import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserData } from '../store/selectors/user.selectors';
import { BehaviorSubject } from 'rxjs';
@Pipe({
  name: 'replaceRewards',
})
export class ReplaceRewardsPipe implements PipeTransform {
  documentType = new BehaviorSubject('');
  constructor(
    private store: Store
  ) {
    this.store.select(selectUserData).subscribe(({ data }) => {
      if (data) {
        this.documentType.next(data.documenttypecode);
        return;
      }
      this.documentType.next('');
      return;
    })
  }
  transform(text: string) {
    const textToReturn = new BehaviorSubject(text);
    this.documentType.subscribe(documentType => {
      if (documentType === 'NIT') {
        textToReturn.next(this.formatTextWhenNit(text));
        return;
      }
      textToReturn.next(this.formatDefault(text));
      return
    });
    return textToReturn;
  }

  formatTextWhenNit(text: string) {
    // El orden que llevan las palabras a cambiar es importante
    // para obtener el valor esperado
    return text.replaceAll('RECOMPENSAS', 'COMISIONES')
      .replaceAll('RECOMPENSAS', 'COMISIONES')
      .replaceAll('RECOMPENSA', 'COMISIÓN')
      .replaceAll('Recompensas', 'Comisiones')
      .replaceAll('Recompensa', 'Comisión')
      .replaceAll('recompensas', 'comisiones')
      .replaceAll('recompensa', 'comisión');
  }

  formatDefault(text: string) {
    // El orden que llevan las palabras a cambiar es importante
    // para obtener el valor esperado
    return text.replaceAll('toda tu recompensa', 'todo tu cashback')
      .replaceAll('todas tus recompensas', 'todo tu cashback')
      .replaceAll('todas las recompensas', 'todo tu cashback')
      .replaceAll('toda la recompensa', 'todo tu cashback')
      .replaceAll('Tus recompensas', 'Tu cashback')
      .replaceAll('Tu recompensa', 'Tu cashback')
      .replaceAll('tus recompensas', 'tu cashback')
      .replaceAll('tu recompensa', 'tu cashback')
      .replaceAll('TUS RECOMPENSAS', 'TU CASHBACK')
      .replaceAll('TU RECOMPENSA', 'TU CASHBACK')
      .replaceAll('LAS RECOMPENSAS', 'EL CASHBACK')
      .replaceAll('Las recompensas', 'El cashback')
      .replaceAll('las recompensas', 'el cashback')
      .replaceAll('LA RECOMPENSA', 'EL CASHBACK')
      .replaceAll('la recompensa', 'el cashback')
      .replaceAll('UNA RECOMPENSA', 'UN CASHBACK')
      .replaceAll('una recompensa', 'un cashback')
      .replaceAll('RECOMPENSAS GENERADAS', 'CASHBACK GENERADO')
      .replaceAll('recompensas generadas', 'cashback generado')
      .replaceAll('RECOMPENSAS ENTREGADAS', 'CASHBACK ENTREGADO')
      .replaceAll('recompensas entregadas', 'cashback entregado')
      .replaceAll('RECOMPENSAS', 'CASHBACK')
      .replaceAll('RECOMPENSA', 'CASHBACK')
      .replaceAll('Recompensas', 'Cashback')
      .replaceAll('Recompensa', 'Cashback')
      .replaceAll('recompensas', 'cashback')
      .replaceAll('recompensa', 'cashback');
  }

}
