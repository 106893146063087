<!-- <div fxLayout="row" class="bar sticky" fxLayoutAlign="start center">
  <span fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
    <mat-icon class="let-icon" (click)="closeComponent()">keyboard_arrow_left</mat-icon>
  </span> -->
  <!-- <span fxLayout="column" fxLayoutAlign="center center" fxFlex="60">
    <h4 class="bar-title">{{ title }}</h4>
  </span> -->
  <!-- <img loading="lazy" src="assets/img/icon-lg-salmon-color.svg" width="120" alt="" />
</div> -->
<div class="top">
  <i class="tio-clear top__close" (click)="closeComponent()"></i>
  <img loading="lazy" src="assets/img/icon-lg-salmon-color.svg" width="120" alt="" />
</div>

<!-- <div fxLayout="row" fxLayoutAlign="center center" class="pt-5">
  <div class="logo">
    <img loading="lazy"src="assets/img/icon-lg-salmon-color.svg" width="180" alt="" />
  </div>
</div> -->