import { createReducer, on } from "@ngrx/store";
import { getMenu, getMenuError, getMenuSuccess, restoreMenu } from "../actions/menus.actions";

export interface MenuState {
    menu: any;
    isLoading: boolean;
    error: any;
}

export const initialMenuState: MenuState = {
    menu: null,
    isLoading: false,
    error: null
}

export const menuReducer = createReducer(
    initialMenuState,
    on(restoreMenu, () => ({ ...initialMenuState })),
    on(getMenu, state => ({ ...state, isLoading: true })),
    on(getMenuSuccess, (state, { menu }) => ({
        ...state,
        menu,
        isLoading: false,
    })),
    on(getMenuError, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),

)