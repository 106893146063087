import { createAction, props } from '@ngrx/store';

export const restoreMenu = createAction('[MENU] Restore menu');

export const getMenu = createAction('[MENU] Get menu');

export const getMenuSuccess = createAction(
    '[MENU] Get menu success',
    props<{ menu: any }>()
);

export const getMenuError = createAction(
    '[MENU] Get menu error',
    props<{ error: any }>()
);