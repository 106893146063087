import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as introJs from 'intro.js/intro.js';
import { setOnboardingDisplay, setOnboardingStep } from '../store/actions/onboarding.actions';
import { MatDialog } from '@angular/material/dialog';
import { ModalReferirComprarComponent } from '../modules/shared/components/modal-referir-comprar/modal-referir-comprar.component';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class IntroJSService {

  introJS = null;
  buttonModalSelector = '#businessList .button--modal'

  defaultOptions: any = {
    autoPosition: true,
    prevLabel: 'Atrás',
    nextLabel: 'Siguiente',
    positionPrecedence: ["right", "top", "bottom", "left"],
    exitOnEsc: false,
    exitOnOverlayClick: false,
    showBullets: false,
    showPrevButton: true,
  }

  constructor(
    private store: Store,
    private matDialogRef: MatDialog,
    private userService: UserService
  ) { }

  onboardingStepTwo() {
    const introJS = introJs();
    introJS.setOptions({
      ...this.defaultOptions,
      steps: [
        {},
        {
          element: '#onboardingBusinessList',
          intro:
            'Selecciona ✅ la marca que quieras y compra o comparte desde cada uno de los links para ganar cashback.',
          position: 'right',
        },
        {},
      ],
    }).start();
    const refreshStepTwo = () => { introJS.refresh(); }
    window.addEventListener('scroll', refreshStepTwo);
    introJS.goToStepNumber(2);
    introJS.onbeforechange(() => {
      setTimeout(() => introJS.exit(true), 0);
    });
    const intervalRefresh = this.refresh(introJS);
    introJS.onexit(() => {
      window.removeEventListener('scroll', refreshStepTwo);
      clearInterval(intervalRefresh);
      setTimeout(() => {
        if (introJS._direction === 'backward') {
          this.store.dispatch(setOnboardingStep({ step: 1 }));
        }
        if (introJS._direction === 'forward') {
          this.store.dispatch(setOnboardingStep({ step: 3 }));
          const firstButton: any = document.querySelector(this.buttonModalSelector);
          firstButton.click();
        }
      }, 1);
    });
  }

  onboardingStepThree() {
    const cdkOverlayContainer = document.querySelector('.cdk-overlay-container');
    cdkOverlayContainer.classList.add('modal-referir-comprar-overlay');
    const introJS = introJs();
    introJS.setOptions({
      ...this.defaultOptions,
      steps: [
        {},
        {
          element: '#modalReferirComprar',
          intro:
            'Desde aquí siempre podrás 🛍️ comprar, referir o compartir en redes sociales una marca un producto y/o promociones.',
            position: 'right',
        },
        {},
      ],
    }).start();
    const refreshStepThree = () => { introJS.refresh(); }
    window.addEventListener('scroll', refreshStepThree);
    cdkOverlayContainer.addEventListener('scroll', refreshStepThree);
    const introJsOverlay: any = document.querySelector('.introjs-overlay');
    introJsOverlay.style.inset = 'auto';
    introJS.goToStepNumber(2);
    introJS.onbeforechange(() => {
      cdkOverlayContainer.removeEventListener('scroll', refreshStepThree);
      cdkOverlayContainer.classList.remove('modal-referir-comprar-overlay');
      setTimeout(() => introJS.exit(true), 0);
    });
    const intervalRefresh = this.refresh(introJS);
    introJS.onexit(() => {
      window.removeEventListener('scroll', refreshStepThree);
      clearInterval(intervalRefresh);
      this.matDialogRef.closeAll();
      setTimeout(() => {
        if (introJS._direction === 'backward') {
          this.store.dispatch(setOnboardingStep({ step: 2 }));
        }
        if (introJS._direction === 'forward') {
          this.store.dispatch(setOnboardingStep({ step: 4 }));
        }
      }, 10);
    });
  }

  onboardingStepFour() {
    const introJS = introJs();
    introJS.setOptions({
      ...this.defaultOptions,
      steps: [
        {},
        {
          element: '#inviteAndWinCard',
          intro:
            'Gana dinero 👍 refiriendo los productos de tus marcas favoritas a amigos y conocidos.',
            position: 'right',
        },
        {},
      ],
    }).start();
    const refreshStepFour = () => { introJS.refresh(); }
    window.addEventListener('scroll', refreshStepFour);
    introJS.goToStepNumber(2);
    introJS.onbeforechange(() => {
      setTimeout(() => introJS.exit(true), 0);
    });
    const intervalRefresh = this.refresh(introJS);
    introJS.onexit(() => {
      window.removeEventListener('scroll', refreshStepFour);
      clearInterval(intervalRefresh);
      setTimeout(() => {
        if (introJS._direction === 'backward') {
          const firstButton: any = document.querySelector(this.buttonModalSelector);
          firstButton.click();
          this.store.dispatch(setOnboardingStep({ step: 3 }));
        }
        if (introJS._direction === 'forward') {
          this.store.dispatch(setOnboardingStep({ step: 5 }));
        }
      }, 1);
    });
  }

  onboardingStepFive() {
    const introJS = introJs();
    introJS.setOptions({
      ...this.defaultOptions,
      steps: [
        {},
        {
          element: '#salesInfo',
          intro:
            '💰 Visualiza el acumulado del cashback. ¡Recuerda tener todos los datos actualizados en el perfil, para que se deposite el dinero en tu cuenta!',
            position: 'right',
        },
        {},
      ],
    }).start();
    const refreshStepFive = () => { introJS.refresh(); }
    window.addEventListener('scroll', refreshStepFive);
    introJS.goToStepNumber(2);
    introJS.onbeforechange(() => {
      setTimeout(() => introJS.exit(true), 0);
    });
    const intervalRefresh = this.refresh(introJS);
    introJS.onexit(() => {
      window.removeEventListener('scroll', refreshStepFive);
      clearInterval(intervalRefresh);
      setTimeout(() => {
        if (introJS._direction === 'backward') {
          this.store.dispatch(setOnboardingStep({ step: 4 }));
        }
        if (introJS._direction === 'forward') {
          this.store.dispatch(setOnboardingStep({ step: 6 }));
        }
      }, 1);
    });
  }

  onboardingStepSix() {
    const introJS = introJs();
    introJS.setOptions({
      ...this.defaultOptions,
      nextLabel: 'Listo',
      steps: [
        {},
        {
          element: '#centroDeAyudaOnboarding',
          intro:
            '¡Quieres saber más de Referidos Éxito! Haz clic aquí y conoce todo sobre nosotros… ⭐️',
            position: 'left',
        },
        {},
      ],
    }).start();
    const refreshStepSix = () => { introJS.refresh(); }
    window.addEventListener('scroll', refreshStepSix);
    introJS.goToStepNumber(2);
    introJS.onbeforechange(() => {
      setTimeout(() => introJS.exit(true), 0);
    });
    const intervalRefresh = this.refresh(introJS);
    introJS.onexit(() => {
      window.removeEventListener('scroll', refreshStepSix);
      clearInterval(intervalRefresh);
      setTimeout(() => {
        if (introJS._direction === 'backward') {
          this.store.dispatch(setOnboardingStep({ step: 5 }));
        }
        if (introJS._direction === 'forward') {
          this.store.dispatch(setOnboardingDisplay({ show: false }));
          this.userService.saveOnboarding(true).subscribe();
        }
      }, 1);
    });
  }

  refresh(introJS) {
    const loops = 10;
    let loopsCount = 0;
    const intervalID = setInterval(() => {
      introJS.refresh();
      loopsCount++;
      if (loopsCount === loops) {
        clearInterval(intervalID);
      }
    }, 1000);
    return intervalID;
  }
}
