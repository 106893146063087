import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-init-interactive-onboarding',
  templateUrl: './init-interactive-onboarding.component.html',
  styleUrls: ['./init-interactive-onboarding.component.scss']
})
export class InitInteractiveOnboardingComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<InitInteractiveOnboardingComponent>
  ) { }

  ngOnInit(): void {
  }

  continueOnboarding() {
    this.matDialogRef.close();
  }

}
