import { createSelector } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { OnboardingState } from "../reducers/onboarding.reducers";


export const selectOnboardingNode = (state: AppState) =>
    state.onboarding;

export const selectOnboarding = createSelector(
    selectOnboardingNode,
    (state: OnboardingState) => state
)