import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class PartnerGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = localStorage.getItem('ACCESS_TOKEN');

    if (token) {
      const tokenDecode = decode(token);
      if (tokenDecode?.role === 'PARTNER' || tokenDecode?.role === 'PARTNER-CASHIER') {
        this.router.navigateByUrl('/partner');
        return false;
      }
      return true;
    }
    return true;
  }

}
