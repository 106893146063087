import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ResponseService } from '../interfaces/response';
import { Observable, Subscription } from 'rxjs';
import { formatToQueryParams } from '../helpers/formatToQueryParams';

@Injectable({
  providedIn: 'root',
})
export class LinksService implements OnDestroy {
  getAmount$: Subscription = new Subscription();
  constructor(private http: HttpClient) { }

  url = environment.URL_REFERAL;
  ulrReport = environment.URL_REPORTS;
  urlComission = environment.URL_COMISSION;
  urlReports = environment.URL_REPORTS;
  urlApiContent = environment.URL_CONTENT;

  reports = 'Reports/ClickerPerformanceReport';
  apiKPI = 'Reports/getKPI';
  apikpiresume = 'Reports/getkpiresume';
  apiGetReports = 'reports/getreports';

  apikpibussiness = 'Reports/getkpibusiness';
  apiSalesByShops = 'reports/salesbyshops';
  apiGetComparativeDates = 'reports/getcomparativedates';

  apikpiTotal = 'Reports/getkpitotaldata';
  apiUsersExcel = 'Reports/getUsersExcel';
  apiGetCommissionsDeletedUsers = 'reports/getcommissionsdeletedusers';

  apiUsersHistoricalBankInformation = 'admin/gethistoricalbankinformation';
  apiAuditExcel = 'Reports/getAudit';
  apiAuditUserInfo = 'admin/getreportupdateinfoclicker';
  apiGetReportReferral = 'Reports/getreportreferral';
  apigetReportClickam = 'Reports/getReportClickam';
  apigetPaymentReport = 'reports/getpaymentreport';
  apigetReportTerms = 'reports/getterms';
  apigetReportCommissions = 'reports/getadmincommissions';
  apiUsers = 'Reports/getUsers';
  apiExportFilterUsers = 'reports/getusersfilter';
  insurance = 'Insurance/ProcessFiles';
  apiSaveLink = 'Link/SaveLink';
  apiSaveLinkRefered = 'link/savelinkreferred';
  apiPostReferrrals = 'Link/downloadReferrals';
  apiGetTotalLinks = 'Link/GetTotalLinksGenerated';
  apiGetUrl = 'link/geturl';
  apiGetUrlWidget = "link/savelinkwidget";
  apiFile = 'commissions/getUrlFileCommissions';
  apigetDetailPaymentClicker = 'commissions/getDetailPaymentClicker';
  apigenerateCommissions = 'commissions/generateCommissionsFile';
  apiHistory = 'commissions/getPaymentHistoryClicker';
  apiLinkHistory = 'linkhistory/getlinkhistory';
  apiupdatePaymentDate = 'commissions/updatePaymentDate';
  apiUpdateStatusCommissionFile = 'commissions/updatestatuscommissionfile';
  apiUpdateStatusCommission = 'commissions/updatestatuscommission';
  apiDeleteCommissionFile = 'commissions/deletecommissionfile';
  apiDeleteCommission = 'commissions/deletecommission';
  apiSaveCutOffDate = 'commissions/savecutoffdate';
  apiGetCutOffDate = 'commissions/getcutoffdate';
  apiGetReferrals = 'referrals/getreferrals';
  apiGetAmounts = 'amount/getamounts';
  apiSaveAmountCommission = 'amount/saveamountcommission';
  apiSaveAmountReferred = 'amount/saveamountreferred';
  apiPicking = 'picking/importfilepickingcommissions';
  apiOrder = 'business/orderbusiness';
  apiSellers = 'seller/getsellers';
  apiGetReport = 'reports/clickerperformancereport';
  apiGetReportById = 'reports/GetReportClickerRewards';
  apiGetReportMonth = 'reports/getcommissionpaymentreport';
  apiAudit = 'reports/getaudit';
  apikpiNovelties = 'new/getkpinovelties';
  apiReportRejected = 'reports/getreportrejected';
  apiGetOrderNumber = 'orders/getorder';
  apiSetStoryClic = 'story/saveclicstory';
  apiReprocessOrdersInvoiced = 'orders/reprocessordersinvoiced';
  apiGetCustomHome = 'userprofile/getcustomhome';
  apiGetCustomReferred = 'userprofile/getcustomreferred';
  token = localStorage.getItem('ACCESS_TOKEN');
  authorization = this.token;

  public saveLink(SaveLink: any) {
    return this.http.post(`${this.url + this.apiSaveLink}`, SaveLink);
  }
  
  getCustomHome() {
    return this.http.get(`${environment.URL_PROFILE}${this.apiGetCustomHome}`);
  }
  
  getCustomReferred() {
    return this.http.get(`${environment.URL_PROFILE}${this.apiGetCustomReferred}`);
  }

  public sendPickingfile(formdata) {
    const data = new FormData();
    data.append('file', formdata.file);
    return this.http.post(`${environment.URL_COMISSION}${this.apiPicking}`, formdata);
  }

  public saveLinkRefer(SaveLink: any) {
    return this.http.post(`${this.url + this.apiSaveLinkRefered}`, SaveLink);
  }

  public saveAmountCommission(amount: any) {
    return this.http.post(`${this.urlComission + this.apiSaveAmountCommission}`, amount);
  }

  public saveCutOffDate(value: number) {
    return this.http.post(`${this.urlComission + this.apiSaveCutOffDate}`, { value: value });
  }

  public saveAmountReferred(amount: any) {
    return this.http.post(`${this.urlComission + this.apiSaveAmountReferred}`, amount);
  }

  public downloadReferrals(dates: any) {
    return this.http.post(`${this.url + this.apiPostReferrrals}`, dates);
  }

  public getReports() {
    return this.http.get(`${this.urlComission}${this.reports}`).pipe(
      map((resp: ResponseService) => {
        return resp.objectResponse;
      })
    );
  }

  public getCutOffDate() {
    return this.http.get(`${this.urlComission}${this.apiGetCutOffDate}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getAmount() {
    this.getAmount$ = this.http.get(`${this.urlComission}${this.apiGetAmounts}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    ).subscribe((amount) => {
      localStorage.setItem('Amount', amount.amountsCommission);
      localStorage.setItem('AmonuntReferred', amount.amountsReferred);
    });
  }

  public getReferrals(params) {
    return this.http
      .get(`${this.urlComission}${this.apiGetReferrals}?from=${params.from}&to=${params.to}&orderBy=DATE&ordination=DESC`)
      .pipe(
        map((resp: ResponseService) => resp.objectResponse)
      );
  }

  public getUrl(params) {
    const queryString = formatToQueryParams(params);
    return this.http.get(`${this.url}${this.apiGetUrl}${queryString}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }
  public getUrlWidget(data: any) {
    return this.http.post(`${this.url}${this.apiGetUrlWidget}`, data).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getKPI(date: any) {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const authorization = token;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authorization,

      }),
    };
    return this.http.get(`${this.urlComission}${this.apiKPI}?start=${date.start}&end=${date.end}`, httpOptions).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }
  public getResume() {
    return this.http.get(`${this.urlReports}${this.apikpiresume}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getBussinessKPI(date: any) {
    const datesGet0 = date.start.split('T');
    const datesGet1 = date.end.split('T');
    return this.http.get(`${this.urlReports}${this.apikpibussiness}?start=${datesGet0[0]}&end=${datesGet1[0]}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getBussinessPartnerKPI(params: any) {
    return this.http.get(`${this.urlReports}${this.apikpibussiness}?start=${params.startDate}&end=${params.endDate}&export=${params.export}`);
  }

  public getSalesByShops(params: any) {
    return this.http.get(`${this.urlReports}${this.apiSalesByShops}?start=${params.startDate}&end=${params.endDate}&idbusiness=${params.idBusiness}`)
  }

  public getComparedates(params: any) {
    return this.http.get(`${this.urlReports}${this.apiGetComparativeDates}?start=${params.startDate}&end=${params.endDate}&startcompare=${params.startcompare}&endcompare=${params.endcompare}&export=${params.export}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getTotalKPI(date: any) {
    const datesGet0 = date.start.split('T');
    const datesGet1 = date.end.split('T');
    return this.http.get(`${this.urlReports}${this.apikpiTotal}?start=${datesGet0[0]}&end=${datesGet1[0]}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getPayment(params: any) {
    const queryString = formatToQueryParams(params);
    return this.http.get(`${this.urlComission}${this.apiHistory}${queryString}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getLinkHistory(params) {
    const queryString = formatToQueryParams(params);
    return this.http
      .get(`${this.urlReports}${this.apiLinkHistory}${queryString}`)
      .pipe(
        map((resp: ResponseService) => resp.objectResponse)
      );
  }

  public getFileReport() {
    return this.http.get(`${this.ulrReport}${this.apiGetReportMonth}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getDetailPaymentClicker(params: any) {
    const queryString = formatToQueryParams(params);
    return this.http.get(`${this.urlComission}${this.apigetDetailPaymentClicker}${queryString}`);
  }

  public getUsersExcel(params: any) {
    return this.http.get(`${this.urlReports}${this.apiUsersExcel}?&start=${params.start}&end=${params.end}`);
  }

  public deleteImports(params: any) {
    return this.http.get(`${this.urlReports}${this.apiGetCommissionsDeletedUsers}?&start=${params.start}&end=${params.end}`);
  }

  public getHistoricalBankInformation(params: any) {
    return this.http.get(`${this.urlReports}${this.apiUsersHistoricalBankInformation}?&start=${params.start}&end=${params.end}`);
  }

  public getAudit(params: any) {
    return this.http.get(`${this.urlComission}${this.apiAuditExcel}?&start=${params.start}&end=${params.end}`);
  }

  public getReportReferral() {
    return this.http.get(`${this.urlComission}${this.apiGetReportReferral}`);
  }

  public getReport(params: any) {
    return this.http.get(`${this.urlReports}${this.apiGetReports}?from=${params.from}&to=${params.to}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getReportTerms() {
    return this.http.get(`${this.ulrReport}${this.apigetReportTerms}`);
  }

  public getReportCommissions() {
    return this.http.get(`${this.ulrReport}${this.apigetReportCommissions}`);
  }

  public getReportUser(id?: string) {
    return this.http.get(`${this.ulrReport}${this.apiGetReport}?userid=${id}`);
  }

  public getRewardsReportById(params: any) {
    const queryString = formatToQueryParams(params);
    return this.http.get(`${this.ulrReport}${this.apiGetReportById}${queryString}`);
  }

  public getReportClickam(params: any) {
    return this.http.get(`${this.urlReports}${this.apigetReportClickam}?&start=${params.start}&end=${params.end}&identification=${params.identification}&business=${params.business}&startoncreatedate=${params.startoncreatedate}&endoncreatedate=${params.endoncreatedate}&medium=${params.medium}`);
  }

  public getPaymentReport(params: any) {
    return this.http.get(`${this.urlReports}${this.apigetPaymentReport}?&start=${params.start}&end=${params.end}`);
  }

  public getAuditoria(params: any) {
    return this.http.get(`${this.urlReports}${this.apiAudit}?&start=${params.start}&end=${params.end}`);
  }
  public getAuditoriaDatosUser(params: any) {
    return this.http.get(`${this.urlReports}${this.apiAuditUserInfo}?&start=${params.start}&end=${params.end}`);
  }
  public sendfile(formdata) {
    const data = new FormData();
    data.append('FileBase64', formdata.fileBase64);
    data.append('email', formdata.email);
    return this.http.post(`${environment.URL_COMISSION}${this.apigenerateCommissions}`, formdata);
  }

  public updatePaymentDate(formdata) {
    const data = new FormData();
    data.append('FileBase64', formdata.fileBase64);
    data.append('email', formdata.email);
    return this.http.post(`${environment.URL_COMISSION}${this.apiupdatePaymentDate}`, formdata);
  }

  public searchUsers(data: any) {
    return this.http.post(`${this.urlReports}${this.apiUsers}`, data).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public exportFilterUsers(data: any) {
    return this.http.post(`${this.urlReports}${this.apiExportFilterUsers}`, data);
  }

  public putOrder(datos?: any) {
    return this.http.post(`${this.urlApiContent}${this.apiOrder}`, datos);
  }

  public getSellers() {
    return this.http.get(`${this.urlComission}${this.apiSellers}`).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public getkpiNovelties(data: any) {
    return this.http.post(`${this.urlReports}${this.apikpiNovelties}`, data).pipe(
      map((resp: ResponseService) => resp.objectResponse)
    );
  }

  public updateStatusCommissionFile(formdata) {
    return this.http.post(`${environment.URL_COMISSION}${this.apiUpdateStatusCommissionFile}`, formdata);
  }

  public deleteCommissionFile(formdata) {
    return this.http.post(`${environment.URL_COMISSION}${this.apiDeleteCommissionFile}`, formdata);
  }

  public getReportRejected(date: any) {
    return this.http.get(`${this.ulrReport}${this.apiReportRejected}?start=${date.start}&end=${date.end}`);
  }

  public updateCommission(data) {
    return this.http.post(`${environment.URL_COMISSION}${this.apiUpdateStatusCommission}`, data);
  }

  public deleteCommission(data) {
    return this.http.post(`${environment.URL_COMISSION}${this.apiDeleteCommission}`, data);
  }

  public setStoryClic(data) {
    return this.http.post(`${this.urlApiContent}${this.apiSetStoryClic}`, data);
  }

  public getOrderNumber(params): Observable<ResponseService> {
    return this.http.get<ResponseService>(`${this.urlComission}${this.apiGetOrderNumber}?orderid=${params.orderid}&business=${params.business}`);
  }

  public reprocessOrdersInvoiced() {
    return this.http.post(`${environment.URL_COMISSION}${this.apiReprocessOrdersInvoiced}`, {});
  }

  ngOnDestroy(): void {
    this.getAmount$.unsubscribe();
  }

}
