import { createReducer, on } from "@ngrx/store";
import {
    getPayments,
    getPaymentsSuccess,
    getPaymentsError,
    getPaymentDetail,
    getPaymentDetailSuccess,
    getPaymentDetailError,
    setCurrentPayment,
    deleteCurrentPayment,
    getRewardsSummary,
    getRewardsSummarySuccess,
    getRewardsSummaryError,
    getRewardsGraph,
    getRewardsGraphSuccess,
    getRewardsGraphError,
    getRewardsHistory,
    getRewardsHistorySuccess,
    getRewardsHistoryError,
    restoreReport,
} from "../actions/report.actions";
import { baseState, baseStatePagination } from "./user.reducers";

export interface ReportState {
    payments: baseStatePagination;
    paymentDetail: baseState;
    currentPayment: any;
    rewardsSummary: baseState;
    rewardsGraph: baseState;
    rewardsHistory: baseStatePagination;
    rewardsTop: baseState;
}



export const initialReportState: ReportState = {
    payments: {
        data: null,
        isLoading: false,
        error: null,
        total: 0,
        params: null,
    },
    paymentDetail: {
        data: null,
        isLoading: false,
        error: null
    },
    currentPayment: null,
    rewardsSummary: {
        data: null,
        isLoading: false,
        error: null
    },
    rewardsGraph: {
        data: null,
        isLoading: false,
        error: null
    },
    rewardsHistory: {
        data: null,
        isLoading: false,
        error: null,
        total: 0,
        params: null,
    },
    rewardsTop: {
        data: null,
        isLoading: false,
        error: null
    },
}

export const reportReducer = createReducer(
    initialReportState,
    on(restoreReport, () => ({ ...initialReportState })),
    on(getPayments, (state, { params }) => ({
        ...state,
        payments: {
            ...state.payments,
            isLoading: true,
            error: null,
            total: 0,
            params
        }
    })),
    on(getPaymentsSuccess, (state, { payments }) => ({
        ...state,
        payments: {
            ...state.payments,
            data: payments.users,
            isLoading: false,
            error: null,
            total: payments.total
        }
    })),
    on(getPaymentsError, (state, { error }) => ({
        ...state,
        payments: {
            ...state.payments,
            isLoading: false,
            error,
            total: 0
        }
    })),
    on(setCurrentPayment, (state, { payment }) => ({
        ...state,
        currentPayment: payment
    })),
    on(deleteCurrentPayment, state => ({
        ...state,
        currentPayment: null,
        paymentDetail: {
            data: null,
            isLoading: false,
            error: null
        },
    })),
    on(getPaymentDetail, state => ({
        ...state,
        paymentDetail: {
            ...state.paymentDetail,
            isLoading: true,
            error: null
        }
    })),
    on(getPaymentDetailSuccess, (state, { payment }) => ({
        ...state,
        paymentDetail: {
            data: payment,
            isLoading: false,
            error: null
        }
    })),
    on(getPaymentDetailError, (state, { error }) => ({
        ...state,
        paymentDetail: {
            ...state.paymentDetail,
            isLoading: false,
            error
        }
    })),
    on(getRewardsSummary, state => ({
        ...state,
        rewardsSummary: {
            ...state.rewardsSummary,
            isLoading: true,
            error: null
        }
    })),
    on(getRewardsSummarySuccess, (state, { data }) => ({
        ...state,
        rewardsSummary: {
            data,
            isLoading: false,
            error: null
        }
    })),
    on(getRewardsSummaryError, (state, { error }) => ({
        ...state,
        rewardsSummary: {
            ...state.rewardsSummary,
            isLoading: false,
            error
        }
    })),
    on(getRewardsGraph, (state, { params }) => ({
        ...state,
        rewardsGraph: {
            ...state.rewardsGraph,
            isLoading: true,
            error: null
        },
        rewardsHistory: {
            ...state.rewardsHistory,
            isLoading: true,
            error: null,
            total: 0,
            params
        },
        rewardsTop: {
            ...state.rewardsTop,
            isLoading: true,
            error: null
        }
    })),
    on(getRewardsGraphSuccess, (state, { data }) => ({
        ...state,
        rewardsGraph: {
            data: data.generalResumeRewards.graph || [],
            isLoading: false,
            error: null
        },
        rewardsHistory: {
            ...state.rewardsHistory,
            data: data.generalResumeRewards.cutOffValueRewards,
            isLoading: false,
            error: null,
            total: data.generalResumeRewards.total,
        },
        rewardsTop: {
            data: data.generalResumeRewards.totalBusiness || [],
            isLoading: false,
            error: null
        }
    })),
    on(getRewardsGraphError, (state, { error }) => ({
        ...state,
        rewardsGraph: {
            ...state.rewardsGraph,
            isLoading: false,
            error
        },
        rewardsHistory: {
            ...state.rewardsHistory,
            isLoading: false,
            error,
            total: 0,
        },
        rewardsTop: {
            ...state.rewardsTop,
            isLoading: false,
            error
        }
    })),
    on(getRewardsHistory, (state, { params }) => ({
        ...state,
        rewardsHistory: {
            ...state.rewardsHistory,
            isLoading: true,
            error: null,
            total: 0,
            params,
        }
    })),
    on(getRewardsHistorySuccess, (state, { data }) => ({
        ...state,
        rewardsHistory: {
            ...state.rewardsHistory,
            data: data.generalResumeRewards.cutOffValueRewards,
            isLoading: false,
            error: null,
            total: data.generalResumeRewards.total,
        }
    })),
    on(getRewardsHistoryError, (state, { error }) => ({
        ...state,
        rewardsHistory: {
            ...state.rewardsHistory,
            isLoading: false,
            error,
            total: 0,
        }
    })),

)