import { createAction, props } from '@ngrx/store';

export const restoreUser = createAction('[USER] Restore User');

export const getUserData = createAction('[USER-DATA] Get user data');

export const getUserDataSuccess = createAction(
    '[USER-DATA] Get user data success',
    props<{ userData: any }>()
);

export const getUserDataError = createAction(
    '[USER-DATA] Get user data error',
    props<{ error: any }>()
);

export const getUserProfile = createAction('[USER-DATA] Get user profile');

export const getUserProfileSuccess = createAction(
    '[USER-DATA] Get user profile success',
    props<{ profile: any }>()
);

export const getUserProfileError = createAction(
    '[USER-DATA] Get user profile error',
    props<{ error: any }>()
);

export const getIdentificationCard1 = createAction('[USER-DATA] Get identification card 1');

export const getIdentificationCard1Success = createAction(
    '[USER-DATA] Get identification card 1 success',
    props<{ data: any }>()
);

export const getIdentificationCard1Error = createAction(
    '[USER-DATA] Get identification card 1 error',
    props<{ error: any }>()
);

export const getIdentificationCard2 = createAction('[USER-DATA] Get identification card 2');

export const getIdentificationCard2Success = createAction(
    '[USER-DATA] Get identification card 2 success',
    props<{ data: any }>()
);

export const getIdentificationCard2Error = createAction(
    '[USER-DATA] Get identification card 2 error',
    props<{ error: any }>()
);

export const getBankCertificate = createAction('[USER-DATA] Get bank certificate');

export const getBankCertificateSuccess = createAction(
    '[USER-DATA] Get bank certificate success',
    props<{ data: any }>()
);

export const getBankCertificateError = createAction(
    '[USER-DATA] Get bank certificate error',
    props<{ error: any }>()
);

export const getRut = createAction('[USER-DATA] Get RUT');

export const getRutSuccess = createAction(
    '[USER-DATA] Get RUT success',
    props<{ data: any }>()
);

export const getRutError = createAction(
    '[USER-DATA] Get RUT error',
    props<{ error: any }>()
);

export const getNovelties = createAction('[USER-NOVELTIES] Get user novelties');

export const getNoveltiesSuccess = createAction(
    '[USER-NOVELTIES] Get user novelties success',
    props<{ novelties: any }>()
);

export const getNoveltiesError = createAction(
    '[USER-NOVELTIES] Get user novelties error',
    props<{ error: any }>()
);

export const updateAccountNumber = createAction(
    '[USER-ACCOUNT NUMBER] Update account number',
    props<{ number: any }>()
)

export const toggleFinancialForm = createAction(
    '[USER-FINANCIAL FORM] Toggle financial form',
    props<{ show: boolean, updating: boolean }>()
)