<div class="refer-win" ngClass.xs="py-3" fxLayout="column" fxLayoutAlign="center center">
    <div class="container" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <p ngClass.xs="text-center galano" fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="20px"
            fxLayoutAlign="center center" class="galano-bold white">
            <span ngClass.gt-xs="parallelogram mr-4" ngClass.xs="galano-bold f-18">
                <span class="rotate">Gana {{amount | currency: '':'symbol':'1.0-0'}} pesos</span>
            </span>
            Invita un amigo y gana por su primera compra
        </p>
        <!-- <app-button *ngIf="!auth.isLoggedIn()" [haveIcon]="false" title="Invita y gana" color="red" (action)="openRegister()"  classButton="shadow-purple" gtm="gtmHomeInvitaYGana"></app-button>
        <app-button *ngIf="auth.isLoggedIn()" [haveIcon]="false" title="Invita y gana" color="red" [routerLink]="['/referidos']"  classButton="shadow-purple" gtm="gtmHomeInvitaYGana"></app-button> -->
        <!-- <button class="button-base gtmHomeInvitaYGana" (click)="openRegister()" *ngIf="!auth.isLoggedIn()">Invita y gana</button>
        <a class="button-base gtmHomeInvitaYGana"  [routerLink]="['/referidos']" *ngIf="auth.isLoggedIn()">Invita y gana</a> -->
    </div>
</div>