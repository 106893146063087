<app-top title="Login y registro" (close)="hideLogin()" class="top"></app-top>

<h3 class="text-center title-login">{{ 'LOGIN.LOGIN' | translate }}</h3>

<app-social-networks-login-buttons [isLogin]="true" (user)="showUser($event)"></app-social-networks-login-buttons>

<p class="email-login-text">O ingresa con tu correo electrónico</p>

<form class="login-container" [formGroup]="loginForm">
  <mat-form-field>
    <input matInput id="email" type="email" placeholder="{{ 'GENERIC.EMAIL' | translate }}" formControlName="Username"
      spacebar required />
    <span matPrefix><mat-icon color="gray">person</mat-icon></span>
    <mat-error id="introduceEmail"
      *ngIf="loginForm.controls.Username.invalid && loginForm.controls.Username.errors.required">{{
      'LOGIN.INTRODUCE_EMAIL' | translate
      }}</mat-error>
    <mat-error id="invalidEmail"
      *ngIf="loginForm.controls.Username.invalid && loginForm.controls.Username.errors.pattern">{{
      'LOGIN.INVALID_EMAIL' | translate
      }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
    <input matInput id="password" [type]="toggleVisbility.type" placeholder="{{ 'GENERIC.PASSWORD' | translate }}"
      formControlName="Password" spacebar required />
    <span matPrefix><mat-icon color="gray">lock</mat-icon></span>
    <mat-error id="introducePassword"
      *ngIf="loginForm.controls.Password.invalid && loginForm.controls.Password.errors.required">{{
      'LOGIN.INTRODUCE_PASSWORD' | translate
      }}</mat-error>

    <mat-error id="minPassword"
      *ngIf="loginForm.controls.Password.invalid && loginForm.controls.Password.errors.minlength">{{
      'GENERIC.MIN_CHARACTERS'
      | translate
      : {
      minchar: loginForm.controls.Password.errors.minlength.requiredLength
      }
      }}</mat-error>

    <mat-error id="maxPassword"
      *ngIf="loginForm.controls.Password.invalid && loginForm.controls.Password.errors.maxlength">{{
      'GENERIC.MAX_CHARACTERS'
      | translate
      : {
      maxchar: loginForm.controls.Password.errors.maxlength.requiredLength
      }
      }}</mat-error>
  </mat-form-field>
  <div class="text-center">
    <button class="button-base login-button" id="login" (click)="loginFormGA4('Correo');loginHandle()"
      [disabled]="!loginForm.valid">
      {{ 'LOGIN.LOGIN' | translate }}
    </button>
  </div>
</form>

<p id="forgotPass" class="cursor-pointer f-13 text-decoration text-center color__primary-black mb-4 mt-4"
  (click)="showForgot();loginFormGA4('Olvidé contraseña')">
  {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
</p>

<p id="activateAccount" class="cursor-pointer f-13 text-decoration text-center color__primary-black mb-4"
  (click)="showActivate();loginFormGA4('Correo activación')">
  {{ 'LOGIN.ACTIVATE' | translate }}
</p>

<button ngClass="base-button register-button mt-4" (click)="showRegister();loginFormGA4('Registrarse')">
  {{ 'GENERIC.REGISTER2' | translate }}
</button>