import { createReducer, on } from "@ngrx/store";
import { baseState } from "./user.reducers";
import { getSellers, getSellersError, getSellersSuccess } from "../actions/sellers.actions";

export interface SellersState {
    sellers: baseState;
}

export const initialSellersState: SellersState = {
    sellers: {
        data: null,
        isLoading: false,
        error: null
    },
}

export const sellersReducer = createReducer(
    initialSellersState,
    on(getSellers, state => ({
        ...state,
        sellers: {
            ...state.sellers,
            isLoading: true,
            error: null
        },
    })),
    on(getSellersSuccess, (state, { data }) => ({
        ...state,
        sellers: {
            data,
            isLoading: false,
            error: null
        },
    })),
    on(getSellersError, (state, { error }) => ({
        ...state,
        sellers: {
            ...state.sellers,
            isLoading: false,
            error
        },
    })),

)