export const environment = {
  production: true,
  // URL_MASTER: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/VBTWYUCZDJ/',
  // // URL_MASTER: 'https://apimexito.azure-api.net/PD-clickam-md-apimasterdata/api/',
  // URL_COMISSION: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/YPNKGWJUBA/',
  // URL_CONTENT: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/PHPHYCKWNQ/',
  // URL_EXTERNAL: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/WTCHMPSSJP/',
  // URL_PROFILE: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/NTYNTUWTDY/',
  // // URL_PROFILE: 'https://apimexito.azure-api.net/PD-clickam-md-apiprofile/',
  // URL_REFERAL: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/ALPDQLKDKG/',
  // URL_SECURITY: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/WCMJXFCDTR/',
  // URL_REPORTS: 'https://lawrencio.grupo-exito.com/apiew/clickam/v1/BSDMNWBMMA/',

  URL_MASTER: 'https://galio.grupo-exito.com/apiew/clickam/v1/VBTWYUCZDJ/',
  URL_COMISSION: 'https://galio.grupo-exito.com/apiew/clickam/v1/YPNKGWJUBA/',
  URL_CONTENT: 'https://galio.grupo-exito.com/apiew/clickam/v1/PHPHYCKWNQ/',
  URL_EXTERNAL: 'https://galio.grupo-exito.com/apiew/clickam/v1/WTCHMPSSJP/',
  URL_PROFILE: 'https://galio.grupo-exito.com/apiew/clickam/v1/NTYNTUWTDY/',
  URL_REFERAL: 'https://galio.grupo-exito.com/apiew/clickam/v1/ALPDQLKDKG/',
  URL_SECURITY: 'https://galio.grupo-exito.com/apiew/clickam/v1/WCMJXFCDTR/',
  URL_REPORTS: 'https://galio.grupo-exito.com/apiew/clickam/v1/BSDMNWBMMA/',
  URL_BLOB: 'https://webclickampdn.blob.core.windows.net/',

  GOOGLE_SIGNIN_CLIENT_ID: '667466213113-i429e3v4apcfv7sige4ail98d2pdgeca.apps.googleusercontent.com',
  FACEBOOK_SIGNIN_APP_ID: '587781123554707',

  DOMAIN: 'https://www.referidosexito.com',
  firebaseConfig: {
    apiKey: 'AIzaSyAZNoC1LqIWsTtXhjaWBneju-b_-xSIpnw',
    authDomain: 'app-clickam-pdn.firebaseapp.com',
    databaseURL: 'https://app-clickam-pdn.firebaseio.com',
    projectId: 'app-clickam-pdn',
    storageBucket: 'app-clickam-pdn.appspot.com',
    messagingSenderId: '7696742342',
    appId: '1:7696742342:web:f00112d7856e1a917da4c7',
  },
};
