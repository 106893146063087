<ng-container *ngIf="!showGuide; else guideTemplate">
    <ng-container *ngIf="isLoading; else noLoadingTemplate">
        <div class="loading loading-title"></div>
        <div class="loading loading-description"></div>
    </ng-container>
    <ng-template #noLoadingTemplate>
        <div class="terms-and-conditions">
            <i class="tio-clear close-terms" *ngIf="canClose" (click)="close(false)"></i>
            <h3 class="terms-title border-bottom-salmon-init">
                {{ mainTitle }}
            </h3>
            <div class="scrollable-section">
                <div [innerHtml]="mainContent"></div>
                <h2 class="border-bottom-salmon-init secondary-title" *ngIf="secondaryTitle">{{ secondaryTitle }}</h2>
                <div [innerHtml]="secondaryContent" *ngIf="secondaryContent"></div>
            </div>
            <div class="button-group">
                <button id="accept-modal" class="button-base button-base__cancel button-base__lg" *ngIf="canCancel"
                    (click)="close(false)">
                    No acepto
                </button>
                <button id="accept-modal" class="button-base button-base__primary-black button-base__lg"
                    (click)="close(true)">
                    Acepto
                </button>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #guideTemplate>
    <div class="terms text-center">
        <img loading="lazy" class="icon" src="/assets/img/icon.svg" alt="icon" />
        <p>
            Actualizamos los términos y condiciones de <br> nuestro sitio para que sigas ganando dinero con Referidos
            éxito.
        </p>
        <button class="button-base button-base__primary-black" (click)="getTerms()">Conócelos aquí</button>
    </div>
</ng-template>