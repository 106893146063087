<div fxLayoutAlign="start center" fxLayoutGap="1rem">
  <a href="https://www.facebook.com/Clickamoficial-111583976917774/" rel="noopener noreferrer" target="_blank"
    (click)="socialLinksGA4('Facebook')">
    <span fxLayout="column">
      <img width="36" height="36" loading="lazy" src="assets/img/Facebook.svg" alt="Facebook" />
    </span>
  </a>
  <a href="https://www.instagram.com/referidosexito/" rel="noopener noreferrer" target="_blank"
    (click)="socialLinksGA4('Instagram')">
    <span fxLayout="column">
      <img width="36" height="36" loading="lazy" src="assets/img/Instagram.svg" alt="Instagram" />
    </span>
  </a>
  <a href="https://www.tiktok.com/@referidosexito1" rel="noopener noreferrer" target="_blank"
    (click)="socialLinksGA4('TikTok')">
    <span fxLayout="column">
      <img width="36" height="36" loading="lazy" src="assets/img/tiktok.svg" alt="Instagram" />
    </span>
  </a>

  <a href="https://www.youtube.com/channel/UCyJTGsZ1N8qV5JRDeMiC9OQ" rel="noopener noreferrer" target="_blank"
    (click)="socialLinksGA4('YouTube')">
    <span fxLayout="column">
      <img width="36" height="36" loading="lazy" src="assets/img/YouTube.svg" alt="YouTube" />
    </span>
  </a>
</div>