import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { getBankCertificate, getBankCertificateError, getBankCertificateSuccess, getIdentificationCard1, getIdentificationCard1Error, getIdentificationCard1Success, getIdentificationCard2, getIdentificationCard2Error, getIdentificationCard2Success, getNovelties, getNoveltiesError, getNoveltiesSuccess, getRut, getRutError, getRutSuccess, getUserData, getUserDataError, getUserDataSuccess, getUserProfile, getUserProfileError, getUserProfileSuccess } from '../actions/user.actions';
import { ResponseService } from 'src/app/interfaces/response';
import { getStatusClassName } from 'src/app/helpers/novelties';


@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private userService: UserService
    ) { }

    getUserData$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getUserData),
            mergeMap(() =>
                this.userService.getuserdata().pipe(
                    map((userData) => getUserDataSuccess({ userData })),
                    catchError((error) => of(getUserDataError({ error })))
                )
            )
        );
    });

    getUserProfile$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getUserProfile),
            mergeMap(() =>
                this.userService.getUserProfile().pipe(
                    map((res: ResponseService) => getUserProfileSuccess({ profile: res.objectResponse })),
                    catchError((error) => of(getUserProfileError({ error })))
                )
            )
        );
    });

    getIdentificationCard1$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getIdentificationCard1),
            mergeMap(() =>
                this.userService.getDocuments('IdentificationCard1').pipe(
                    map((res: ResponseService) => getIdentificationCard1Success({ data: res.objectResponse })),
                    catchError((error) => of(getIdentificationCard1Error({ error })))
                )
            )
        );
    });

    getIdentificationCard2$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getIdentificationCard2),
            mergeMap(() =>
                this.userService.getDocuments('IdentificationCard2').pipe(
                    map((res: ResponseService) => getIdentificationCard2Success({ data: res.objectResponse })),
                    catchError((error) => of(getIdentificationCard2Error({ error })))
                )
            )
        );
    });

    getBankCertificate$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getBankCertificate),
            mergeMap(() =>
                this.userService.getDocuments('BankCertificate').pipe(
                    map((res: ResponseService) => getBankCertificateSuccess({ data: res.objectResponse })),
                    catchError((error) => of(getBankCertificateError({ error })))
                )
            )
        );
    });

    getRut$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getRut),
            mergeMap(() =>
                this.userService.getDocuments('Rut').pipe(
                    map((res: ResponseService) => getRutSuccess({ data: res.objectResponse })),
                    catchError((error) => of(getRutError({ error })))
                )
            )
        );
    });

    getUserNovelties$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getNovelties),
            mergeMap(() =>
                this.userService.getNoveltyUser().pipe(
                    map((res: ResponseService) => {
                        if (res.state === 'Success') {
                            const novelties = res.objectResponse.novelties;
                            const noveltiesWithStatus = novelties.map((novelty) => {
                                if (novelty.statusnovelty === 'Pendiente de documentación') {
                                    novelty.statusnovelty = 'Pendiente documento'
                                }
                                return {
                                    ...novelty,
                                    statusClassName: getStatusClassName(novelty.statusnovelty)
                                };
                            });
                            const noveltiesToRedux: ResponseService = {
                                ...res,
                                objectResponse: {
                                    ...res.objectResponse,
                                    novelties: noveltiesWithStatus
                                }
                            }
                            return getNoveltiesSuccess({ novelties: noveltiesToRedux })
                        }
                        return getNoveltiesError({ error: res.userMessage })
                    }),
                    catchError((error) => of(getNoveltiesError({ error })))
                )
            )
        );
    });

}