<div id="{{ data.id }}" class="modal-width generic-modal">
  <div class="top-generic" fxLayoutAlign="space-between">
    <h1 class="title-width f-18 galano-bold" mat-dialog-title>
      {{ data.title }}
    </h1>
    <mat-icon class="modal__close-icon" (click)="onNoClick()" *ngIf="!hideCloseIcon">close</mat-icon>
  </div>
  <div fxLayoutAlign="end">
    <a id="closeDialog" matRipple (click)="onNoClick()" *ngIf="data.showCloseIcon"><mat-icon class="close">close</mat-icon></a>
  </div>
  <div mat-dialog-content>
    <ng-container *ngTemplateOutlet="data.template"> </ng-container>
  </div>
</div>
