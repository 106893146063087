import { Injectable } from '@angular/core';
import { CanLoad, ActivatedRouteSnapshot, } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CanLoadByUrl implements CanLoad {
  constructor() { }
  canLoad(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return route.data.path === (<any>route).path;
  }
}
