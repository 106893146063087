import { createReducer, on } from "@ngrx/store";
import { restoreBreadcrumbs, setBreadcrumbs } from "../actions/breadcrumbs.actions";

export interface Breadcrumbs {
    label: string;
    link: string;
}

export interface BreadCrumbsState {
    breadcrumbs: Breadcrumbs[];
}

export const initialBreadCrumbsState: BreadCrumbsState = {
    breadcrumbs: [],
}

export const breadcrumbsReducer = createReducer(
    initialBreadCrumbsState,
    on(restoreBreadcrumbs, () => ({ ...initialBreadCrumbsState })),
    on(setBreadcrumbs, (state, { breadcrumbs }) => ({
        ...state,
        breadcrumbs
    })),

)