import { createAction, props } from '@ngrx/store';

export const restoreOptions = createAction('[OPTIONS-LISTS] Restore options lists');

export const getOptionsLists = createAction('[OPTIONS LISTS] Get options lists');

export const getOptionsListsSuccess = createAction(
    '[OPTIONS LISTS] Get options lists success',
    props<{ options: any[] }>()
);

export const getOptionsListsError = createAction(
    '[OPTIONS LISTS] Get options lists error',
    props<{ error: any }>()
);

export const getDeparments = createAction('[OPTIONS LISTS] Get departments');

export const getDeparmentsSuccess = createAction(
    '[OPTIONS LISTS] Get departments success',
    props<{ departments: any[] }>()
);

export const getDeparmentsError = createAction(
    '[OPTIONS LISTS] Get departments error',
    props<{ error: any }>()
);

export const getBanks = createAction('[OPTIONS LISTS] Get banks');

export const getBanksSuccess = createAction(
    '[OPTIONS LISTS] Get banks success',
    props<{ banks: any[] }>()
);

export const getBanksError = createAction(
    '[OPTIONS LISTS] Get banks error',
    props<{ error: any }>()
);

export const getStatusVerification = createAction('[OPTIONS LISTS] Get status verification');

export const getStatusVerificationSuccess = createAction(
    '[OPTIONS LISTS] Get status verification success',
    props<{ status: any[] }>()
);

export const getStatusVerificationError = createAction(
    '[OPTIONS LISTS] Get status verification error',
    props<{ error: any }>()
);

// export const getVerificationTime = createAction(
//     '[OPTIONS LISTS] Get verification time',
// );

export const setVerificationTime = createAction(
    '[OPTIONS LISTS] Set verification time',
    props<{ time: Date | null }>()
);