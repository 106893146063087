import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RoleGuard } from './role.guard';
import { AuthCanLoad } from './authCanLoad.guard';
import { CanLoadByUrl } from './canLoad/authCanLoad.guard';
import { PartnerGuard } from './guards/partner.guard';

const routes: Routes = [
  {
    path: 'url',
    loadChildren: () => import('./modules/url/url.module').then((m) => m.UrlModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [PartnerGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/anonymous/anonymous.module').then((m) => m.AnonymousModule),
    canActivate: [PartnerGuard]
  },
  {
    path: 'centro-de-ayuda',
    loadChildren: () => import('./modules/centro-de-ayuda/centro-de-ayuda.module').then((m) => m.CentroDeAyudaModule),
    canActivate: [PartnerGuard]
  },
  {
    path: 'buscar',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [PartnerGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
    canActivate: [PartnerGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/clicker/clicker.module').then((m) => m.ClickerModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'CLICKER',
    },
  },
  {
    path: 'reportes',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    canLoad: [AuthCanLoad],
    canActivate: [PartnerGuard],
    data: {
      role: 'CLICKER',
    },
  },
  {
    path: 'soporte',
    loadChildren: () => import('./modules/support/support.module').then((m) => m.SupportModule),
    canLoad: [AuthCanLoad, CanLoadByUrl],
    canActivate: [PartnerGuard],
    data: { path: 'soporte' }
  },
  {
    path: 'mi-perfil',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [AuthCanLoad],
    canActivate: [PartnerGuard],
    data: {
      role: 'CLICKER',
    },
  },
  {
    path: 'partner',
    loadChildren: () => import('./modules/partners/partners.module').then((m) => m.PartnersModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'PARTNER',
      role2: 'PARTNER-CASHIER'
    },
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
