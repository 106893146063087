import { createReducer, on } from "@ngrx/store";
import { getActiveBussiness, getActiveBussinessSuccess, getActiveBussinessError } from "../actions/bussiness.actions";
import { baseState } from "./user.reducers";

export interface BussinessState {
    activeBussiness: baseState;
}

export const initialBussinessState: BussinessState = {
    activeBussiness: {
        data: null,
        isLoading: false,
        error: null
    },
}

export const bussinessReducer = createReducer(
    initialBussinessState,
    on(getActiveBussiness, state => ({
        ...state,
        activeBussiness: {
            ...state.activeBussiness,
            isLoading: true,
            error: null
        },
    })),
    on(getActiveBussinessSuccess, (state, { bussiness }) => ({
        ...state,
        activeBussiness: {
            data: bussiness,
            isLoading: false,
            error: null
        },
    })),
    on(getActiveBussinessError, (state, { error }) => ({
        ...state,
        activeBussiness: {
            ...state.activeBussiness,
            isLoading: false,
            error
        },
    })),

)