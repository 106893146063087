<div class="row align-content-center justify-content-center card-login" @simpleFadeAnimation>
  <div class="col-sm-12 p-0">
    <mat-card color="transparent" class="login">
      <mat-card-header class="container p-0">
        <mat-card-title id="{{ idTitle }}"> </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <ng-container *ngTemplateOutlet="template"> </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
