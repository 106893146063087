import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { getPaymentDetail, getPaymentDetailError, getPaymentDetailSuccess, getPayments, getPaymentsError, getPaymentsSuccess, getRewardsGraph, getRewardsGraphError, getRewardsGraphSuccess, getRewardsHistory, getRewardsHistoryError, getRewardsHistorySuccess, getRewardsSummary, getRewardsSummaryError, getRewardsSummarySuccess } from '../actions/report.actions';
import { LinksService } from 'src/app/services/links.service';
import { ResponseService } from 'src/app/interfaces/response';


@Injectable()
export class ReportEffects {

    constructor(
        private actions$: Actions,
        private linksService: LinksService
    ) { }

    getPayments$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getPayments),
            mergeMap(({ params }) =>
                this.linksService.getPayment(params).pipe(
                    map((payments) => getPaymentsSuccess({ payments })),
                    catchError((error) => of(getPaymentsError({ error }))
                    )
                )
            )
        );
    });

    getPaymentDetail$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getPaymentDetail),
            mergeMap(({ params }) =>
                this.linksService.getDetailPaymentClicker(params).pipe(
                    map((res: ResponseService) => getPaymentDetailSuccess({ payment: res.objectResponse })),
                    catchError((error) => of(getPaymentDetailError({ error }))
                    )
                )
            )
        );
    });

    getRewardsSummary$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getRewardsSummary),
            mergeMap(({ userId }) =>
                this.linksService.getReportUser(userId).pipe(
                    map((res: ResponseService) => getRewardsSummarySuccess({ data: res.objectResponse })),
                    catchError((error) => of(getRewardsSummaryError({ error }))
                    )
                )
            )
        );
    });

    getRewardsGraph$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getRewardsGraph),
            mergeMap(({ params }) =>
                this.linksService.getRewardsReportById(params).pipe(
                    map((res: ResponseService) => getRewardsGraphSuccess({ data: res.objectResponse })),
                    catchError((error) => of(getRewardsGraphError({ error }))
                    )
                )
            )
        );
    });

    getRewardsHistory$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getRewardsHistory),
            mergeMap(({ params }) =>
                this.linksService.getRewardsReportById(params).pipe(
                    map((res: ResponseService) => getRewardsHistorySuccess({ data: res.objectResponse })),
                    catchError((error) => of(getRewardsHistoryError({ error }))
                    )
                )
            )
        );
    });

}