import { createReducer, on } from "@ngrx/store";
import {
    getBankCertificate,
    getBankCertificateError,
    getBankCertificateSuccess,
    getIdentificationCard1,
    getIdentificationCard1Error,
    getIdentificationCard1Success,
    getIdentificationCard2,
    getIdentificationCard2Error,
    getIdentificationCard2Success,
    getNovelties,
    getNoveltiesError,
    getNoveltiesSuccess,
    getRut,
    getRutError,
    getRutSuccess,
    getUserData,
    getUserDataError,
    getUserDataSuccess,
    getUserProfile,
    getUserProfileError,
    getUserProfileSuccess,
    restoreUser,
    toggleFinancialForm,
    updateAccountNumber
} from "../actions/user.actions";

export interface DataBanksInterface {
    identificationCard1: baseState;
    identificationCard2: baseState;
    bankCertificate: baseState;
    rut: baseState;
}

export interface baseState {
    data: any;
    error: any;
    isLoading: boolean;
}

export interface baseStatePagination {
    data: any;
    error: any;
    isLoading: boolean;
    total: number;
    params: any;
}

export interface UserDataState {
    data: baseState;
    profile: baseState;
    bank: DataBanksInterface;
    novelties: baseState;
    financialForm: FinancialForm;
}

export interface FinancialForm {
    show: boolean;
    updating: boolean;
}

export const initialUserState: UserDataState = {
    data: {
        data: null,
        error: null,
        isLoading: false
    },
    profile: {
        data: null,
        error: null,
        isLoading: false
    },
    bank: {
        identificationCard1: {
            data: null,
            error: null,
            isLoading: false
        },
        identificationCard2: {
            data: null,
            error: null,
            isLoading: false
        },
        bankCertificate: {
            data: null,
            error: null,
            isLoading: false
        },
        rut: {
            data: null,
            error: null,
            isLoading: false
        },
    },
    novelties: {
        data: null,
        error: null,
        isLoading: false
    },
    financialForm: {
        show: true,
        updating: false
    }
}

export const userDataReducer = createReducer(
    initialUserState,
    on(restoreUser, () => ({ ...initialUserState })),
    on(getUserData, state => ({
        ...state,
        data: {
            ...state.data,
            isLoading: true,
            error: null
        }
    })),
    on(getUserDataSuccess, (state, { userData }) => ({
        ...state,
        data: {
            data: { ...userData },
            isLoading: false,
            error: null
        }
    })),
    on(getUserDataError, (state, { error }) => ({
        ...state,
        data: {
            ...state.data,
            isLoading: false,
            error
        }
    })),
    on(getUserProfile, state => ({
        ...state,
        profile: {
            ...state.profile,
            isLoading: true,
            error: null
        }
    })),
    on(getUserProfileSuccess, (state, { profile }) => ({
        ...state,
        profile: {
            data: profile,
            isLoading: false,
            error: null
        }
    })),
    on(getUserProfileError, (state, { error }) => ({
        ...state,
        profile: {
            ...state.profile,
            isLoading: false,
            error
        }
    })),
    on(getIdentificationCard1, state => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard1: {
                ...state.bank.identificationCard1,
                isLoading: true,
                error: null
            },
        }
    })),
    on(getIdentificationCard1Success, (state, { data }) => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard1: {
                data,
                isLoading: false,
                error: null
            },
        }
    })),
    on(getIdentificationCard1Error, (state, { error }) => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard1: {
                ...state.bank.identificationCard1,
                isLoading: false,
                error
            },
        }
    })),
    on(getIdentificationCard2, state => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard2: {
                ...state.bank.identificationCard2,
                isLoading: true,
                error: null
            },
        }
    })),
    on(getIdentificationCard2Success, (state, { data }) => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard2: {
                data,
                isLoading: false,
                error: null
            },
        }
    })),
    on(getIdentificationCard2Error, (state, { error }) => ({
        ...state,
        bank: {
            ...state.bank,
            identificationCard2: {
                ...state.bank.identificationCard2,
                isLoading: false,
                error
            },
        }
    })),
    on(getBankCertificate, state => ({
        ...state,
        bank: {
            ...state.bank,
            bankCertificate: {
                ...state.bank.bankCertificate,
                isLoading: true,
                error: null
            },
        }
    })),
    on(getBankCertificateSuccess, (state, { data }) => ({
        ...state,
        bank: {
            ...state.bank,
            bankCertificate: {
                data,
                isLoading: false,
                error: null
            },
        }
    })),
    on(getBankCertificateError, (state, { error }) => ({
        ...state,
        bank: {
            ...state.bank,
            bankCertificate: {
                ...state.bank.bankCertificate,
                isLoading: false,
                error
            },
        }
    })),
    on(getRut, state => ({
        ...state,
        bank: {
            ...state.bank,
            rut: {
                ...state.bank.rut,
                isLoading: true,
                error: null
            },
        }
    })),
    on(getRutSuccess, (state, { data }) => ({
        ...state,
        bank: {
            ...state.bank,
            rut: {
                data,
                isLoading: false,
                error: null
            },
        }
    })),
    on(getRutError, (state, { error }) => ({
        ...state,
        bank: {
            ...state.bank,
            rut: {
                ...state.bank.rut,
                isLoading: false,
                error
            },
        }
    })),
    on(getNovelties, state => ({
        ...state,
        novelties: {
            ...state.data,
            isLoading: true,
            error: null
        }
    })),
    on(getNoveltiesSuccess, (state, { novelties }) => ({
        ...state,
        novelties: {
            data: { ...novelties },
            isLoading: false,
            error: null
        }
    })),
    on(getNoveltiesError, (state, { error }) => ({
        ...state,
        novelties: {
            ...state.data,
            isLoading: false,
            error
        }
    })),
    on(updateAccountNumber, (state, { number }) => ({
        ...state,
        profile: {
            data: {
                ...state.profile.data,
                bankAccountNumber: number
            },
            isLoading: false,
            error: null
        }
    })),
    on(toggleFinancialForm, (state, { show, updating }) => ({
        ...state,
        financialForm: {
            show,
            updating
        }
    })),
)