<div class="wrapper">
  <i class="tio-clear close-dialog" (click)="onNoClick()"></i>
  <ngx-slick-carousel id="onboarding-slick" class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
    (afterChange)="afterChange($event)">
    <div class="item" ngxSlickItem *ngFor="let item of slides">
      <img [src]="isMobile ? item.imagemobile : item.imageweb" class="img-mobile" alt="Onboarding" />
    </div>
  </ngx-slick-carousel>
  <div class="arrows" *ngIf="slides.length > 1">
    <button class="arrow-button" [ngClass]="{'arrow-button__disabled':firstSlide}" (click)="prev()">
      <i class="tio-back_ui arrow-icon"></i>
    </button>
    <button class="arrow-button" [ngClass]="{'arrow-button__disabled':lastSlide}" (click)="next()">
      <i class="tio-next_ui arrow-icon"></i>
    </button>
  </div>
</div>