import { Component, OnInit } from '@angular/core';
declare var dataLayer: any;

@Component({
  selector: 'app-sociallinks',
  templateUrl: './sociallinks.component.html',
  styleUrls: ['./sociallinks.component.scss'],
})
export class SociallinksComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  socialLinksGA4(element) {
    dataLayer.push({
      event: 'user_interaction',
      screen_name: 'Home',
      section: 'Footer',
      element
    });
  }
}
