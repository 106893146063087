import { createReducer, on } from "@ngrx/store";
import { baseState } from "./user.reducers";
import { getBlogs, getBlogsError, getBlogsSuccess, getPrincipalBlogs, getPrincipalBlogsError, getPrincipalBlogsSuccess, getTags, getTagsError, getTagsSuccess } from "../actions/blog.actions";

export interface BlogsState {
    principalBlogs: baseState;
    blogs: baseState;
    tags: baseState;
}

export const initialBlogsState: BlogsState = {
    principalBlogs: {
        data: null,
        isLoading: false,
        error: null
    },
    blogs: {
        data: null,
        isLoading: false,
        error: null
    },
    tags: {
        data: null,
        isLoading: false,
        error: null
    },
}

export const blogsReducer = createReducer(
    initialBlogsState,
    on(getPrincipalBlogs, state => ({
        ...state,
        principalBlogs: {
            ...state.principalBlogs,
            isLoading: true,
            error: null
        },
    })),
    on(getPrincipalBlogsSuccess, (state, { principalBlogs }) => ({
        ...state,
        principalBlogs: {
            data: principalBlogs,
            isLoading: false,
            error: null
        },
    })),
    on(getPrincipalBlogsError, (state, { error }) => ({
        ...state,
        principalBlogs: {
            ...state.principalBlogs,
            isLoading: false,
            error
        },
    })),
    on(getBlogs, state => ({
        ...state,
        blogs: {
            ...state.blogs,
            isLoading: true,
            error: null
        },
    })),
    on(getBlogsSuccess, (state, { blogs }) => ({
        ...state,
        blogs: {
            data: blogs,
            isLoading: false,
            error: null
        },
    })),
    on(getBlogsError, (state, { error }) => ({
        ...state,
        blogs: {
            ...state.blogs,
            isLoading: false,
            error
        },
    })),
    on(getTags, state => ({
        ...state,
        tags: {
            ...state.tags,
            isLoading: true,
            error: null
        },
    })),
    on(getTagsSuccess, (state, { tags }) => ({
        ...state,
        tags: {
            data: tags,
            isLoading: false,
            error: null
        },
    })),
    on(getTagsError, (state, { error }) => ({
        ...state,
        tags: {
            ...state.tags,
            isLoading: false,
            error
        },
    })),

)