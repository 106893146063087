import { ActionReducerMap, ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { BussinessState, bussinessReducer } from './reducers/bussiness.reducers';
import { UserDataState, userDataReducer } from './reducers/user.reducers';
import { MenuState, menuReducer } from './reducers/menu.reducers';
import { OptionsListsState, optionsListsReducer } from './reducers/options-lists.reducers';
import { reportReducer, ReportState } from './reducers/report.reducers';
import { BreadCrumbsState, breadcrumbsReducer } from './reducers/breadcrumbs.reducers';
import { BlogsState, blogsReducer } from './reducers/blogs.reducers';
import { SellersState, sellersReducer } from './reducers/sellers.reducers';
import { TermsState, termsReducer } from './reducers/terms-and-conditions.reducers';
import { OnboardingState, onboardingReducer } from './reducers/onboarding.reducers';

export interface AppState {
    bussiness: BussinessState;
    user: UserDataState;
    menu: MenuState,
    options: OptionsListsState;
    report: ReportState;
    breadcrumbs: BreadCrumbsState,
    blogs: BlogsState,
    sellers: SellersState,
    terms: TermsState,
    onboarding: OnboardingState
}

export const appReducers: ActionReducerMap<AppState> = {
    bussiness: bussinessReducer,
    user: userDataReducer,
    menu: menuReducer,
    options: optionsListsReducer,
    report: reportReducer,
    breadcrumbs: breadcrumbsReducer,
    blogs: blogsReducer,
    sellers: sellersReducer,
    terms: termsReducer,
    onboarding: onboardingReducer
};