import { Injectable } from '@angular/core';
import { getActiveBussiness, getActiveBussinessSuccess, getActiveBussinessError } from "../actions/bussiness.actions";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { getMenu, getMenuError, getMenuSuccess } from '../actions/menus.actions';
import { AuthService } from 'src/app/services/auth.service';


@Injectable()
export class MenuEffects {

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {

    }

    getMenu$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getMenu),
            mergeMap(() => {
                const isLoggedIn = this.authService.isLoggedIn();
                if (isLoggedIn) {
                    return this.authService.getMenuClicker().pipe(
                        map((menu) => getMenuSuccess({ menu })),
                        catchError((error) => of(getMenuError({ error }))
                        )
                    )
                } else {
                    return this.authService.getMenu().pipe(
                        map((menu) => getMenuSuccess({ menu })),
                        catchError((error) => of(getMenuError({ error }))
                        )
                    )
                }
            })
        );
    });

}