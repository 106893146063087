import { createAction, props } from '@ngrx/store';

export const setOnboardingDisplay = createAction(
    '[ONBOARDING] Set onboarding display',
    props<{ show: boolean }>()
);

export const setOnboardingStep = createAction(
    '[ONBOARDING] Set onboarding step',
    props<{ step: number }>()
);