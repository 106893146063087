<div class="social-networks-container">
    <button (click)="googleLogin();socialButtonsGA4('Google')" class="social-networks-login-btn">
        <img class="social-network-btn-icon google-btn-icon" [src]="origin + 'img/icons/google-login-icon.png'"
            alt="Google" />
        {{ isLogin ? 'Continúa' : 'Regístrate' }} con Google
    </button>
    <!-- <button (click)="facebookLogin();socialButtonsGA4('Facebook')" class="social-networks-login-btn">
        <img class="social-network-btn-icon facebook-btn-icon" [src]="origin + 'img/icons/facebook-login-icon.svg'"
            alt="Facebook" />
        {{ isLogin ? 'Continúa' : 'Regístrate' }} con Facebook
    </button> -->
</div>