import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getMenu, restoreMenu } from '../store/actions/menus.actions';
import { restoreOptions } from '../store/actions/options-lists.actions';
import { restoreReport } from '../store/actions/report.actions';
import { restoreUser } from '../store/actions/user.actions';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private store: Store
  ) { }

  logout() {
    this.store.dispatch(restoreMenu());
    this.store.dispatch(restoreOptions());
    this.store.dispatch(restoreReport());
    this.store.dispatch(restoreUser());
    this.store.dispatch(getMenu());
  }
}
