import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ResponseService } from '../interfaces/response';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataRangeInterface } from '../interfaces/dateRangeInterface';
import { formatToQueryParams } from '../helpers/formatToQueryParams';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {

  }

  urlReports = environment.URL_REPORTS;
  url = environment.URL_PROFILE;
  urlEmployee = environment.URL_PROFILE;
  urlContent = environment.URL_CONTENT;

  apiProfile = 'userprofile/GetUserProfile';
  apiReceiveCommunications = 'userprofile/setReceiveCommunications';
  apiGetDocuments = 'userprofile/downloadBase64';
  apiActivateProfile = 'userprofile/activateUser';
  apiShorUrl = 'userprofile/getShortURL';
  apiCreateUser = 'userprofile/create';
  apiIdType = 'userprofile/getIdTypes';
  apigetBankAccountNumber = 'userprofile/getBankAccountNumber';
  apichangeBankInformation = 'userprofile/changeBankInformation';
  apiDisableUser = 'userprofile/disableUser';
  apiUpdateUser = 'userprofile/updateUser';
  apiChangeOrigin = 'userprofile/changeorigin';
  apiUsers = 'userprofile/getUsers';
  apiGetBasicData = 'userprofile/getBasicData';
  apiComunications = 'userprofile/setReceiveCommunications';
  apiVerified = 'userprofile/verifyUser';
  apiDepartment = 'userprofile/getDeparments';
  apiBanks = 'userprofile/getBanks';
  apiUploadFiles = 'userprofile/upload';
  apiDownloadFile = 'userprofile/downloadBase64';
  apiDownload = 'userprofile/download';
  apiGetuserdata = 'userprofile/getuserdata';
  apiUpdateUserEmail = 'userprofile/updateUserEmail';
  apiRegisterUserTerms = 'userprofile/registeruserterms';
  apiSaveUserOnboardingViewed = 'userprofile/saveuseronboardingviewed';
  apiSaveuserRateapp = 'userprofile/saveuserrateapp';
  apiSaveUserAccepttermsReferrals = 'userprofile/saveuseraccepttermsreferrals';
  apiSaveUserDevice = 'notification/saveuserdevice';
  apiSendVerificationCode = 'notification/sendverificationcode';
  apiValidateVerificationCode = 'notification/verificatecode';
  apiUpdateEmployees = 'userprofile/updateemployees';
  apiGetExternalUsers = 'userprofile/getexternalusers';
  apiGetValidationsUsers = 'userprofile/getvalidationusers';
  apiDeleteUser = 'userprofile/deleteaccount';
  apiDeleteUserAdmin = 'userprofile/deleteuseradmin';
  apiReporUserGamification = 'reports/getreportgamification';
  apiReporReferral = 'reports/getreportreferral';
  apiReportCambios = 'reports/getreportfeedback';
  apiDeleteComments = 'reports/getreportfeedbackdeletetion';
  apiReportStories = 'reports/getreportvisitstories';
  apiOrdersNotInvoiced = 'reports/getreportordersnotinvoiced';
  apiGetTopCategories = 'reports/gettopcategories';
  apiGetReportSavers = 'reports/getreportsavers';
  apiSaveSaver = 'userprofile/savesaver';
  token = localStorage.getItem('ACCESS_TOKEN');
  authorization = this.token;
  apiSaveNews = 'new/savenew';
  apiUploadNews = 'new/uploadnew';
  apiGetNews = 'new/getnews';
  apiNoveltyById = 'new/getnoveltybyid';
  apiSaveLabel = 'new/changelabelnew';
  apiGetExcelNews = 'new/getnewsexcel';
  apiSetStatusNew = 'new/changestatusnew';
  apiReportLife = 'report/getcommissionsbyuser';
  apiReportNovetly = 'novelty/getnoveltiesbyuser';
  apiUpdateInfoClicker = 'userprofile/updateinfoclicker';
  apiSaveFeedBack = 'userprofile/savefeedback';
  apiGetStatusVerification = 'userprofile/getstatusverification';
  apiUpdateResponseAccountBank = 'userprofile/updateresponseaccountbank';
  apiSavePermision = 'userprofile/savepermissions';
  apiGetPermision = 'userprofile/getpermissions';
  apiGetFirstBuy = 'userprofile/getfirstbuy';
  apiCreateUserAdmin = 'userprofile/createuseradmin';
  apiUserInfoAditional = 'userprofile/getuserinfoaditional';
  apiGetDocumentsUser = 'userprofile/getdocumentsuser';
  apiSaveNewNovelty = 'new/savenewnovelty';
  apiGetNewsNovelties = 'new/getnewsnovelty';
  apiSaveQualificationNovelty = 'new/noveltyqualification';
  apiGetNewsById = 'new/usernews';
  apiSaveTestimony = 'testimony/savetestimony';
  apiDeleteTestimonies = 'testimony/deletetestimonies';
  apiGetTestimonies = 'testimony/gettestimonies';
  apiGetTestimoniesUser = 'testimony/gettestimoniesuser';
  apiSaveOrderTestimony = 'testimony/saveordertestimony';
  apiSaveActiveTestimony = 'testimony/saveactivetestimony';
  apiSaveCampaign = 'campaign/savecampaign';
  apiGetCampaign = 'campaign/getcampaigns';
  apiSaveVisitCampaign = 'campaign/savevisitcampaign';
  apiChangeBusinessNovelty = 'new/changebusinessnovelty';
  apiChangeDocumentNovelty = 'new/changedocumentnovelty';
  apiGetUser = 'phygital/getuser';

  userInfo$ = new BehaviorSubject<any>(null);

  onboardingView = new BehaviorSubject<any>({ onboardin: false, popUps: false });
  userOnboardingObservable = this.onboardingView.asObservable();

  sendVerificationCode() {
    return this.http.post(`${this.url}${this.apiSendVerificationCode}`, {});
  }

  validateVerificationCode(code) {
    return this.http.post(`${this.url}${this.apiValidateVerificationCode}`, { code });
  }

  public getFirstBuy() {
    return this.http.get(`${this.url}${this.apiGetFirstBuy}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  getKey(params: any) {
    const queryParams = formatToQueryParams(params);
    return this.http.get(`${this.url}userprofile/getkey${queryParams}`);
  }

  public saveCampaign(data: object) {
    return this.http.post(`${this.urlContent + this.apiSaveCampaign}`, data);
  }

  public saveVisitCampaign(id: number) {
    return this.http.post(`${this.urlContent + this.apiSaveVisitCampaign}`, { id });
  }

  public getCampaigns(params) {
    return this.http
      .get(`${this.urlContent}${this.apiGetCampaign}?from=${params.from}&to=${params.to}&orderBy=${params.orderBy}&ordination=${params.orderOrigin}&start=${params.startDate}&end=${params.endDate}&export=${params.exports}`)
      .pipe(
        map((data: ResponseService) => data.objectResponse)
      );
  }

  getUserProfile() {
    return this.http.get(this.url + this.apiProfile);
  }

  getUserPhygital(params: any): Observable<ResponseService> {
    return this.http.get<ResponseService>(`${this.url}${this.apiGetUser}?identification=${params.identification}&cellphone=${params.cellphone}`);
  }

  public activateProfile(email: string) {
    return this.http.post(`${this.url + this.apiActivateProfile}`, { email: email });
  }

  public setReceiveCommunications(data: object) {
    return this.http.post(`${this.url + this.apiReceiveCommunications}`, data);
  }

  getShortUrl(url: string) {
    return this.http.get(`${this.url}${this.apiShorUrl}?url=${encodeURIComponent(url)}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  getBasicData() {
    return this.http.get(`${this.url}${this.apiGetBasicData}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  getuserdata() {
    return this.http.get(`${this.url}${this.apiGetuserdata}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  public registerUser(userInfo: any) {
    return this.http.post(`${this.url}${this.apiCreateUser}`, userInfo);
  }

  public saveNewNovelty(newNovelty: any) {
    return this.http.post(`${this.url}${this.apiSaveNewNovelty}`, newNovelty);
  }

  getNewNovelties(id: string) {
    return this.http.get(`${this.url}${this.apiGetNewsNovelties}?id=${id}`);
  }

  public saveUserAcceptTermsReferrals(params) {
    return this.http.post(`${this.url}${this.apiSaveUserAccepttermsReferrals}`, params);
  }

  public updateEmployees() {
    return this.http.post(`${this.url}${this.apiUpdateEmployees}`, {});
  }

  public getExternalUsers() {
    return this.http.get(`${this.url}${this.apiGetExternalUsers}`);
  }

  public getValidationlUsers() {
    return this.http.get(`${this.url}${this.apiGetValidationsUsers}`);
  }

  public getReportStories() {
    return this.http.get(`${this.urlReports}${this.apiReportStories}`);
  }

  public getreportordersnotinvoiced(params: DataRangeInterface) {
    return this.http.get(`${this.urlReports}${this.apiOrdersNotInvoiced}?start=${params.startDate}&end=${params.endDate}`);
  }

  public getTop() {
    return this.http.get(`${this.urlReports}${this.apiGetTopCategories}`);
  }

  public saveUserDevice(userid: string, token: string) {
    return this.http.post(`${this.url}${this.apiSaveUserDevice}`, { userid: userid, device: token });
  }

  public uploadFiles(params: any) {
    return this.http.post(`${this.url}${this.apiUploadFiles}`, params);
  }

  public idType() {
    return this.http.get(`${this.url}${this.apiIdType}`);
  }

  public downloadFile(identification: string, typeDocument: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'APPLICATION/octet-stream',
        responseType: 'blob',
        Accept: 'application/pdf',
        observe: 'response',
      }),
    };
    return this.http.get(`${this.url}${this.apiDownloadFile}?identification=${identification}&typeDocument=${typeDocument}`, httpOptions);
  }

  public downloadFiles(data) {
    const httpOptions = {
      responseType: 'blob' as 'text',
    };
    return this.http.post(`${this.url}${this.apiDownload}`, data, httpOptions);
  }

  public getDepartments() {
    return this.http.get(`${this.url}${this.apiDepartment}`);
  }

  public getBanks() {
    return this.http.get(`${this.url}${this.apiBanks}`);
  }

  public saveOnboarding(save: any) {
    return this.http.post(`${this.url}${this.apiSaveUserOnboardingViewed}`, { viewed: save });
  }

  public rateapp(save: any) {
    return this.http.post(`${this.url}${this.apiSaveuserRateapp}`, { rateapp: save });
  }

  public validateEmployee(id: string, document: string) {
    return this.http.get(`${this.urlEmployee}validateEmployee?id=${id}&documentType=${document}`);
  }

  public searchUsers(term?: any) {
    return this.http.get(`${this.url}${this.apiUsers}?searchText=${term.term}&from=${term.from}&to=${term.to}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  public statusUser(id: any, value: boolean) {
    return this.http.post(`${this.url}${this.apiDisableUser}`, { userid: id, value });
  }

  public updateUserEmail(userid: string, email: string) {
    return this.http.post(`${this.url}${this.apiUpdateUserEmail}`, { userid, email });
  }

  public changeOrigin(id: any, value: boolean) {
    return this.http.post(`${this.url}${this.apiChangeOrigin}`, { userid: id, isEmployeeGrupoExito: value });
  }

  public getBankAccountNumber(password: any) {
    return this.http.post(`${this.url}${this.apigetBankAccountNumber}`, { password });
  }

  public changeBankInformation(id: any, data: any) {
    return this.http.post(`${this.url}${this.apichangeBankInformation}`, data);
  }

  public updateUser(data: any) {
    return this.http.post(`${this.url}${this.apiUpdateUser}`, data);
  }

  public registeruserterms(id: any) {
    return this.http.post(`${this.url}${this.apiRegisterUserTerms}`, { idbusiness: id });
  }

  public comunitcations(id: any, value: boolean) {
    return this.http.post(`${this.url}${this.apiComunications}`, { userid: id, value });
  }

  public verifiedUser(id: any, value: number) {
    return this.http.post(`${this.url}${this.apiVerified}`, { userid: id, verified: value });
  }

  public deleteUser(data: any) {
    return this.http.post(`${this.url}${this.apiDeleteUser}`, data);
  }
  public getReportGamification() {
    return this.http.get(`${this.urlReports}${this.apiReporUserGamification}`);
  }
  public saveNews(data: any) {
    return this.http.post(`${this.url}${this.apiSaveNews}`, data);
  }
  public uploadFileNews(data: any) {
    return this.http.post(`${this.url}${this.apiUploadNews}`, data);
  }
  public getAllNews(data: any) {
    return this.http.post(`${this.urlReports}${this.apiGetNews}`, data).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  public getNoveltyById(id: string, userId: any) {
    return this.http.get(`${this.urlReports}${this.apiNoveltyById}?id=${id}&userId=${userId}`);
  }

  public getNoveltiesById(id) {
    return this.http.get(`${this.url}${this.apiGetNewsById}?userId=${id}`);
  }

  public getExportNewsExcel(data: any) {
    return this.http.post(`${this.urlReports}${this.apiGetExcelNews}`, data);
  }

  public setStatus(data: any) {
    return this.http.post(`${this.url}${this.apiSetStatusNew}`, data);
  }

  public saveBusinessNovelty(data: any) {
    return this.http.post(`${this.url}${this.apiChangeBusinessNovelty}`, data);
  }

  public saveDocumentNovelty(data: any) {
    return this.http.post(`${this.url}${this.apiChangeDocumentNovelty}`, data);
  }

  public saveLabels(data: any) {
    return this.http.post(`${this.url}${this.apiSaveLabel}`, data);
  }

  public getHojaVida(data: any) {
    return this.http.get(`${this.urlReports}${this.apiReportLife}?&userid=${data.userId}&start=${data.start}&end=${data.end}`);
  }

  public getNoveltyUser() {
    return this.http.get(`${this.urlReports}${this.apiReportNovetly}?from=1&to=50&orderBy=CONSECUTIVE&ordination=DESC`);
  }

  public saveQualificationNovelty(data: any) {
    return this.http.patch(`${this.url}${this.apiSaveQualificationNovelty}`, data);
  }

  public getDocuments(document: string) {
    return this.http.get(`${this.url}${this.apiGetDocuments}?typeDocument=${document}`);
  }

  public updateInfoClicker(data: any) {
    return this.http.post(`${this.url}${this.apiUpdateInfoClicker}`, data);
  }
  public saveFeedback(data: any) {
    return this.http.post(`${this.url}${this.apiSaveFeedBack}`, data);
  }
  public getReportCommets(params: any) {
    return this.http.get(`${this.urlReports}${this.apiReportCambios}?&start=${params.start}&end=${params.end}`);
  }

  public getDeleteCommetsRest(params: any) {
    return this.http.get(`${this.urlReports}${this.apiDeleteComments}?&start=${params.start}&end=${params.end}`);
  }

  public getReportReferral(params: any) {
    return this.http.get(`${this.urlReports}${this.apiReporReferral}?&start=${params.start}&end=${params.end}`);
  }

  public getReportsavers() {
    return this.http.get(`${this.urlReports}${this.apiGetReportSavers}`);
  }

  public saveSaver() {
    return this.http.post(`${this.url + this.apiSaveSaver}`, {});
  }

  public getStatusVerification() {
    return this.http.get(`${this.url}${this.apiGetStatusVerification}`);
  }

  public postUpdateResponseAccountBank(data: any) {
    return this.http.post(`${this.url}${this.apiUpdateResponseAccountBank}`, data);
  }

  public getPermision(): Observable<ResponseService> {
    return this.http.get<ResponseService>(`${this.url + this.apiGetPermision}`);
  }

  public getPermisionPartner(idBusiness: number) {
    return this.http.get(`${this.url + this.apiGetPermision}?idbusiness=${idBusiness}&rol=PARTNER`);
  }

  public savePermision(data: any) {
    return this.http.post(`${this.url + this.apiSavePermision}`, data);
  }

  public deleteUserAdmin(data: any) {
    return this.http.post(`${this.url}${this.apiDeleteUserAdmin}`, data);
  }

  public addUserAdmin(data: any) {
    return this.http.post(`${this.url}${this.apiCreateUserAdmin}`, data);
  }

  public getUserInfoAditional(userId) {
    return this.http.get(`${this.url}${this.apiUserInfoAditional}?userid=${userId}`);
  }

  public getDocumentsUser(userId) {
    return this.http.get(`${this.url}${this.apiGetDocumentsUser}?userid=${userId}`);
  }

  public getTestimonies(visible = false) {
    return this.http.get(`${this.url + this.apiGetTestimonies}?visible=${visible}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  public getTestimoniesUser() {
    return this.http.get(`${this.url + this.apiGetTestimoniesUser}`).pipe(
      map((data: ResponseService) => data.objectResponse)
    );
  }

  public saveTestimonies(data: object) {
    return this.http.post(`${this.url + this.apiSaveTestimony}`, data);
  }

  public deleteTestimonies(data: any) {
    return this.http.post(`${this.url + this.apiDeleteTestimonies}`, data);
  }

  public saveOrderTestimonies(data: any) {
    return this.http.post(`${this.url + this.apiSaveOrderTestimony}`, data);
  }

  public saveActiveTestimonies(data: any) {
    return this.http.post(`${this.url + this.apiSaveActiveTestimony}`, data);
  }

}


