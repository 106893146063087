import { createReducer, on } from "@ngrx/store";
import { getTerms, getTermsError, getTermsSuccess } from "../actions/terms-and-conditions.actions";

export interface TermsState {
    terms: any;
    isLoading: boolean;
    error: any;
}

export const initialTermsState: TermsState = {
    terms: null,
    isLoading: false,
    error: null
}

export const termsReducer = createReducer(
    initialTermsState,
    on(getTerms, state => ({ ...state, isLoading: true })),
    on(getTermsSuccess, (state, { terms }) => ({
        ...state,
        terms,
        isLoading: false,
    })),
    on(getTermsError, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),

)