<div fxLayout="column">
  <span *ngFor="let item of options; let i = index" class="menu-mobile">
    <ng-container *ngIf="item.description === 'Sin Grupo'; else defaultItem">
      <span [ngClass]="{
        adminMenu: (auth.getRole$ | async) === 'ADMIN' || ((auth.getRole$ | async) === 'SUPERADMIN' && auth.isLoggedIn())
      }">
        <span *ngFor="let item of item.menus; let i = index">
          <a routerLink="{{ item.route }}" *ngIf="item.active" routerLinkActive="active"
            (click)="hide();menuOptionsGA4(item.description)" class="menu-item">
            <i *ngIf="item.icon" ngClass="{{ item.icon }} icsub"></i>
            {{ item.description | replaceRewards | async }}
          </a>
        </span>
      </span>
    </ng-container>
    <ng-template #defaultItem>
      <ng-container *ngIf="!item.menus; else submenus">
        <a routerLink="{{ item.route }}" *ngIf="item.active" routerLinkActive="active" (click)="hide()"
          class="menu-item" (click)="menuOptionsGA4(item.description)">
          <i *ngIf="item.icon" ngClass="{{ item.icon }} icsub"></i>
          {{ item.description | replaceRewards | async }}
        </a>
      </ng-container>
      <ng-template #submenus>
        <mat-accordion>
          <mat-expansion-panel class="mat-expansion-panel" routerLinkActive="active">
            <mat-expansion-panel-header (click)="menuOptionsGA4(item.description)">
              <span fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" ngClass="{{ item.description }} f-16"
                class="menu-item__group">
                <i *ngIf="item.icon" ngClass="{{ item.icon }} icsub"></i>
                {{ item.description | replaceRewards | async }}
              </span>
            </mat-expansion-panel-header>
            <mat-list role="list">
              <mat-list-item *ngFor="let item of item.menus" class="pl-4" [disableRipple]="true">
                <a routerLink="{{ item.route }}" *ngIf="item.active" (click)="hide()" class="wrapper-subitem"
                  (click)="menuOptionsGA4(item.description)">
                  <span class="menu-subitem" routerLinkActive="active">
                    <span class="subitem-circle">•</span>
                    <span class="subitem-name">
                      {{ item.description | replaceRewards | async }}
                    </span>
                  </span>
                </a>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>
    </ng-template>
  </span>
</div>