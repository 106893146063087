import { createSelector } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { DataBanksInterface, UserDataState, baseState } from "../reducers/user.reducers";


export const selectUser = (state: AppState) =>
    state.user;

export const selectUserData = createSelector(
    selectUser, (state: UserDataState) => state.data
);

export const selectUserProfile = createSelector(
    selectUser, (state: UserDataState) => state.profile
);

export const selectUserBank = createSelector(
    selectUser, (state: UserDataState) => state.bank
);

export const selectUserIdentificationCard1 = createSelector(
    selectUserBank,
    (state: DataBanksInterface) => state.identificationCard1
);

export const selectUserIdentificationCard2 = createSelector(
    selectUserBank,
    (state: DataBanksInterface) => state.identificationCard2
);

export const selectUserBankCertificate = createSelector(
    selectUserBank,
    (state: DataBanksInterface) => state.bankCertificate
);

export const selectUserRut = createSelector(
    selectUserBank,
    (state: DataBanksInterface) => state.rut
);

export const selectUserNovelties = createSelector(
    selectUser, (state: UserDataState) => state.novelties
);

export const selectFinancialForm = createSelector(
    selectUser, (state: UserDataState) => state.financialForm
);