import { BlogsEffects } from "./blogs.effects";
import { BussinessEffects } from "./bussiness.effects";
import { MenuEffects } from "./menu.effects";
import { OptionsListsEffects } from "./options-lists.effects";
import { ReportEffects } from "./report.effects";
import { SellersEffects } from "./sellers.effects";
import { TermsAndConditionsEffects } from "./terms-and-conditions.effects";
import { UserEffects } from "./user.effects";


export const EffectsArray: any[] = [
    BussinessEffects,
    UserEffects,
    MenuEffects,
    OptionsListsEffects,
    ReportEffects,
    BlogsEffects,
    SellersEffects,
    TermsAndConditionsEffects
];