import { Component, OnInit, HostListener, Input, OnDestroy, Output, EventEmitter, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription, skip } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { ResponseService } from 'src/app/interfaces/response';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectMenu } from 'src/app/store/selectors/menu.selectors';
import decode from 'jwt-decode';
import { selectUserProfile } from 'src/app/store/selectors/user.selectors';
import { selectOnboarding } from 'src/app/store/selectors/onboarding.selectors';
import { IntroJSService } from 'src/app/services/introjs.service';
declare var dataLayer: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isHome: boolean;
  @Input() internal: boolean;
  @Input() name: string;
  @Output() sidenav = new EventEmitter();
  menuItems: any = [];
  role: string;

  private subscription: Subscription = new Subscription();
  menuStore$: Subscription = new Subscription();
  listenOnboarding$: Subscription = new Subscription();

  isLoggedIn: any;
  firstNames: string;
  lastNames: string;
  amount: any;

  notifications = [];
  total: any;

  constructor(
    private utils: UtilsService,
    public auth: AuthService,
    private _content: ContentService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store,
    private introJSService: IntroJSService
  ) { }

  ngOnInit() {
    this.getAmount();
    this.getMenu();
    this.getNotications();
  }

  ngAfterViewInit(): void {
    this.listenOnboarding();
  }

  listenOnboarding() {
    this.listenOnboarding$ = this.store.select(selectOnboarding).subscribe(({ show, step }) => {
      if (show && step === 6) {
        this.introJSService.onboardingStepSix();
      }
    });
  }

  public getMenu() {
    this.menuStore$ = this.store.select(selectMenu).subscribe(menu => {
      if (menu) {
        this.menuItems = menu[0]?.menus;
      }
    });
  }

  getUser() {
    this.store.select(selectUserProfile).subscribe(({ isLoading }) => {
      if (!isLoading) {
        const token = localStorage.getItem('ACCESS_TOKEN');
        const tokenDecode = decode(token);
        if (tokenDecode?.role === 'PARTNER' || tokenDecode?.role === 'PARTNER-CASHIER') {
          return false;
        }
        return true;
      }
    })
  }

  public open() {
    this.sidenav.emit();
  }

  public logout() {
    this.utils.logout();
  }

  @HostListener('over')
  showLogin() {
    this.utils.showloginForm();
  }

  @HostListener('over')
  showMenu() {
    this.utils.showMenu();
  }

  @HostListener('over')
  openRegister() {
    this.utils.showRegisterForm();
  }

  public getAmount() {
    if (isPlatformBrowser(this.platformId)) {
      let count = 0;
      const interval = setInterval(() => {
        this.amount = localStorage.getItem('Amount');
        count++;
      }, 500);

      if (count === 3) {
        clearInterval(interval);
      }
    }
  }

  public getNotications() {
    this.auth.getRole$.subscribe((role) => {
      this.role = role;
      if (role === 'CLICKER') {
        this._content.getNotificationAdmin(false).subscribe((notification: ResponseService) => {
          const { published, total } = notification.objectResponse;
          this.notifications = published;
          if (total > 0) {
            this.total = total;
          }
        });
      }
    });
  }

  headerGA4(element) {
    dataLayer.push({
      event: 'user_interaction',
      screen_name: 'Home',
      section: 'Header menú',
      element,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.menuStore$.unsubscribe();
    this.listenOnboarding$.unsubscribe();
  }

}


