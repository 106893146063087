<section class="modal-information">
    <h2 class="modal-information__title">Para seguir disfrutando de Referidos éxito por favor ingresa los siguientes
        datos.</h2>
    <p class="modal-information__subtitle">Estos datos nos permitirán confirmar tu documentación bancaria.</p>
    <form [formGroup]="homeInformationForm" class="information-form">
        <span class="information-form__input-container">
            <label class="information-form__input-label">
                {{ 'REGISTER.DEPARTMENT' | translate }}
            </label>
            <span class="information-form__input-field">
                <mat-form-field class="information-form__input-control">
                    <input matInput id="departmentCode" [matAutocomplete]="auto" formControlName="department"
                        autocomplete="off" (blur)="checkDepartment()" />
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayElement">
                        <mat-option (onSelectionChange)="selectDepartment(department)"
                            *ngFor="let department of filteredDepartments; let i = index" [value]="department">
                            <span>{{ department.description }}</span>
                        </mat-option>
                    </mat-autocomplete>

                    <mat-error id="selectDepartementError"
                        *ngIf="homeInformationForm.controls.department.invalid && homeInformationForm.controls.department.errors.incorrect">{{
                        'REGISTER.ERROR_DEPARTMENT' | translate }}</mat-error>
                    <mat-error id="requiredDepartement"
                        *ngIf="homeInformationForm.controls.department.invalid && homeInformationForm.controls.department.errors.required">
                        {{ 'GENERIC.REQUIRED' | translate : { formValue: 'Departamento' } }}</mat-error>
                </mat-form-field>
            </span>
        </span>

        <span class="information-form__input-container">
            <label class="information-form__input-label">
                {{ 'REGISTER.CITY' | translate }}
            </label>
            <span class="information-form__input-field">
                <mat-form-field class="information-form__input-control">
                    <input matInput id="city" [matAutocomplete]="city" formControlName="city" autocomplete="off"
                        (blur)="checkCity()" />
                    <mat-autocomplete #city="matAutocomplete" [displayWith]="displayElement">
                        <mat-option (onSelectionChange)="selectCity(city)" *ngFor="let city of filteredCities"
                            [value]="city">
                            <span>{{ city.description }}</span>
                        </mat-option>
                    </mat-autocomplete>

                    <mat-error id="selectCityError"
                        *ngIf="homeInformationForm.controls.city.invalid && homeInformationForm.controls.city.errors.incorrect">{{
                        'REGISTER.ERROR_CITY' | translate }}</mat-error>
                    <mat-error id="requiredCity"
                        *ngIf="homeInformationForm.controls.city.invalid && homeInformationForm.controls.city.errors.required">
                        {{ 'GENERIC.REQUIRED' | translate : { formValue: 'Ciudad' } }}</mat-error>
                </mat-form-field>
            </span>
        </span>

        <span class="information-form__input-container">
            <label class="information-form__input-label">
                {{ 'REGISTER.ADDRESS' | translate }}
            </label>
            <span class="information-form__input-field">
                <mat-form-field class="information-form__input-control">
                    <input matInput id="address" type="text" formControlName="address" autocomplete="on" />
                    <mat-error id="requiredAddress"
                        *ngIf="homeInformationForm.controls.address.invalid && homeInformationForm.controls.address.errors.required">
                        {{ 'GENERIC.REQUIRED' | translate : { formValue: 'Dirección' } }}</mat-error>
                </mat-form-field>
            </span>
        </span>

        <div class="information-form__button-container">
            <button id="editHomeInformationBtn"
                class="button-base button-base__primary-black gtmPerfilInfoViviendaClicGuardar" (click)="updateUser()">
                <span class="gtmPerfilInfoViviendaClicGuardar">{{ 'GENERIC.SAVE' | translate }}</span>
            </button>
        </div>
    </form>
</section>