import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import decode from 'jwt-decode';
import { ContentService } from 'src/app/services/content.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserProfile } from 'src/app/store/selectors/user.selectors';
import { WindowClass } from 'src/app/helpers/window-location';
import { DateFormat } from '../../helpers/date-format';
import { ResponseService } from 'src/app/interfaces/response';
import { LinksService } from 'src/app/services/links.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements OnInit, OnDestroy {
  saveLink$: Subscription = new Subscription();
  @ViewChild('slickModal', { static: false })
  slickModal: SlickCarouselComponent;
  isMobile: boolean = true;
  breakpointSubscription: Subscription = new Subscription();
  user$: Subscription = new Subscription();
  currentId = 0;
  userId;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    infinite: true,
    arrows: false,
    swipeToSlide: true,
    variableWidth: false,
  };
  showArrowButtons: boolean = false;
  url: string;
  currentUser: any = {};

  constructor(
    private content: ContentService,
    public dialogRef: MatDialogRef<any>,
    private breakPointService: BreakpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private linksService: LinksService
  ) {
    if (data.length > 1) {
      this.showArrowButtons = true;
      this.slideConfig.dots = true;
    }
  }

  ngOnInit() {
    this.getUser();
    this.breakpoint();
  }

  getUser() {
    this.user$ = this.store.select(selectUserProfile).subscribe(({ data, isLoading }) => {
      if (data && !isLoading) {
        this.currentUser = data;
        this.userId = data.userId;
        this.dialogRef.beforeClosed()
          .subscribe(() => {
            this.saveVisitOffer();
          });
      }
    })
  }

  breakpoint() {
    this.breakpointSubscription = this.breakPointService
      .isWidthLessThanBreakpoint('800')
      .subscribe((res: boolean) => {
        this.isMobile = res;
      });
  }

  slick({ slick }, saveVisit = false) {
    const currentSlide = slick.currentSlide;
    this.currentId = parseInt(slick.$slides[currentSlide].getAttribute('data-key'));
    saveVisit && this.saveVisitOffer();
  }

  afterChange({ slick }) {
    const currentSlide = slick.currentSlide;
    this.currentId = parseInt(slick.$slides[currentSlide].getAttribute('data-key'));
  }

  saveVisitOffer() {
    const currentElement = this.data.find(element => element.id.toString() === this.currentId.toString());
    if (!currentElement.new) {
      this.content.saveVisitOffer({ idoffer: this.currentId, userId: parseInt(this.userId) }).subscribe();
      this.data = this.data.map(element => {
        if (element.id.toString() === this.currentId.toString()) element.new = true;
        return element;
      })
    }
  }

  detectLink(story: any) {
    if (story.idbusiness) {
      this.saveLink(story);
    } else {
      WindowClass.open(story.link);
    }
  }

  saveLink(story: any) {
    const data = {
      link: story.link,
      identification: this.currentUser.identification,
      plu: 'POPUP',
      business: story.idbusiness,
      creationDate: DateFormat.format(new Date(), 'YYYY-MM-DD HH:MM'),
    };
    this.saveLink$ = this.linksService.saveLink(data).subscribe((resp: ResponseService) => {
      if (resp.state === 'Success') {
        WindowClass.open(resp.objectResponse.link);
      }
    });
  }

  closeMatDialog(): void {
    this.dialogRef.close();
  }

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription && this.breakpointSubscription.unsubscribe();
  }
}
