import { createReducer, on } from "@ngrx/store";
import {
    getBanks,
    getBanksError,
    getBanksSuccess,
    getDeparments,
    getDeparmentsError,
    getDeparmentsSuccess,
    getOptionsLists,
    getOptionsListsError,
    getOptionsListsSuccess,
    getStatusVerification,
    getStatusVerificationError,
    getStatusVerificationSuccess,
    restoreOptions,
    setVerificationTime
} from "../actions/options-lists.actions";
import { baseState } from "./user.reducers";

export interface OptionsListsState {
    options: baseState;
    departments: baseState;
    banks: baseState;
    statusVerification: baseState;
    verificationTime: Date;
}

export const initialOptionsListsState: OptionsListsState = {
    options: {
        data: null,
        isLoading: false,
        error: null
    },
    departments: {
        data: null,
        isLoading: false,
        error: null
    },
    banks: {
        data: null,
        isLoading: false,
        error: null
    },
    statusVerification: {
        data: null,
        isLoading: false,
        error: null
    },
    verificationTime: null,
}

export const optionsListsReducer = createReducer(
    initialOptionsListsState,
    on(restoreOptions, () => ({ ...initialOptionsListsState })),
    on(getOptionsLists, state => ({
        ...state,
        options: {
            ...state.options,
            isLoading: true,
            error: null
        }
    })),
    on(getOptionsListsSuccess, (state, { options }) => ({
        ...state,
        options: {
            data: options,
            isLoading: false,
            error: null
        }
    })),
    on(getOptionsListsError, (state, { error }) => ({
        ...state,
        options: {
            ...state.options,
            isLoading: false,
            error
        }
    })),
    on(getDeparments, state => ({
        ...state,
        departments: {
            ...state.departments,
            isLoading: true,
            error: null
        }
    })),
    on(getDeparmentsSuccess, (state, { departments }) => ({
        ...state,
        departments: {
            data: departments,
            isLoading: false,
            error: null
        }
    })),
    on(getDeparmentsError, (state, { error }) => ({
        ...state,
        departments: {
            ...state.departments,
            isLoading: false,
            error
        }
    })),
    on(getBanks, state => ({
        ...state,
        banks: {
            ...state.banks,
            isLoading: true,
            error: null
        }
    })),
    on(getBanksSuccess, (state, { banks }) => ({
        ...state,
        banks: {
            data: banks,
            isLoading: false,
            error: null
        }
    })),
    on(getBanksError, (state, { error }) => ({
        ...state,
        banks: {
            ...state.banks,
            isLoading: false,
            error
        }
    })),
    on(getStatusVerification, state => ({
        ...state,
        statusVerification: {
            ...state.statusVerification,
            isLoading: true,
            error: null
        }
    })),
    on(getStatusVerificationSuccess, (state, { status }) => ({
        ...state,
        statusVerification: {
            data: status,
            isLoading: false,
            error: null
        }
    })),
    on(getStatusVerificationError, (state, { error }) => ({
        ...state,
        statusVerification: {
            ...state.statusVerification,
            isLoading: false,
            error
        }
    })),
    on(setVerificationTime, (state, { time }) => ({
        ...state,
        verificationTime: time
    })),

)