<header>
  <!-- Mobile - not login -->
  <div fxHide.gt-sm="true" *ngIf="!auth.isLoggedIn()" fxLayoutAlign="space-between stretch" fxLayoutGap="1rem"
    class="divContainer">
    <div fxLayout="row" fxLayoutGap="1rem">
      <span *ngIf="role !== 'PARTNER' && role !== 'PARTNER-CASHIER'" fxLayout="column" fxLayoutAlign="center center"
        (click)="open();headerGA4('Menú')" class="burger-menu cursor-pointer">
        <img width="24" height="24" loading="lazy" src="assets/img/menu2.svg" alt="menu" />
        <span>Menú</span>
      </span>

      <img loading="lazy" [routerLink]="['inicio']" class="principal-logo principal-logo__mobile"
        src="assets/img/icon-sm-salmon-bg.svg" alt="logo-clickam" (click)="headerGA4('Referidos éxito')" />
    </div>

    <div fxLayoutAlign="center center">
      <a class="login-text" mat-button (click)="showLogin();headerGA4('Iniciar sesión')">
        {{ 'LOGIN.LOGIN' | translate }}
      </a>

      <span class="spanLink-active">
        <a class="login-text" (click)="openRegister();headerGA4('Registrarse')">{{ 'GENERIC.REGISTER2' | translate
          }}</a>
      </span>
    </div>
  </div>

  <!-- Desktop - not login -->
  <div fxHide fxShow.gt-sm="true" *ngIf="!auth.isLoggedIn()" fxLayoutAlign="space-between center" fxLayoutGap="1rem"
    class="container divContainer">
    <div class="nav-options-container">
      <img loading="lazy" [routerLink]="['inicio']" class="principal-logo gtmHomeLogoClickam"
        src="assets/img/icon-lg-salmon-color.svg" alt="logo-clickam" (click)="headerGA4('Referidos éxito')" />

      <div class="divider"></div>

      <nav>
        <ul fxLayoutAlign="space-between center" fxLayoutGap="1rem" class="links">
          <li *ngFor="let item of menuItems">
            <a [routerLink]="item.route" (click)="headerGA4(item.description)">{{ item.description |
              replaceRewards | async }}</a>
          </li>
        </ul>
      </nav>
    </div>

    <div fxLayoutAlign="center center" class="loginButtons">
      <a class="login-text gtmHomeMenuClickamIniciarSesion" mat-button
        (click)="showLogin();headerGA4('Iniciar sesión')">
        {{ 'LOGIN.LOGIN' | translate }}
      </a>
      <a class="btnRegister login-text gtmHomeMenuClickamRegistrarse" mat-button
        (click)="openRegister();headerGA4('Registrarse')">{{
        'GENERIC.REGISTER2' | translate }}</a>
    </div>
  </div>

  <!-- Mobile and desktop - login -->
  <div *ngIf="auth.isLoggedIn()" fxLayoutAlign="space-between stretch" fxLayoutGap="1rem"
    class="divContainer divContainerLoggedIn">
    <div fxLayout="row" fxLayoutGap="1rem">
      <span *ngIf="role !== 'PARTNER'&& role !== 'PARTNER-CASHIER'" fxLayout="column" fxLayoutAlign="center center"
        (click)="open();headerGA4('Menú')" class="burger-menu cursor-pointer">
        <img width="24" height="24" loading="lazy" src="assets/img/menu2.svg" alt="menu" />
        <span>Menú</span>
      </span>

      <img loading="lazy" [routerLink]="['inicio']" class="principal-logo gtmHomeLogoClickam "
        src="assets/img/icon-lg-salmon-color.svg" alt="Referidos éxito" (click)="headerGA4('Referidos éxito')" />

    </div>

    <div fxLayoutAlign="center center" fxLayoutGap="0.5rem" class="divIcons">
      <!-- El id centroDeAyudaOnboarding se utiliza en el servicio introJSService -->
      <a [routerLink]="['/centro-de-ayuda']" fxLayoutAlign="center center" class="iconContainer gtmIconoCentroDeAyuda"
        (click)="headerGA4('Centro de ayuda')" *ngIf="(role !== 'PARTNER') && (role !== 'PARTNER-CASHIER')"
        id="centroDeAyudaOnboarding">
        <i class="tio-help icon cursor-pointer gtmIconoCentroDeAyuda"></i>
      </a>
      <span *ngIf="role === 'CLICKER'" fxLayoutAlign="center center" class="iconContainer gtmIconoNotificaciones"
        [matMenuTriggerFor]="notificationsmenu" (click)="headerGA4('Notificaciones')">
        <span [matBadge]="total" matBadgeOverlap="false" matBadgeColor="warn" matBadgeSize="small">
          <i class="tio-notifications icon cursor-pointer gtmIconoNotificaciones"></i>
        </span>
      </span>
      <mat-menu #notificationsmenu="matMenu" xPosition="before">
        <div class="menuProfile">
          <span class="title-notification">Notificaciones</span>
          <mat-list *ngIf="notifications.length">
            <mat-list-item *ngFor="let notification of notifications | slice: 0:3">
              <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutGap="20px">
                  <div>
                    <i class="tio-notice icon-props"></i>
                  </div>

                  <div fxLayout="column" [routerLink]="['/notificaciones']" class="cursor-pointer">
                    <span class="title-inner">{{ notification.title }}</span>
                    <span class="date">{{ notification.date | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>

                <div *ngIf="!notification.viewed">
                  <i class="tio-record icr"></i>
                </div>
              </div>
            </mat-list-item>
          </mat-list>

          <div class="mt-3" *ngIf="!notifications.length" fxLayout="row" fxLayoutAlign="center center">
            <span class="not-notifications gtmIconoNotificaciones" fxLayout="column" fxLayoutAlign="center center">
              <i class="tio-notifications gtmIconoNotificaciones"></i>
              <p>¡Pronto recibirás grandes noticias!</p>
            </span>
          </div>

          <span class="separate gtmIconoNotificaciones"></span>
          <button class="open-notifications gtmIconoNotificaciones" [routerLink]="['/notificaciones']"
            mat-menu-item>Abrir Notificaciones</button>
        </div>
      </mat-menu>
      <span fxLayoutAlign="center center" class="iconContainer gtmIconoPerfil" [matMenuTriggerFor]="perfilOptions"
        (click)="headerGA4('Mi perfil')">
        <i class="tio-account_circle icon cursor-pointer gtmIconoPerfil"></i>
      </span>
      <mat-menu #perfilOptions="matMenu" xPosition="before">
        <div class="menuProfile">
          <button class="gtmPerfilMenuLightboxMiPerfil" *ngIf="role === 'CLICKER'" [routerLink]="['/mi-perfil']"
            mat-menu-item>Mi perfil</button>
          <button class="gtmPerfilMenuCerrarsesion" (click)="logout()" mat-menu-item>Cerrar sesión</button>
        </div>
      </mat-menu>
    </div>
  </div>
</header>