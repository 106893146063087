import { Component, OnInit, OnDestroy, HostListener, Inject, PLATFORM_ID, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { ResponseService } from 'src/app/interfaces/response';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import decode from 'jwt-decode';
import { LinksService } from 'src/app/services/links.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { WindowClass } from 'src/app/helpers/window-location';
import { WidgetService } from 'src/app/services/widget.service';
import { Store } from '@ngrx/store';
import { getUserData, getUserProfile } from 'src/app/store/actions/user.actions';
import { getMenu } from 'src/app/store/actions/menus.actions';
declare var dataLayer: any;

@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class LoginformComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private utils: UtilsService,
    private link: LinksService,
    @Inject(PLATFORM_ID) private platformId: object,
    private activatedRoute: ActivatedRoute,
    private widgetService: WidgetService,
    private store: Store
  ) { }

  private subscription: Subscription = new Subscription();

  loginForm: UntypedFormGroup;
  isSubmitted = false;
  emailPattern = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
  loggedIn: boolean;

  ngOnInit() {
    this.loginForm = this.fb.group({
      Username: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(64)]],
      Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
    });
  }

  showUser(user: any) {
    this.login(user);
  }

  /**
   * Metodo para loguearse
   * @params recibe Password y Username
   */

  public loginHandle() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const loginData = {
      Password: btoa(this.loginForm.value.Password),
      Username: this.loginForm.value.Username,
    };
    this.login(loginData);
  }

  login(loginData: any) {
    this.subscription = this.authService.login(loginData).subscribe({
      next: (resp: ResponseService) => {
        if (resp.state === 'Success') {
          setTimeout(() => {
          }, 500);
          this.link.getAmount();
          localStorage.setItem('ACCESS_TOKEN', resp.objectResponse.token);
          localStorage.setItem('REFRESH_TOKEN', resp.objectResponse.refreshToken);
          this.utils.hideloginForm();
          this.authService.routeBased();
          this.store.dispatch(getUserData());
          this.store.dispatch(getUserProfile());
          this.store.dispatch(getMenu());
        } else {
          Swal.fire({
            title: 'Login inválido',
            text: resp.userMessage,
            type: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonClass: 'button-base button-base__cancel',
          });
        }
      },
      error: (error) => {
        Swal.fire({
          title: error.statusText,
          text: error.error.userMessage,
          type: 'error',
          confirmButtonText: 'Aceptar',
          confirmButtonClass: 'button-base button-base__cancel',
        });
      },
      complete: () => {
        const token = localStorage.getItem('ACCESS_TOKEN');
        const tokenDecode = decode(token);
        if (tokenDecode.role === 'CLICKER') {
          const queryParams = { ...this.activatedRoute.snapshot.queryParams };
          delete queryParams['widget'];
          this.router.navigate([], { queryParams }).then(() => {
            this.widgetService.dispatchWidgetEvent();
          });
        }
      }
    });
  }

  @HostListener('over')
  hideLogin() {
    this.utils.hideloginForm();
  }

  @HostListener('over')
  showRegister() {
    this.utils.showRegisterForm();
  }

  @HostListener('over')
  showForgot() {
    this.utils.showForgot();
  }

  @HostListener('over')
  showActivate() {
    this.utils.showActivate();
  }

  loginFormGA4(element) {
    dataLayer.push({
      event: 'sign_in',
      element,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
