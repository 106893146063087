import { Injectable } from '@angular/core';
import { getActiveBussiness, getActiveBussinessSuccess, getActiveBussinessError } from "../actions/bussiness.actions";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';


@Injectable()
export class BussinessEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService
    ) { }

    getActiveBussiness$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getActiveBussiness),
            mergeMap(() =>
                this.contentService.getBusiness().pipe(
                    map((bussiness) => getActiveBussinessSuccess({ bussiness })),
                    catchError((error) => of(getActiveBussinessError({ error }))
                    )
                )
            )
        );
    });

}