<div class="stores-container">
  <a href="https://apps.apple.com/co/app/clickam/id1495823258" rel="noopener noreferrer" target="_blank"
    class="apple footer-store" (click)="storeGA4('AppleStore')">
    <span fxLayout="column">
      <img width="100" height="25" loading="lazy" src="assets/img/store/Applestore-v2.svg" alt="Icon-Store" />
    </span>
  </a>
  <a *ngIf="showLogo" href="https://play.google.com/store/apps/details?id=com.clickam.appcompania"
    rel="noopener noreferrer" target="_blank" class="footer-store" (click)="storeGA4('PlayStore')">
    <span fxLayout="column">
      <img width="100" height="25" loading="lazy" src="assets/img/store/GooglePlay-v2.svg" alt="Icon-Google" />
    </span></a>
  <a *ngIf="showLogo"
    href="https://appgallery.cloud.huawei.com/marketshare/app/C101678383?locale=es_US&source=appshare&subsource=C101678383"
    rel="noopener noreferrer" target="_blank" class="footer-store" (click)="storeGA4('HuaweiStore')">
    <span fxLayout="column">
      <img width="100" height="25" loading="lazy" src="assets/img/store/AppGallery-v2.svg" alt="Icon-Galery-Huawei" />
    </span></a>
</div>