import { Component, OnInit, HostListener, Input, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectMenu } from 'src/app/store/selectors/menu.selectors';
declare var dataLayer: any;
@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuOptionsComponent implements OnInit, OnDestroy {

  constructor(
    public auth: AuthService,
    private utils: UtilsService,
    private router: Router,
    private store: Store
  ) { }

  options = [];
  token = localStorage.getItem('ACCESS_TOKEN');
  authorization = this.token;
  @Input() colfooter;
  @Input() aligmentdesktop = 'center center';
  @Input() aligment;
  @Input() layoutxs = 'row';
  @Input() layoutmd = 'column';
  @Input() showIcon = false;
  @Input() icon: string;
  @Input() section = 'menuTop';
  @Output() hideSidenav = new EventEmitter();

  isOpenMenu: boolean;
  private subscription: Subscription = new Subscription();
  menuStore$: Subscription = new Subscription();

  ngOnInit() {
    this.getMenu();
  }

  /**
   * Metodo para obtener los menus
   */

  public getMenu() {
    this.menuStore$ = this.store.select(selectMenu).subscribe(menu => {
      this.options = menu;
    });
  }

  public hide() {
    this.hideSidenav.emit();
  }

  @HostListener('over')
  hideMenu() {
    this.utils.hideMenu();
  }

  /**
   * metodo para cerrar sesion
   */

  public logout() {
    this.utils.logout();
  }

  menuOptionsGA4(element){
    dataLayer.push({
      event: 'user_interaction',
      screen_name: 'Home',
      section: 'Menú',
      element,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.menuStore$.unsubscribe();
  }
}
