import { createAction, props } from '@ngrx/store';

export const getActiveBussiness = createAction('[BUSSINESS] Get active bussiness');

export const getActiveBussinessSuccess = createAction(
    '[BUSSINESS] Get active bussiness success',
    props<{ bussiness: any[] }>()
);

export const getActiveBussinessError = createAction(
    '[BUSSINESS] Get active bussiness error',
    props<{ error: any }>()
);