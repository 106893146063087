<div class="text-center">
    <app-title showTitleh3="true" text="¿Te gusta Referidos éxito?"></app-title>
    <app-image src="/assets/img/stars.svg" alt="stars"></app-image>
    <p>Tómate unos segundos para calificarnos, tu opinión es muy importante para seguir mejorando.</p>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <app-button id="later" title="Más tarde" [haveIcon]="false" (action)="onNoClick()" color="white" classButton="no-shadow"></app-button>
        <app-button
          id="savep"
          title="Calificar"
          [haveIcon]="false"
          (action)="qualify()"
          classButton="shadow-purple"
        ></app-button>
      </div>
</div>
