import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, retry, switchMap, take } from 'rxjs/operators';
import { ResponseService } from '../interfaces/response';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StoreService } from '../services/store.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);
  lastRequest: any;
  urlSecurity = environment.URL_SECURITY;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private storeService: StoreService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('ACCESS_TOKEN');

    return next.handle(this.addAuthToken(request)).pipe(
      catchError((requestError: HttpErrorResponse) => {
        if (requestError.status === 401) {
          if (token) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthToken(request)))
              );
            }
            else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              const calling = new Date();
              return this.refreshToken().pipe(
                switchMap((refreshResponse: ResponseService) => {
                  this.refreshTokenInProgress = false;
                  const oldTokens = {
                    accessTokenOld: localStorage.getItem('ACCESS_TOKEN'),
                    refreshTokenOld: localStorage.getItem('REFRESH_TOKEN'),
                    accessTokenNew: refreshResponse.objectResponse.token,
                    refreshTokenNew: refreshResponse.objectResponse.refreshToken,
                    response: refreshResponse,
                    initService: calling,
                    endService: new Date()
                  };
                  if (refreshResponse.state === 'Success') {
                    localStorage.setItem('ACCESS_TOKEN', refreshResponse.objectResponse.token);
                    localStorage.setItem('REFRESH_TOKEN', refreshResponse.objectResponse.refreshToken);
                    this.refreshTokenSubject.next(refreshResponse);
                    return next.handle(this.addAuthToken(request));
                  } else {
                    localStorage.clear();
                    localStorage.setItem('info', JSON.stringify(oldTokens));
                    this.router.navigateByUrl('/inicio');
                    this.storeService.logout();
                  }
                }),
                catchError((requestError: HttpErrorResponse): Observable<HttpEvent<any>> => {
                  return throwError(() => requestError);
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                })
              );
            }
          } else {
            localStorage.clear();
            localStorage.setItem('TokenError', JSON.stringify(requestError));
            this.router.navigateByUrl('/inicio');
            this.storeService.logout();
            return throwError(() => requestError);
          }
        } else if (requestError.status === 503) {
          return this.handleWithRetry(request, next, 3);
        } else {
          return throwError(() => requestError);
        }
      })
    );
  }

  redirectWithParams(route) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const navigationExtras: NavigationExtras = {
        queryParams: { ...params },
        queryParamsHandling: 'merge'
      };
      this.router.navigate([`${route}`], navigationExtras);
    });
  }

  addAuthToken(request: HttpRequest<any>) {
    const token: string = localStorage.getItem('ACCESS_TOKEN');
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private refreshToken() {
    const accesstoken = localStorage.getItem('ACCESS_TOKEN');
    const refreshtoken = localStorage.getItem('REFRESH_TOKEN');
    return this.httpClient.post(`${this.urlSecurity}token/refresh`, { AccessToken: accesstoken, refreshtoken });
  }

  private handleWithRetry(request: HttpRequest<any>, next: HttpHandler, maxRetryCount: number): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthToken(request)).pipe(
      retry(maxRetryCount)
    );
  }
}
