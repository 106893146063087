import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { getBlogs, getBlogsError, getBlogsSuccess, getPrincipalBlogs, getPrincipalBlogsError, getPrincipalBlogsSuccess, getTags, getTagsError, getTagsSuccess } from '../actions/blog.actions';
import { ResponseService } from 'src/app/interfaces/response';

const principalBlogs = [
    { id: 1, description: 'Primer articulo' },
    { id: 2, description: 'Segundo articulo' },
    { id: 3, description: 'Tercer articulo' },
    { id: 4, description: 'Cuarto articulo' },
]


@Injectable()
export class BlogsEffects {

    constructor(
        private actions$: Actions,
        private contentService: ContentService
    ) { }

    getPrincipalBlogs$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getPrincipalBlogs),
            mergeMap(() =>
                this.contentService.getPrincipalBlogs()
                    .pipe(
                        map((res: ResponseService) => getPrincipalBlogsSuccess({ principalBlogs: res.objectResponse.blogs })),
                        catchError((error) => of(getPrincipalBlogsError({ error })))
                    )
            )
        );
    });

    getBlogs$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getBlogs),
            mergeMap(({ params }) => {
                return this.contentService.getBlogs(params)
                    .pipe(
                        map((res: ResponseService) => getBlogsSuccess({ blogs: res.objectResponse })),
                        catchError((error) => of(getBlogsError({ error })))
                    )
            })
        );
    });

    getTags$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getTags),
            mergeMap(() =>
                this.contentService.getBlogTags()
                    .pipe(
                        map((res: ResponseService) => getTagsSuccess({ tags: res.objectResponse })),
                        catchError((error) => of(getTagsError({ error })))
                    )
            )
        );
    });

}