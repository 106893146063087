import { createAction, props } from '@ngrx/store';

export const getTerms = createAction('[TERMS] Get terms');

export const getTermsSuccess = createAction(
    '[TERMS] Get terms success',
    props<{ terms: any }>()
);

export const getTermsError = createAction(
    '[TERMS] Get terms error',
    props<{ error: any }>()
);