export class WindowClass {
  static redirect(url: string) {
    window.location.href = url.toString();
  }
  static open(url: string) {
    window.open(url.toString());
  }
  static locationReplace(url: string){
    window.location.replace(url);
  }

  static reload(){
    window.location.reload();
  }
}
