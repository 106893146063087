import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { getTerms, getTermsError, getTermsSuccess } from '../actions/terms-and-conditions.actions';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ResponseService } from 'src/app/interfaces/response';


@Injectable()
export class TermsAndConditionsEffects {

    constructor(
        private actions$: Actions,
        private masterDataService: MasterDataService
    ) { }

    getTerms$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getTerms),
            mergeMap(() => {
                return this.masterDataService.getTerms().pipe(
                    map((resp: ResponseService) => getTermsSuccess({ terms: resp.objectResponse })),
                    catchError((error) => of(getTermsError({ error }))
                    )
                )
            })
        );
    });

}