import { createAction, props } from '@ngrx/store';

export const getSellers = createAction('[SELLERS] Get sellers');

export const getSellersSuccess = createAction(
    '[SELLERS] Get sellers success',
    props<{ data: any }>()
);

export const getSellersError = createAction(
    '[SELLERS] Get sellers error',
    props<{ error: any }>()
);