import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { getDeparments } from 'src/app/store/actions/options-lists.actions';
import { getUserData, getUserProfile } from 'src/app/store/actions/user.actions';
import { selectDepartments } from 'src/app/store/selectors/options-lists.selectors';
import { selectUserProfile } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-home-information-modal',
  templateUrl: './home-information-modal.component.html',
  styleUrls: ['./home-information-modal.component.scss']
})
export class HomeInformationModalComponent implements OnInit, OnDestroy {
  userInfo: any;
  departments = [];
  cities: [];
  department: any = {};
  city: any = {};
  address: string;
  departmentsStore$: Subscription = new Subscription();
  userProfileStore$: Subscription = new Subscription();
  updateUser$: Subscription = new Subscription();
  filteredDepartments: any[];
  filteredCities: any[];

  homeInformationForm: FormGroup;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private store: Store,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.store.dispatch(getDeparments());
    this.initHomeInformationForm();
    this.filterDepartments();
    this.filterCities();
    this.getPersonalInfo();
  }

  initHomeInformationForm() {
    this.homeInformationForm = this.fb.group({
      department: [this.department, Validators.required],
      city: [this.city, Validators.required],
      address: [this.address, Validators.required]
    })
  }

  getPersonalInfo() {
    this.userProfileStore$ = this.store.select(selectUserProfile).subscribe(({ data, isLoading }) => {
      if (data && !isLoading) {
        this.userInfo = data;
        this.department = {
          code: data.department,
          description: data.departmentName
        };
        this.city = {
          code: data.municipality,
          description: data.municipalityName
        };
        this.address = data.address;
        this.initHomeInformationForm();
        this.getDepartments();
      }
    });
  }

  updateUser() {
    this.checkDepartment();
    this.checkCity();
    this.homeInformationForm.markAllAsTouched();
    if (this.homeInformationForm.valid) {
      const params = {
        cellphone: this.userInfo.cellphone,
        firstNames: this.userInfo.firstNames,
        department: this.homeInformationForm.controls.department.value.code,
        municipality: this.homeInformationForm.controls.city.value.code,
        address: this.homeInformationForm.controls.address.value,
      }
      this.updateUser$ = this.userService.updateUser(params).subscribe({
        next: (resp: any) => {
          if (resp.state === 'Success') {
            this.snackBar.open(resp.userMessage, 'Cerrar', { duration: 3000 });
            this.store.dispatch(getUserProfile());
            this.store.dispatch(getUserData());
            this.dialog.closeAll();
          }
        },
        error: (err) => {
          this.snackBar.open(err.userMessage, 'Cerrar', { duration: 3000 });
        }
      });
    }
  }

  getDepartments() {
    this.departmentsStore$ = this.store.select(selectDepartments).subscribe(({ data, error }) => {
      if (data) {
        this.departments = data;
        const auxDepartment = this.departments.find(dep => dep.code === this.department.code);
        this.cities = auxDepartment ? auxDepartment.municipalities : [];
        this.filterDepartments();
        this.filterCities();
      } else if (error) {
        this.departments = [];
        this.cities = [];
      }
    });
  }

  selectDepartment(department) {
    this.department = { code: department.code, description: department.description };
    this.cities = department.municipalities;
    this.homeInformationForm.controls.city.setValue('');
  }

  checkDepartment() {
    if (
      this.homeInformationForm.controls.department.value.code !== this.department.code ||
      !this.homeInformationForm.controls.department.value.code || !this.department.code
    ) {
      this.homeInformationForm.controls.department.setErrors({ incorrect: true });
    }
  }

  selectCity(city) {
    this.city = {
      code: city.code,
      description: city.description
    };
  }

  checkCity() {
    if (
      !this.homeInformationForm.controls.city.value.code || !this.city.code ||
      this.homeInformationForm.controls.city.value.code !== this.city.code
    ) {
      this.homeInformationForm.controls.city.setErrors({ incorrect: true });
    }
  }

  displayElement(item?: any): string | undefined {
    return item ? item.description : undefined;
  }

  filterDepartments() {
    this.homeInformationForm.controls.department.valueChanges.subscribe((res) => {
      if (res) {
        this.filteredDepartments = this.departments.filter((dep: any) => dep.description.toLocaleLowerCase().includes(res.toLocaleLowerCase()));
      } else {
        this.filteredDepartments = this.departments;
      }
    });
  }

  filterCities() {
    this.homeInformationForm.controls.city.valueChanges.subscribe((res) => {
      if (res) {
        this.filteredCities = this.cities.filter((city: any) => city.description.toLocaleLowerCase().includes(res.toLocaleLowerCase()));
      } else {
        this.filteredCities = this.cities;
      }
    });
  }

  ngOnDestroy(): void {
    this.departmentsStore$.unsubscribe();
    this.userProfileStore$.unsubscribe();
    this.updateUser$.unsubscribe();
  }

}
