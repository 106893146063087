<app-top title="Correo de Activación" (close)="hideActivate()"></app-top>

<p class="text-center activate-account__title">{{ 'ACTIVATE.TEXT' | translate }}</p>

<form class="forgotpassword-container" [formGroup]="activateForm">
  <mat-form-field>
    <input matInput id="email" type="email" placeholder="{{ 'GENERIC.EMAIL' | translate }}" formControlName="email"
      spacebar required />
    <mat-error id="introduceEmail"
      *ngIf="activateForm.controls.email.invalid && activateForm.controls.email.errors.required">{{
      'LOGIN.INTRODUCE_EMAIL' | translate
      }}</mat-error>
    <mat-error id="invalidEmail"
      *ngIf="activateForm.controls.email.invalid && activateForm.controls.email.errors.pattern">{{
      'LOGIN.INVALID_EMAIL' | translate
      }}</mat-error>

    <mat-error id="maxemail"
      *ngIf="activateForm.controls.email.invalid && activateForm.controls.email.errors.maxlength">{{
      'GENERIC.MAX_CHARACTERS'
      | translate
      : {
      maxchar: activateForm.controls.email.errors.maxlength.requiredLength
      }
      }}</mat-error>
  </mat-form-field>

  <button class="button-base activate-button" id="recoverPassword" (click)="activateAccount()"
    [disabled]="!activateForm.valid">
    <span>{{ 'GENERIC.SEND' | translate }}</span>
  </button>
</form>