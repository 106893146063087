import { createSelector } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { MenuState } from "../reducers/menu.reducers";


export const selectMenuNode = (state: AppState) =>
    state.menu;

export const selectMenu = createSelector(
    selectMenuNode,
    (state: MenuState) => state.menu
)