import { createAction, props } from '@ngrx/store';

export const restoreReport = createAction('[REPORT] Restore report');

export const getPayments = createAction(
    '[REPORT] Get payments',
    props<{ params: any }>()
);

export const getPaymentsSuccess = createAction(
    '[REPORT] Get payments success',
    props<{ payments: any }>()
);

export const getPaymentsError = createAction(
    '[REPORT] Get payments error',
    props<{ error: any }>()
);

export const setCurrentPayment = createAction(
    '[REPORT] Set current payment',
    props<{ payment: any }>()
);

export const deleteCurrentPayment = createAction('[REPORT] Delete current payment');

export const getPaymentDetail = createAction(
    '[REPORT] Get payment detail',
    props<{ params: any }>()
);

export const getPaymentDetailSuccess = createAction(
    '[REPORT] Get payment detail success',
    props<{ payment: any }>()
);

export const getPaymentDetailError = createAction(
    '[REPORT] Get payment detail error',
    props<{ error: any }>()
);

export const getRewardsSummary = createAction(
    '[REPORT] Get rewards summary',
    props<{ userId: any }>()
);

export const getRewardsSummarySuccess = createAction(
    '[REPORT] Get rewards summary success',
    props<{ data: any }>()
);

export const getRewardsSummaryError = createAction(
    '[REPORT] Get rewards summary error',
    props<{ error: any }>()
);

export const getRewardsGraph = createAction(
    '[REPORT] Get rewards graph',
    props<{ params: any }>()
);

export const getRewardsGraphSuccess = createAction(
    '[REPORT] Get rewards graph success',
    props<{ data: any }>()
);

export const getRewardsGraphError = createAction(
    '[REPORT] Get rewards graph error',
    props<{ error: any }>()
);

export const getRewardsHistory = createAction(
    '[REPORT] Get rewards history',
    props<{ params: any }>()
);

export const getRewardsHistorySuccess = createAction(
    '[REPORT] Get rewards history success',
    props<{ data: any }>()
);

export const getRewardsHistoryError = createAction(
    '[REPORT] Get rewards history error',
    props<{ error: any }>()
);