import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { ResponseService } from 'src/app/interfaces/response';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-onboarding-swiper',
  templateUrl: './onboarding-swiper.component.html',
  styleUrls: ['./onboarding-swiper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnboardingSwiperComponent implements OnInit, OnDestroy {

  private breakpoint$: Subscription = new Subscription();
  isMobile: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<OnboardingSwiperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakPointService: BreakpointService,
  ) { }
  @ViewChild('slickModal', { static: false })
  slickModal: SlickCarouselComponent;
  firstSlide: boolean = true;
  lastSlide: boolean = false;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    arrows: false,
    autoplay: false,
    swipeToSlide: true,
    variableWidth: false,
    adaptiveHeight: true,
    lazyLoad: 'ondemand'
  };

  slides = [];

  $onBoardingSubscription: Subscription = new Subscription();

  ngOnInit() {
    this.initSlides();
    this.breakpoint();
  }

  afterChange(event) {
    this.firstSlide = event.first;
    this.lastSlide = event.last;
  }

  breakpoint() {
    this.breakpoint$ = this.breakPointService
      .isWidthLessThanBreakpoint('800')
      .subscribe((res: boolean) => {
        this.isMobile = res;
      });
  }

  initSlides() {
    this.slides = this.data.slides || [];
    if (this.slides.length > 1) {
      this.slideConfig = {
        ...this.slideConfig,
        dots: true
      }
    }
  }

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.$onBoardingSubscription.unsubscribe();
    this.breakpoint$.unsubscribe();
  }
}
