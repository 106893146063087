<section class="sectionPopUp">
  <mat-icon class="close-icon cursor-pointer" id="closeDialog" (click)="closeMatDialog()">close</mat-icon>
  <div class="carousel-container">
    <ngx-slick-carousel
       #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slick($event)"
      (beforeChange)="slick($event,true)"
      (afterChange)="afterChange($event)"
    >
      <div ngxSlickItem *ngFor="let story of data; let i = index" [attr.data-key]="story.id">
        <div class="slick-image-container">
          <!-- Imagen Web -->
          <img *ngIf="!isMobile" loading="lazy" class="slick-image" [src]="story.imageurlweb" [alt]="story.textbutton" />
          <!-- Imagen Mobile -->
          <img *ngIf="isMobile" loading="lazy" class="slick-image" [src]="story.imageurlmobile" [alt]="story.textbutton" />
        </div>
        <div class="buttons-container">
          <a
            *ngIf="story.textbutton && story.link"
            class="buttonPopUp"
            (click)="detectLink(story)"
            >{{ story.textbutton }}</a
          >
          <button *ngIf="story.textbutton && !story.link" class="buttonPopUp" (click)="closeMatDialog()">
            {{ story.textbutton }}
          </button>
        </div>
      </div>
    </ngx-slick-carousel>
    <div *ngIf="showArrowButtons">
      <button class="btn-arrow btn-prev" (click)="prev()">
        <i class="tio-back_ui"></i>
      </button>
      <button class="btn-arrow btn-next" (click)="next()">
        <i class="tio-next_ui"></i>
      </button>
    </div>
  </div>
</section>
