<app-top title="Registro" (close)="hideRegister()"></app-top>

<h3 class="register-title">
  {{ isASocialNetworkRegister ? ('REGISTER.FILL_YOUR_DATA' | translate) :
  ('REGISTER.REGISTER_ACCOUNT' | translate) }}
</h3>
<p class="register__subtitle">
  Es fácil y rápido
</p>
<ng-container *ngIf="!isASocialNetworkRegister">
  <app-social-networks-login-buttons [isLogin]="false" (user)="showUser($event)"></app-social-networks-login-buttons>
  <p class="register-description-text">O crea una cuenta</p>
</ng-container>

<form class="register-container" [formGroup]="registerForm">

  <mat-form-field>
    <mat-select (selectionChange)="selectId($event.value)" placeholder="{{ 'REGISTER.DOCUMENT_TYPE' | translate }}"
      id="idtype" formControlName="idType" required>
      <mat-option disabled>{{ 'REGISTER.ID_TYPE' | translate }}</mat-option>
      <mat-option *ngFor="let typeId of idUserType" value="{{ typeId.id }}">{{ typeId.value }}</mat-option>
    </mat-select>
    <mat-error id="requiredTypeId" *ngIf="registerForm.controls.idType.invalid">{{
      'GENERIC.REQUIRED' | translate: { formValue: '' }
      }}</mat-error>
  </mat-form-field>

  <ng-container *ngIf="isASocialNetworkRegister || registerForm.controls.idType.value">

    <mat-form-field>
      <input matInput id="id" type="text" placeholder="{{ 'GENERIC.DOCUMENT_NUMBER' | translate : {dc: typedc } }}"
        formControlName="id" spacebar required />
      <mat-error id="reuiredId" *ngIf="registerForm.controls.id.invalid && registerForm.controls.id.errors.required">{{
        'GENERIC.REQUIRED' | translate: { formValue: 'Número de documento' }
        }}</mat-error>

      <mat-error id="maxId" *ngIf="registerForm.controls.id.invalid && registerForm.controls.id.errors.maxlength">{{
        'GENERIC.MAX_CHARACTERS'
        | translate
        : {
        maxchar: registerForm.controls.id.errors.maxlength.requiredLength
        }
        }}</mat-error>

      <mat-error id="numberId" *ngIf="registerForm.controls.id.invalid && registerForm.controls.id.errors.pattern">{{
        'GENERIC.ONLY_NUMBERS' | translate
        }}</mat-error>
    </mat-form-field>

    <span *ngIf="showBusiness" class="small">(*Con digito de verificación)</span>


    <ng-container *ngIf="showPerson && !isASocialNetworkRegister">
      <mat-form-field>
        <input matInput id="name" type="text" placeholder="{{ 'GENERIC.NAME' | translate }}" formControlName="name"
          required />
        <mat-error id="requiredName"
          *ngIf="registerForm.controls.name.invalid && registerForm.controls.name.errors.required">{{
          'GENERIC.REQUIRED' | translate: { formValue: 'Nombre' }
          }}</mat-error>
        <mat-error id="maxName"
          *ngIf="registerForm.controls.name.invalid && registerForm.controls.name.errors.maxlength">{{
          'GENERIC.MAX_CHARACTERS'
          | translate
          : {
          maxchar: registerForm.controls.name.errors.maxlength.requiredLength
          }
          }}</mat-error>
        <mat-error id="invalidName"
          *ngIf="registerForm.controls.name.invalid && registerForm.controls.name.errors.pattern">{{
          'GENERIC.INVALID_FORMAT' | translate: { val: 'Nombre' }
          }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput id="lastname" type="text" placeholder="{{ 'GENERIC.LASTNAME' | translate }}"
          formControlName="lastName" required />
        <mat-error id="requiredLastName"
          *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.errors.required">{{
          'GENERIC.REQUIRED' | translate: { formValue: 'Apellidos' }
          }}</mat-error>

        <mat-error id="maxLastName"
          *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.errors.maxlength">{{
          'GENERIC.MAX_CHARACTERS'
          | translate
          : {
          maxchar: registerForm.controls.lastName.errors.maxlength.requiredLength
          }
          }}</mat-error>

        <mat-error id="invalidLastName"
          *ngIf="registerForm.controls.lastName.invalid && registerForm.controls.lastName.errors.pattern">{{
          'GENERIC.INVALID_FORMAT' | translate: { val: 'Apellidos' }
          }}</mat-error>
      </mat-form-field>

    </ng-container>

    <ng-container *ngIf="showBusiness">
      <mat-form-field>
        <input matInput id="social" type="text" placeholder="{{ 'GENERIC.SOCIAL' | translate }}"
          formControlName="social" required />
        <mat-error id="requiredsocial"
          *ngIf="registerForm.controls.social.invalid && registerForm.controls.social.errors.required">{{
          'GENERIC.REQUIRED' | translate: { formValue: 'Razón Social' }
          }}</mat-error>

        <mat-error id="maxsocial"
          *ngIf="registerForm.controls.social.invalid && registerForm.controls.social.errors.maxlength">{{
          'GENERIC.MAX_CHARACTERS'
          | translate
          : {
          maxchar: registerForm.controls.social.errors.maxlength.requiredLength
          }
          }}</mat-error>

        <mat-error id="invalidsocial"
          *ngIf="registerForm.controls.social.invalid && registerForm.controls.social.errors.pattern">{{
          'GENERIC.INVALID_FORMAT' | translate: { val: 'Razón Social' }
          }}</mat-error>
      </mat-form-field>
    </ng-container>

    <mat-form-field>
      <input matInput id="phone" type="text" placeholder="{{ 'GENERIC.CELLPHONE' | translate }}" formControlName="phone"
        spacebar required />
      <mat-error id="requiredPhone"
        *ngIf="registerForm.controls.phone.invalid && registerForm.controls.phone.errors.required">{{
        'GENERIC.REQUIRED' | translate: { formValue: 'Celular' }
        }}</mat-error>

      <mat-error id="minPhone"
        *ngIf="registerForm.controls.phone.invalid && registerForm.controls.phone.errors.minlength">{{
        'GENERIC.MIN_CHARACTERS'
        | translate
        : {
        minchar: registerForm.controls.phone.errors.minlength.requiredLength
        }
        }}</mat-error>

      <mat-error id="maxPhone"
        *ngIf="registerForm.controls.phone.invalid && registerForm.controls.phone.errors.maxlength">{{
        'GENERIC.MAX_CHARACTERS'
        | translate
        : {
        maxchar: registerForm.controls.phone.errors.maxlength.requiredLength
        }
        }}</mat-error>

      <mat-error id="numberPhone"
        *ngIf="registerForm.controls.phone.invalid && registerForm.controls.phone.errors.pattern">{{
        'GENERIC.ONLY_NUMBERS' | translate
        }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="!isASocialNetworkRegister">
      <mat-form-field>
        <input matInput id="email" type="email" placeholder="{{ 'GENERIC.EMAIL' | translate }}" formControlName="email"
          spacebar required />
        <mat-error id="invalidEmail" *ngIf="registerForm.controls.email.invalid">{{
          'LOGIN.INVALID_EMAIL' | translate: { formValue: 'Email' }
          }}</mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%">
        <mat-label>{{ 'GENERIC.PASSWORD' | translate }}</mat-label>
        <mat-pass-toggle-visibility #toggleVisbility matSuffix></mat-pass-toggle-visibility>
        <input matInput #passwordWithValidation [type]="toggleVisbility.type" required formControlName="password"
          placeholder="{{ 'GENERIC.PASSWORD' | translate }}" />
        <!-- <mat-hint align="end" aria-live="polite"> {{ passwordWithValidation.value.length }} / 20 </mat-hint> -->
        <mat-error id="requiredPassword"
          *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.errors.required">{{
          'GENERIC.REQUIRED' | translate: { formValue: 'Contraseña' }
          }}</mat-error>

        <mat-error id="minPassword"
          *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.errors.minlength">{{
          'GENERIC.MIN_CHARACTERS'
          | translate
          : {
          minchar: registerForm.controls.password.errors.minlength.requiredLength
          }
          }}</mat-error>

        <mat-error id="maxPassword"
          *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.errors.maxlength">{{
          'GENERIC.MAX_CHARACTERS'
          | translate
          : {
          maxchar: registerForm.controls.password.errors.maxlength.requiredLength
          }
          }}</mat-error>

        <mat-error id="patternPassword"
          *ngIf="registerForm.controls.password.invalid && registerForm.controls.password.errors.pattern">{{
          'GENERIC.PASSWORD_PATTERN' | translate
          }}</mat-error>
      </mat-form-field>
      <mat-password-strength class="mb-2" #passwordComponentWithValidation
        [password]="registerForm.controls.password.value"
        (onStrengthChanged)="onStrengthChanged($event)"></mat-password-strength>
      <p class="password-suggestion">
        - La contraseña debe incluir letras y números, mínimo 6 caracteres y máximo 20.
      </p>

      <mat-form-field>
        <mat-pass-toggle-visibility #toggleVisbility2 matSuffix></mat-pass-toggle-visibility>
        <input matInput id="confirmPassword" [type]="toggleVisbility2.type"
          placeholder="{{ 'GENERIC.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" required
          spacebar />
        <mat-error id="notmatchPassword"
          *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">{{
          'REGISTER.PASSWORD_NOT_MATCH' | translate }}</mat-error>

        <mat-error id="requiredConfirmPassword"
          *ngIf="registerForm.controls.confirmPassword.invalid && registerForm.controls.confirmPassword.errors.required">{{
          'GENERIC.REQUIRED' | translate: { formValue: 'Contraseña' } }}</mat-error>

        <mat-error id="minConfirmPassword"
          *ngIf="registerForm.controls.confirmPassword.invalid && registerForm.controls.confirmPassword.errors.minlength">{{
          'GENERIC.MIN_CHARACTERS'
          | translate
          : {
          minchar: registerForm.controls.confirmPassword.errors.minlength.requiredLength
          }
          }}</mat-error>

        <mat-error id="maxConfirmPassword"
          *ngIf="registerForm.controls.confirmPassword.invalid && registerForm.controls.confirmPassword.errors.maxlength">{{
          'GENERIC.MAX_CHARACTERS'
          | translate
          : {
          maxchar: registerForm.controls.confirmPassword.errors.maxlength.requiredLength
          }
          }}</mat-error>
      </mat-form-field>
    </ng-container>

    <span class="terms-conditions mt-3">
      <mat-checkbox formControlName="acceptTerms" id="acceptTerms"
        (change)="acceptTermsCheck($event,'termsAndConditions')">
      </mat-checkbox>
      <p class="accept-terms cursor-pointer" (click)="termsAndConditions('termsAndConditions')">
        Acepto los términos y condiciones*
      </p>
    </span>
    <p class="terms-conditions--error" *ngIf="submitted && registerForm.controls.acceptTerms.invalid">
      Este campo es obligatorio
    </p>

    <span class="terms-conditions mt-3">
      <mat-checkbox formControlName="acceptAdvertising" id="acceptAdvertising">
      </mat-checkbox>
      <p class="accept-terms cursor-pointer" (click)="termsAndConditions('advertising')">
        Autorizo el tratamiento de mis datos para el envío de publicidad
      </p>
    </span>

    <span class="terms-conditions mt-3">
      <mat-checkbox formControlName="acceptHabeasData" id="acceptHabeasData"
        (change)="acceptTermsCheck($event,'habeasData')">
      </mat-checkbox>
      <p class="accept-terms cursor-pointer" (click)="termsAndConditions('habeasData')">
        Autorizo el tratamiento de mis datos personales*
      </p>
    </span>
    <p class="terms-conditions--error mb-4" *ngIf="submitted && registerForm.controls.acceptHabeasData.invalid">
      Este campo es obligatorio
    </p>

    <button id="register" class="mt-2 mb-5 button-base register-button gtmRegistraTuCuentaClicContinuarDatosPrincipales"
      (click)="handleRegister();registerGA4('Registrarse')">
      <span class="gtmRegistraTuCuentaClicContinuarDatosPrincipales">Registrarse</span>
    </button>
  </ng-container>
</form>