import { createReducer, on } from "@ngrx/store";
import { setOnboardingDisplay, setOnboardingStep } from "../actions/onboarding.actions";

export interface OnboardingState {
    show: boolean;
    step: number;
}

export const initialOnboardingState: OnboardingState = {
    show: false,
    step: 0
}

export const onboardingReducer = createReducer(
    initialOnboardingState,
    on(setOnboardingDisplay, (state, { show }) => ({
        ...state,
        show
    })),
    on(setOnboardingStep, (state, { step }) => ({
        ...state,
        step
    })),

)