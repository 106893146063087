import { Component, OnInit, OnDestroy, HostListener, ViewChild, TemplateRef, Inject, PLATFORM_ID } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from 'src/app/validators/confirm-password.validator';
import Swal from 'sweetalert2';
import { ResponseService } from 'src/app/interfaces/response';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfirmEmailValidator } from 'src/app/validators/confirm-email.validator';
import { UserService } from 'src/app/services/user.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/modules/shared/components/modal-generic/modal-generic.component';
import { ContentService } from 'src/app/services/content.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { LinksService } from 'src/app/services/links.service';
import { TermsAndConditionsModalComponent } from 'src/app/modules/shared/components/terms-and-conditions-modal/terms-and-conditions-modal.component';
declare var dataLayer: any;

@Component({
  selector: 'app-registerform',
  templateUrl: './registerform.component.html',
  styleUrls: ['./registerform.component.scss'],
})
export class RegisterformComponent implements OnInit, OnDestroy {
  submitted: boolean = false;
  isASocialNetworkRegister: boolean = false;
  socialFormIdTypeControl: FormControl;
  socialFormIdControl: FormControl;
  socialFormNameControl: FormControl;
  socialNetworkUser: any;
  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private registerUser: UserService,
    private router: Router,
    private utils: UtilsService,
    private dialog: MatDialog,
    private content: ContentService,
    private link: LinksService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  private subscription: Subscription = new Subscription();
  private registerFromSocialNetwork$: Subscription = new Subscription();
  registerForm: UntypedFormGroup;
  showTerms: boolean;
  showRegisterForm: boolean;
  showLoginForm: boolean;
  acceptTerms: boolean = null;
  idUserType = [];

  emailPattern = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
  namePattern = '[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+';
  socialPattern = '[a-zA-Z0-9 .-_àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+';
  numberPattern = '^(0|[0-9][0-9]*)$';
  passwordPattern = '(?=.*[a-zA-Z])(?=.*[0-9])';
  classMsg: string;
  amount: any;
  amountReferred: any;


  showPerson = false;
  showBusiness = false;
  typedc = 'documento';

  ngOnInit() {
    this.amount = localStorage.getItem('Amount');
    this.amountReferred = localStorage.getItem('AmonuntReferred');

    this.registerForm = this.fb.group(
      {
        name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.namePattern)]],
        lastName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.namePattern)]],
        social: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.socialPattern)]],
        idType: ['', Validators.required],
        id: ['', [Validators.required, Validators.maxLength(11), Validators.pattern(this.numberPattern)]],
        phone: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.numberPattern)]],
        email: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(64)]],
        password: [
          '',
          [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(new RegExp(this.passwordPattern))],
        ],
        confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        acceptTerms: [null, Validators.required],
        acceptAdvertising: [false],
        acceptHabeasData: [null, Validators.required],
      },
      {
        validator: [ConfirmPasswordValidator.MatchPassword],
      }
    );
    // this.showRegisterForm = true;

    this.getidType();
  }

  showUser(user) {
    this.showRegisterForm = false;
    this.isASocialNetworkRegister = true;
    this.socialNetworkUser = user;
    this.registerForm.controls.name.clearValidators();
    this.registerForm.controls.lastName.clearValidators();
    this.registerForm.controls.email.clearValidators();
    this.registerForm.controls.password.clearValidators();
    this.registerForm.controls.confirmPassword.clearValidators();
    this.registerForm.controls.name.setErrors(null);
    this.registerForm.controls.lastName.setErrors(null);
    this.registerForm.controls.email.setErrors(null);
    this.registerForm.controls.password.setErrors(null);
    this.registerForm.controls.confirmPassword.setErrors(null);
    this.registerForm.controls.confirmPassword.updateValueAndValidity();
  }

  public termsAndConditions(category) {
    this.dialog.open(TermsAndConditionsModalComponent,
      {
        data: {
          typeDocument: this.typedc,
          category,
          canClose: true
        },
        minWidth: '600px',
        disableClose: false,
        panelClass: 'terms-and-conditions-modal'
      }).afterClosed().subscribe(accept => this.afterClosedTerms(category, accept));
  }

  afterClosedTerms(category, accept) {
    switch (category) {
      case 'termsAndConditions':
        if (!this.registerForm.controls.acceptTerms.value) {
          this.registerForm.controls.acceptTerms.setValue(accept);
        }
        break;
      case 'advertising':
        this.registerForm.controls.acceptAdvertising.setValue(accept);
        break;
      case 'habeasData':
        if (!this.registerForm.controls.acceptHabeasData.value) {
          this.registerForm.controls.acceptHabeasData.setValue(accept);
        }
        break;
    }
  }

  public acceptModal() {
    this.dialog.closeAll();
    this.acceptTerms = true;
    this.registerForm.controls.acceptTerms.setValue(true);
  }

  @HostListener('over')
  hideRegister() {
    this.utils.hideRegisterForm();
  }

  handleRegister() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.isASocialNetworkRegister ? this.registerFromSocialNetwork() : this.register();
    }
  }

  public register() {
    const registerForm = {
      Email: this.registerForm.controls.email.value.toLowerCase(),
      FirstNames: this.registerForm.controls.name.value,
      LastNames: this.registerForm.controls.lastName.value,
      social: this.registerForm.controls.social.value,
      Identification: this.registerForm.controls.id.value,
      Cellphone: this.registerForm.controls.phone.value,
      Password: btoa(this.registerForm.controls.password.value),
      idReferrer: localStorage.getItem('idClicker'),
      idcampaign: parseInt(localStorage.getItem('campaign')),
      IdType: this.registerForm.controls.idType.value,
      acceptTerms: true,
      acceptHabeasData: true,
      acceptnotifications: this.registerForm.controls.acceptAdvertising.value,
    };

    this.subscription = this.registerUser.registerUser(registerForm).subscribe(
      (resp: ResponseService) => {
        if (resp.state === 'Success') {
          if (isPlatformBrowser(this.platformId)) {
            dataLayer.push({
              event: 'pushEventGA',
              categoria: 'Registro',
              accion: 'ClicContinuar',
              etiqueta: 'RegistroExitoso',
            });
          }

          Swal.fire({
            title: 'Revisa tu correo',
            type: 'info',
            html: `
            <div class="text-center">
            <h3 class="gray f-16">Recuerda ir a la bandeja de entrada de tu correo para activar tu cuenta.</h3>
              <p class="f-11">*Revisa también tu bandeja de correo no deseado</p>
              </div>`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Volver al inicio',
            confirmButtonClass: 'button-base button-base__cancel gtmRegistroClicModalValidacion',
          }).then(() => {
            this.utils.hideloginForm();
          });
        } else {
          Swal.fire({
            title: 'Registro inválido',
            text: resp.userMessage,
            type: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonClass: 'button-base button-base__cancel',
          });
        }
      },
      (error) => { this.handleError(error, 'accept-register-alert-invalid') }
    );
  }
  handleError(error, confirmButtonClass) {
    Swal.fire({
      title: error.statusText,
      text: error.error.userMessage,
      type: 'error',
      confirmButtonText: 'Aceptar',
      confirmButtonClass: 'button-base button-base__cancel',
    });
  }

  registerFromSocialNetwork() {
    const registerForm = {
      email: this.socialNetworkUser.email.toLowerCase(),
      firstNames: this.socialNetworkUser.firstName,
      lastNames: this.socialNetworkUser.lastName,
      social: this.registerForm.controls.social.value,
      identification: this.registerForm.controls.id.value,
      cellphone: this.registerForm.controls.phone.value,
      idType: this.registerForm.controls.idType.value,
      acceptHabeasData: this.registerForm.controls.acceptHabeasData.value,
      acceptTerms: this.registerForm.controls.acceptTerms.value,
      acceptnotifications: this.registerForm.controls.acceptAdvertising.value,
      socialmedia: this.socialNetworkUser.origin || '',
      token: this.socialNetworkUser.token
    };
    this.registerFromSocialNetwork$ = this.registerUser.registerUser(registerForm).subscribe({
      next: (res: any) => {
        if (res.state === 'Error') {
          Swal.fire({
            title: 'Registro inválido',
            text: res.userMessage,
            type: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonClass: 'button-base button-base__cancel',
          });
        } else if (res.state === 'Success') {
          this.login({
            token: this.socialNetworkUser.token,
            username: this.socialNetworkUser.email.toLowerCase()
          });
        }
      },
      error: (error) => { this.handleError(error, 'accept-register-alert-invalid') }
    });
  }

  login(data: any) {
    this.subscription = this.authService.login(data).subscribe({
      next: (resp: ResponseService) => {
        if (resp.state === 'Success') {
          setTimeout(() => {
            this.link.getAmount();
          }, 500);
          localStorage.setItem('ACCESS_TOKEN', resp.objectResponse.token);
          localStorage.setItem('REFRESH_TOKEN', resp.objectResponse.refreshToken);
          this.utils.hideloginForm();
          this.authService.routeBased();
          if (isPlatformBrowser(this.platformId)) {
            dataLayer.push({
              event: 'pushEventGA',
              categoria: 'IniciarSesion',
              accion: 'ClicLightboxIniciar',
              etiqueta: 'IniciarSesionExitoso',
            });

            dataLayer.push({
              event: 'pushEventGA',
              categoria: 'Inicio',
              accion: 'ClicLateral',
              etiqueta: this.socialNetworkUser.email.toLowerCase(),
            });
          }
        } else {
          Swal.fire({
            title: 'Login inválido',
            text: resp.userMessage,
            type: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonClass: 'button-base button-base__cancel',
          });
        }
      },
      error: (error) => { this.handleError(error, 'accept-forgot-alert-invalid') }
    });
  }

  /**
   * check para aceptar terminos y condiciones
   */

  public acceptTermsCheck({ checked }, controlName: string) {
    if (!checked) {
      switch (controlName) {
        case 'termsAndConditions':
          this.registerForm.controls.acceptTerms.setValue(null);
          break;
        case 'habeasData':
          this.registerForm.controls.acceptHabeasData.setValue(null);
          break;
      }
    }
  }

  /**
   * Metodo para listar el tipo de identificacion del usuario
   */

  public getidType() {
    this.subscription = this.registerUser.idType().subscribe((res: ResponseService) => {
      this.idUserType = res.objectResponse;
    });
  }

  public selectId(typeId: string) {
    if (typeId === '3') {
      this.showBusiness = true;
      this.showPerson = false;
      this.registerForm.controls.name.clearValidators();
      this.registerForm.controls.lastName.clearValidators();
      this.registerForm.controls.name.setValue(null);
      this.registerForm.controls.lastName.setValue(null);
      this.typedc = 'nit';
    } else {
      this.showBusiness = false;
      this.showPerson = true;
      this.registerForm.controls.social.clearValidators();
      this.registerForm.controls.social.setValue(null);
      this.typedc = 'documento';
    }
  }

  /**
   * Metodo para validar la fuerza de la contraseña
   * @param event evento
   */

  onStrengthChanged(event) {
    if (event <= 20) {
      this.classMsg = 'weak';
    }
    if (event > 20 && event < 100) {
      this.classMsg = 'normal';
    }
    if (event >= 100) {
      this.classMsg = 'acceptable';
    }
  }

  registerGA4(element) {
    dataLayer.push({
      event: 'sign_up',
      element,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
