
<h1 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh1">
    {{text}}
</h1>

<h2 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh2">
    {{text}}
</h2>

<h3 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh3">
    {{text}}
</h3>

<h4 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh4">
    {{text}}
</h4>

<h5 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh5">
    {{text}}
</h5>

<h6 [ngClass]="commonClass" [ngClass.gt-xs]="classWeb" [ngClass.xs]="classMobile" *ngIf="showTitleh6">
    {{text}}
</h6>