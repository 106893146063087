import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PopupComponent } from './components/popup/popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './components/header/header.component';
import { SociallinksComponent } from './components/sociallinks/sociallinks.component';
import { TopComponent } from './components/top/top.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { RouterModule } from '@angular/router';
import { MenuOptionsComponent } from './components/menu-options/menu-options.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { AppStoreComponent } from './components/app-store/app-store.component';
import { LogoComponent } from './components/logo/logo.component';
import { DialogStoriesComponent } from './components/dialog-stories/dialog-stories.component';
import { ProductBussinessComponent } from './components/product-bussiness/product-bussiness.component';
import { BackNavigationComponent } from './components/back-navigation/back-navigation.component';
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { TrimPipe } from 'src/app/pipes/trim.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RemoveTagsPipe } from 'src/app/pipes/remove-tags.pipe';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { CardStoryComponent } from './components/card-story/card-story.component';
import { OnboardingSwiperComponent } from './components/onboarding-swiper/onboarding-swiper.component';
import { SafePipe } from 'src/app/pipes/safe,pipe';
import { BackButtonComponent } from './components/atoms/back-button/back-button.component';
import { DateRangeComponent } from './components/atoms/date-range/date-range.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ButtonComponent } from './components/atoms/button/button.component';
import { DateRangeButtonComponent } from './components/molecules/date-range-button/date-range-button.component';
import { CardDataComponent } from './components/card-data/card-data.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CardDataTotalComponent } from './components/card-data-total/card-data-total.component';
import { TableDetailComissionComponent } from './components/table-detail-comission/table-detail-comission.component';
import { ImageComponent } from './components/atoms/image/image.component';
import { SliderShareComponent } from './components/atoms/slider-share/slider-share.component';
import { ImageTextComponent } from './components/molecules/image-text/image-text.component';
import { TitleComponent } from './components/atoms/title/title.component';
import { CardComponent } from './components/atoms/card/card.component';
import { BussinessCardComponent } from '../anonymous/components/bussiness-card/bussiness-card.component';
import { ButtonStoryComponent } from '../anonymous/components/button-story/button-story.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ReviewClickamComponent } from './components/review-clickam/review-clickam.component';
import { WidgetBarComponent } from './components/widget-bar/widget-bar.component';
import { WelcomeComponent } from '../anonymous/components/welcome/welcome.component';
import { NewBusinessFormComponent } from '../anonymous/components/new-business-form/new-business-form.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SavingComponent } from './components/saving/saving.component';
import { ReferWinComponent } from './components/refer-win/refer-win.component';
import { QrComponent } from './components/qr/qr.component';
import { BannerPrincipalComponent } from './components/banner-principal/banner-principal.component';
import { ModalReferirComprarComponent } from './components/modal-referir-comprar/modal-referir-comprar.component';
import { SocialNetworksLoginButtonsComponent } from './components/social-networks-login-buttons/social-networks-login-buttons.component';
import { TermsAndConditionsModalComponent } from './components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { ReplaceRewardsPipe } from 'src/app/pipes/replace-rewards.pipe';
import { SuperbarraComponent } from './components/superbarra/superbarra.component';
import { OfferCardComponent } from './components/offer-card/offer-card.component';
import { OfferCarruselComponent } from './components/offer-carrusel/offer-carrusel.component';
import { SwiperModule } from "swiper/angular";
import { NoimagePipe } from 'src/app/pipes/noimage.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { EmptyModalComponent } from './components/empty-modal/empty-modal.component';
import { CarouselsGroupComponent } from './components/carousels-group/carousels-group.component';
import { TitleWithTimeRangeComponent } from './components/title-with-time-range/title-with-time-range.component';
import { InputFilePreviewComponent } from './components/input-file-preview/input-file-preview.component';
import { EnableTooltipOnDisabledDirective } from './directives/enableTooltip';
import { NotificationBadgeComponent } from './components/notification-badge/notification-badge.component';
import { DidnotFindWhatsAppContactComponent } from './components/didnot-find-whatspp-contact/didnot-find-whatsapp-contact.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { StoryShareModalComponent } from './components/story-share-modal/story-share-modal.component';
import { BusinessListComponent } from './components/business-list/business-list.component';
import { InitInteractiveOnboardingComponent } from './components/init-interactive-onboarding/init-interactive-onboarding.component';
import { BannerPrincipalLogoutComponent } from './components/banner-principal-logout/banner-principal-logout.component';
import { BusinessLogoutComponent } from './components/business-logout/business-logout.component';

@NgModule({
  declarations: [
    SearchComponent,
    NotFoundComponent,
    PopupComponent,
    FooterComponent,
    HeaderComponent,
    SociallinksComponent,
    TopComponent,
    MenuOptionsComponent,
    UnderConstructionComponent,
    TruncatePipe,
    TrimPipe,
    RemoveTagsPipe,
    SafePipe,
    AppStoreComponent,
    LogoComponent,
    ProductBussinessComponent,
    BackNavigationComponent,
    ModalGenericComponent,
    PageTitleComponent,
    DialogStoriesComponent,
    CardStoryComponent,
    OnboardingSwiperComponent,
    BackButtonComponent,
    DateRangeComponent,
    ButtonComponent,
    DateRangeButtonComponent,
    CardDataComponent,
    CardDataTotalComponent,
    TableDetailComissionComponent,
    ImageComponent,
    SliderShareComponent,
    ImageTextComponent,
    TitleComponent,
    CardComponent,
    BussinessCardComponent,
    ButtonStoryComponent,
    ReviewClickamComponent,
    WidgetBarComponent,
    WelcomeComponent,
    NewBusinessFormComponent,
    SavingComponent,
    ReferWinComponent,
    QrComponent,
    BannerPrincipalComponent,
    ModalReferirComprarComponent,
    TermsAndConditionsModalComponent,
    SocialNetworksLoginButtonsComponent,
    InputFileComponent,
    ReplaceRewardsPipe,
    SuperbarraComponent,
    OfferCardComponent,
    OfferCarruselComponent,
    NoimagePipe,
    EmptyModalComponent,
    CarouselsGroupComponent,
    TitleWithTimeRangeComponent,
    InputFilePreviewComponent,
    EnableTooltipOnDisabledDirective,
    NotificationBadgeComponent,
    DidnotFindWhatsAppContactComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    SearchBarComponent,
    InputSearchComponent,
    StoryShareModalComponent,
    BusinessListComponent,
    InitInteractiveOnboardingComponent,
    BannerPrincipalLogoutComponent,
    BusinessLogoutComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatListModule,
    MatRippleModule,
    MatMenuModule,
    GoogleChartsModule,
    RouterModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    SlickCarouselModule,
    NgxPaginationModule,
    NgxDaterangepickerMd.forRoot(),
    ShareButtonModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    SwiperModule
  ],
  exports: [
    SearchComponent,
    NotFoundComponent,
    FooterComponent,
    HeaderComponent,
    MatListModule,
    MatRippleModule,
    SociallinksComponent,
    MatMenuModule,
    SwiperModule,
    TopComponent,
    GoogleChartsModule,
    RouterModule,
    MenuOptionsComponent,
    UnderConstructionComponent,
    TruncatePipe,
    ReplaceRewardsPipe,
    TrimPipe,
    SafePipe,
    RemoveTagsPipe,
    LogoComponent,
    ProductBussinessComponent,
    BackNavigationComponent,
    PageTitleComponent,
    DialogStoriesComponent,
    CardStoryComponent,
    OnboardingSwiperComponent,
    BackButtonComponent,
    DateRangeComponent,
    ButtonComponent,
    DateRangeButtonComponent,
    CardDataComponent,
    CardDataTotalComponent,
    TableDetailComissionComponent,
    ImageComponent,
    SliderShareComponent,
    ImageTextComponent,
    TitleComponent,
    CardComponent,
    BussinessCardComponent,
    ButtonStoryComponent,
    ReviewClickamComponent,
    WidgetBarComponent,
    WelcomeComponent,
    NewBusinessFormComponent,
    SavingComponent,
    SocialNetworksLoginButtonsComponent,
    QrComponent,
    BannerPrincipalComponent,
    ModalReferirComprarComponent,
    TermsAndConditionsModalComponent,
    InputFileComponent,
    SuperbarraComponent,
    OfferCardComponent,
    OfferCarruselComponent,
    EmptyModalComponent,
    CarouselsGroupComponent,
    NoimagePipe,
    TitleWithTimeRangeComponent,
    InputFilePreviewComponent,
    EnableTooltipOnDisabledDirective,
    NotificationBadgeComponent,
    DidnotFindWhatsAppContactComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    SearchBarComponent,
    InputSearchComponent,
    BusinessListComponent,
    BannerPrincipalLogoutComponent,
    BusinessLogoutComponent
  ],
})
export class SharedModule { }
