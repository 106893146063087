<app-refer-win *ngIf="!role || role==='CLICKER'"></app-refer-win>
<footer class="text-center border-gray">
  <div class="logo">
    <div>
      <img width="180" loading="lazy" src="assets/img/icon-lg-white-color.svg" alt="Logo Referidos éxito" />
      <p>{{ 'FOOTER.SLOGAN' | translate }}</p>
      <div fxHide fxShow.gt-sm>
        <app-app-store></app-app-store>
      </div>
    </div>
    <div fxHide fxShow.gt-sm>
      <div class="social">
        <app-sociallinks></app-sociallinks>
      </div>
      <div class="copyRight">
        <span>{{ 'GENERIC.COPYRIGHT' | translate }} &copy;</span>
      </div>
    </div>
  </div>

  <div fxHide fxShow.gt-sm class="linksInformation">
    <section *ngFor="let section of sectionsLinks">
      <h3>{{ section.description | replaceRewards | async }}</h3>
      <ul>
        <li *ngFor="let item of section.links" (click)="footerGA4(item.description)">
          <a *ngIf="item.active" [href]="item.link | safe: 'url'" target="_blank">
            {{ item.description | replaceRewards | async }}
          </a>
        </li>
      </ul>
    </section>
  </div>

  <div fxShow fxHide.gt-sm class="linksInformation2">
    <section *ngFor="let section of sectionsLinks">
      <details>
        <summary>{{ section.description | replaceRewards | async }}</summary>
        <ul>
          <li *ngFor="let item of section.links" (click)="footerGA4(item.description)">
            <a *ngIf="item.active" [href]="item.link | safe: 'url'" target="_blank">
              {{ item.description | replaceRewards | async }}
            </a>
          </li>
        </ul>
      </details>
      <hr />
    </section>
  </div>

  <div fxShow fxHide.gt-sm fxLayout="column" fxLayoutAlign="stretch center">
    <app-app-store></app-app-store>
    <div class="social">
      <app-sociallinks></app-sociallinks>
    </div>
    <div class="copyRight">
      <span>{{ 'GENERIC.COPYRIGHT' | translate }} &copy;</span>
    </div>
  </div>
</footer>