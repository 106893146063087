import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  customWidgetEvent: any;

  initEvent(){
    this.customWidgetEvent = new CustomEvent('widgetEvent', {});
  }

  dispatchWidgetEvent() {
    document.dispatchEvent(this.customWidgetEvent);
  }

}
