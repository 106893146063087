import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss']
})
export class PreviewImageComponent implements OnInit {

  src: any = '';

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizier: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.src = this.domSanitizier.bypassSecurityTrustResourceUrl(this.data.src)
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
