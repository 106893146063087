import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private http: HttpClient) {}
  url = environment.URL_MASTER;
  apiDepartment = 'data/getDeparments';
  apiBanks = 'data/getBanks';
  apiTerms = 'data/getterms';
  apiSetTerms = 'data/saveterm';
  public getDepartments() {
    return this.http.get(`${this.url}${this.apiDepartment}`);
  }

  public getBanks() {
    return this.http.get(`${this.url}${this.apiBanks}`);
  }
  public getTerms() {
    return this.http.get(`${this.url}${this.apiTerms}`);
  }
  public setTerms(data: any) {
    return this.http.post(`${this.url + this.apiSetTerms}`, data);
  }
}
