import { createSelector } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { OptionsListsState } from "../reducers/options-lists.reducers";

export const selectOptionsLists = (state: AppState) => state.options;

export const selectOptions = createSelector(
    selectOptionsLists,
    (state: OptionsListsState) => state.options
)

export const selectDepartments = createSelector(
    selectOptionsLists,
    (state: OptionsListsState) => state.departments
)

export const selectBanks = createSelector(
    selectOptionsLists,
    (state: OptionsListsState) => state.banks
)

export const selectStatusVerification = createSelector(
    selectOptionsLists,
    (state: OptionsListsState) => state.statusVerification
)

export const selectVerificationTime = createSelector(
    selectOptionsLists,
    (state: OptionsListsState) => state.verificationTime
)