import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { getBanks, getBanksError, getBanksSuccess, getDeparments, getDeparmentsError, getDeparmentsSuccess, getOptionsLists, getOptionsListsError, getOptionsListsSuccess, getStatusVerification, getStatusVerificationError, getStatusVerificationSuccess } from '../actions/options-lists.actions';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ResponseService } from 'src/app/interfaces/response';


@Injectable()
export class OptionsListsEffects {

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private masterDataService: MasterDataService
    ) {

    }

    getOptionsLists$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getOptionsLists),
            mergeMap(() =>
                this.userService.getBasicData().pipe(
                    map((options) => getOptionsListsSuccess({ options })),
                    catchError((error) => of(getOptionsListsError({ error }))
                    )
                )
            )
        );
    });

    getDepartments$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getDeparments),
            mergeMap(() =>
                this.masterDataService.getDepartments().pipe(
                    map((response: ResponseService) =>
                        getDeparmentsSuccess({ departments: response.objectResponse })),
                    catchError((error) => of(getDeparmentsError({ error }))
                    )
                )
            )
        );
    });

    getBanks$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getBanks),
            mergeMap(() =>
                this.masterDataService.getBanks().pipe(
                    map((response: ResponseService) =>
                        getBanksSuccess({ banks: response.objectResponse })),
                    catchError((error) => of(getBanksError({ error }))
                    )
                )
            )
        );
    });

    getStatusVerification$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getStatusVerification),
            mergeMap(() =>
                this.userService.getStatusVerification().pipe(
                    map((response: ResponseService) =>
                        getStatusVerificationSuccess({ status: response.objectResponse })),
                    catchError((error) => of(getStatusVerificationError({ error }))
                    )
                )
            )
        );
    });

}