import { Component, OnInit, ViewChild, TemplateRef, HostListener, OnDestroy, Inject, PLATFORM_ID, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UtilsService } from './services/utils.service';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { ContentService } from './services/content.service';
import { TokenService } from './services/token.service';
declare var dataLayer: any;
import { PopupComponent } from './modules/shared/components/popup/popup.component';
import { isPlatformBrowser, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import decode from 'jwt-decode';
import { onMainContentChange } from './animations/animations';
import { OnboardingSwiperComponent } from './modules/shared/components/onboarding-swiper/onboarding-swiper.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ModalGenericComponent } from './modules/shared/components/modal-generic/modal-generic.component';
import { ResponseService } from './interfaces/response';
import { MasterDataService } from './services/master-data.service';
import { UpdateService } from './services/update.service';
import { ReviewClickamComponent } from './modules/shared/components/review-clickam/review-clickam.component';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { getUserData, getUserProfile } from './store/actions/user.actions';
import { selectUserData } from './store/selectors/user.selectors';
import { selectUserProfile } from 'src/app/store/selectors/user.selectors';
import { getMenu } from './store/actions/menus.actions';
import { HomeInformationModalComponent } from './modules/clicker/components/home-information-modal/home-information-modal.component';
import { WidgetService } from './services/widget.service';
import { getActiveBussiness } from './store/actions/bussiness.actions';
import { getSellers } from './store/actions/sellers.actions';
import { TermsAndConditionsModalComponent } from './modules/shared/components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { IntroJSService } from './services/introjs.service';
import { InitInteractiveOnboardingComponent } from './modules/shared/components/init-interactive-onboarding/init-interactive-onboarding.component';
import { setOnboardingDisplay, setOnboardingStep } from './store/actions/onboarding.actions';
import { selectOnboarding } from './store/selectors/onboarding.selectors';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange],
})
export class AppComponent implements OnInit, OnDestroy {
  public onSideNavChange: boolean;

  @ViewChild('templateCardLogin, TemplateCardRegister, TemplateCardForgot, templateCardActivate', {
    static: false,
  })
  template: TemplateRef<any>;
  isHome: boolean;
  internal: boolean;
  showLoginForm: boolean;
  showRegisterForm: boolean;
  showForgotForm: boolean;
  showActivateForm: boolean;
  isOpen = false;
  // isOpenMenu = false;
  private subscription: Subscription = new Subscription();
  userDataStore$: Subscription = new Subscription();
  listenOnboarding$: Subscription = new Subscription();
  profileStore$: Subscription = new Subscription();
  innerWidth: number;
  showAnimation1: boolean;
  showAnimation2: boolean;
  isLoggedIn: any;
  firstName: string;
  lastName: string;
  email: string;
  userInfo: any;
  onboardingViewed: boolean = false;
  managedPayments: boolean;
  isEmployee: boolean;
  role: string;
  classPage: string;
  location: Location;
  timeout: any;
  categoryTyC: string = '';
  acceptTyC: boolean;
  acceptHabeasData: boolean;
  acceptNotifications: boolean;
  acceptTerms: boolean = null;
  @ViewChild('templateTerms', { static: false })
  templateTerms: TemplateRef<any>;
  newTermsHTML = false;
  stepTerms = true;
  activateButton = false;
  contentTerminos: any;
  contentTerminosPJ: any;
  contentProteccion: any;
  contentTransparencia: any;
  contentPrograma: any;
  textTerminos: any;
  textTerminosPJ: any;
  textProteccion: any;
  textTransparencia: any;
  textPrograma: any;
  hideFH = false;
  rateapp = false;
  openRegister: boolean = true;
  idPopup: any;
  idCampaign: number;
  slowConection = false;
  documentType: string;
  popups: any[] = [];
  firstCallPopups: boolean = true;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService,
    public auth: AuthService,
    private userService: UserService,
    private content: ContentService,
    private token: TokenService,
    private sw: UpdateService,
    private dialog: MatDialog,
    location: Location,
    private personalInfo: MasterDataService,
    @Inject(PLATFORM_ID) private platformId: object,
    private store: Store,
    private widgetService: WidgetService,
    private introJsService: IntroJSService
  ) {
    this.sw.checkForUpdates();

    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('es');
      translate.use('es');
    }

    this.subscription = router.events.subscribe((url: any) => {
      if (url instanceof NavigationStart) {
        if (this.role === 'PARTNER' && url.url !== '/partner') {
          this.router.navigate(['/partner']);
        }

        if (isPlatformBrowser(this.platformId)) {
          dataLayer.push({
            event: 'pageview',
            virtualPageURL: url.url,
          });
        }

        const splitUrl = url.url.split('/');
        if (splitUrl[1] === 'url') {
          this.hideFH = true;
        } else {
          this.hideFH = false;
        }
        dataLayer.push({
          event: 'pageview',
          virtualPageURL: url.url,
        });
      } else if (url instanceof NavigationEnd && this.role === 'CLICKER') {
        clearTimeout(this.timeout);
        if (this.auth.isLoggedIn()) {
          this.triggerPopup();
        }
      }
    });

    this.isLoggedIn = this.auth.isLoggedIn();

    this.subscription = this.router.events.subscribe(() => {
      const urlLocation = location.prepareExternalUrl(location.path());
      const SplitLocation = urlLocation.split('/');
      this.classPage = SplitLocation[1];
    });

    this.subscription = this.route.queryParams.subscribe((params) => {
      if (!!params.campaign) {
        localStorage.setItem('campaign', params.id);
        this.saveVisitCampaign(parseInt(params.id));
      }
      if (params.register === 'true') {
        this.utils.showRegisterForm();
      }
    });
  }

  ngOnInit() {
    this.initService();
    this.store.dispatch(getActiveBussiness());
    this.widgetService.initEvent();
    this.showAnimation1 = true;
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.showLoginForm = true;
    this.showRegisterForm = false;
    this.showForgotForm = false;
    this.showActivateForm = false;

    this.subscription = this.utils.change.subscribe((isOpen) => {
      this.isOpen = isOpen;
      this.showRegisterForm = false;
      this.showLoginForm = true;
      this.showForgotForm = false;
      this.showActivateForm = false;
    });

    // this.subscription = this.utils.changeMenu.subscribe((isOpenMenu) => {
    //   this.isOpenMenu = isOpenMenu;
    // });

    this.subscription = this.utils.changeRegister.subscribe((isOpenRegister) => {
      this.isOpen = isOpenRegister;
      this.showRegisterForm = true;
      this.showLoginForm = false;
      this.showForgotForm = false;
      this.showActivateForm = false;
    });

    this.subscription = this.utils.showForgotFormEmit.subscribe((isOpenForgot) => {
      this.isOpen = isOpenForgot;
      this.showRegisterForm = false;
      this.showLoginForm = false;
      this.showActivateForm = false;
      this.showForgotForm = true;
    });

    this.subscription = this.utils.showActivateFormEmit.subscribe((isOpenActivate) => {
      this.isOpen = isOpenActivate;
      this.showActivateForm = true;
      this.showRegisterForm = false;
      this.showLoginForm = false;
      this.showForgotForm = false;
    });

    this.windowWidth();
    this.getUserData();
    this.getUserProfile();
    this.listenOnboarding();
    this.review();
    this.store.dispatch(getMenu());
    if (this.auth.isLoggedIn()) {
      this.store.dispatch(getUserData());
      this.store.dispatch(getUserProfile());
    }
  }

  public saveVisitCampaign(id: number) {
    this.subscription = this.userService.saveVisitCampaign(id).subscribe();
  }

  public getTerms() {
    if (this.utils.showTermsAndConditions) {
      this.utils.showTermsAndConditions = false;
      this.personalInfo.getTerms().subscribe((resp: any) => {
        this.contentTerminos = resp.objectResponse[0].sectionvalue;
        this.contentProteccion = resp.objectResponse[1].sectionvalue;
        this.contentTransparencia = resp.objectResponse[2].sectionvalue;
        this.contentPrograma = resp.objectResponse[3].sectionvalue;
        this.contentTerminosPJ = resp.objectResponse[4].sectionvalue;
        this.textTerminos = resp.objectResponse[0].sectiontitle;
        this.textProteccion = resp.objectResponse[1].sectiontitle;
        this.textTransparencia = resp.objectResponse[2].sectiontitle;
        this.textPrograma = resp.objectResponse[3].sectiontitle;
        this.textTerminosPJ = resp.objectResponse[4].sectiontitle;
        this.termsAndConditions();
      });
    }
  }

  termsAndConditionsModal() {
    this.dialog.open(TermsAndConditionsModalComponent,
      {
        data: {
          typeDocument: this.documentType,
          category: this.categoryTyC,
          canClose: false,
          showGuide: true
        },
        disableClose: true,
        panelClass: 'terms-and-conditions-modal'
      }).afterClosed().subscribe(accept => this.afterClosedTerms(this.categoryTyC, accept));
  }

  afterClosedTerms(category, accept) {
    switch (category) {
      case 'termsAndConditions':
        this.sendReferalsTerm({ type: 'TerminosCondiciones', value: accept });
        break;
      case 'advertising':
        this.sendReferalsTerm({ type: 'EnvioPublicacion', value: accept });
        break;
      case 'habeasData':
        this.sendReferalsTerm({ type: 'HabeasData', value: accept });
        break;
    }
  }

  public termsAndConditions() {
    const template = this.templateTerms;
    const title = '';
    const id = 'newTerms';
    this.dialog.open(ModalGenericComponent, {
      disableClose: true,
      data: {
        title,
        id,
        template,
        hideCloseIcon: true
      },
    });
  }

  public showTerms() {
    this.stepTerms = false;
    this.newTermsHTML = true;
  }

  public logout() {
    this.stepTerms = true;
    this.newTermsHTML = false;
    this.activateButton = false;
    this.utils.logout();
    this.dialog.closeAll();
  }

  public acceptTermsCheck(buttonState: MatCheckboxChange) {
    if (buttonState.checked === true) {
      this.activateButton = true;
    } else {
      this.activateButton = false;
    }
  }

  public sendReferalsTerm(params) {
    this.userService.saveUserAcceptTermsReferrals(params).subscribe((resp: ResponseService) => {
      this.store.dispatch(getUserData());
    });
  }

  getOnboardingCarousel() {
    this.content.getBoarding().subscribe((slides) => {
      if (slides.length > 0) {
        this.dialog
          .open(OnboardingSwiperComponent, { panelClass: 'panel-class-onboarding', data: { slides } })
          .afterClosed()
          .subscribe(() => {
            this.userService.saveOnboarding(true).subscribe(() => {
              this.onboardingViewed = true;
            });
          });
      }
    });
  }

  showOnBoarding() {
    if (!this.onboardingViewed && this.role === 'CLICKER') {
      if (location.pathname === '/inicio') {
        const keyParams = { key: 'dynamic-onboarding' };
        this.userService.getKey(keyParams).subscribe((res: ResponseService) => {
          if (res.state === 'Success') {
            const interactiveOnboardingIsActive = JSON.parse(res.objectResponse.value);
            if (interactiveOnboardingIsActive) {
              this.store.dispatch(setOnboardingDisplay({ show: true }));
              this.store.dispatch(setOnboardingStep({ step: 1 }));
            } else {
              this.getOnboardingCarousel();
            }
          }
        });
      } else {
        this.router.navigateByUrl('/inicio');
        setTimeout(() => {
          this.showOnBoarding();
        }, 1000);
      }
    }
  }

  listenOnboarding() {
    this.listenOnboarding$ = this.store.select(selectOnboarding).subscribe(({ show, step }) => {
      if (show && step === 1) {
        this.dialog
          .open(InitInteractiveOnboardingComponent, { disableClose: true })
          .afterClosed()
          .subscribe(() => {
            const businessList = document.getElementById('businessList');
            if (businessList) {
              const scrollTo = businessList.offsetTop - 100;
              window.scrollTo({ top: scrollTo, behavior: 'smooth' });
              this.store.dispatch(setOnboardingStep({ step: 2 }));
            }
          })
      }
    });
  }

  public review() {
    this.auth.rate$.subscribe((resp) => {
      if (resp && this.onboardingViewed && this.role === 'CLICKER' && this.innerWidth < 600 && !this.rateapp) {
        this.dialog
          .open(ReviewClickamComponent, {
            width: '350px',
          })
          .afterClosed()
          .subscribe(() => {
            this.userService.rateapp(true).subscribe();
          });
      }
    });
  }

  public getPopUps() {
    if (this.acceptTyC && this.onboardingViewed) {
      this.content.getPopup().subscribe((resp) => {
        this.popups = resp;
        this.triggerPopup();
      });
    }
  }

  triggerPopup() {
    const locationHref = location.href;
    const routeSplit = locationHref.split('/');
    const currentRoute = `/${routeSplit[routeSplit.length - 1]}`;
    const popupsToshow = this.popups.filter((popup: any) => !popup.new && popup.seccion === currentRoute);
    popupsToshow.length > 0 && this.openPopUp(popupsToshow);
  }

  public openPopUp(popupsToshow: any) {
    this.dialog.open(PopupComponent, {
      data: [
        ...popupsToshow,
      ],
      panelClass: 'transversal-popup',
    });
  }

  public saveVisitOffer(idoffer) {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const tokenDecode = decode(token);
    const userId = tokenDecode.userid;
    this.content.saveVisitOffer({ idoffer, userId }).subscribe();
  }

  public hideLogin() {
    this.isOpen = !this.isOpen;
    this.showLoginForm = true;
    this.showRegisterForm = false;
    this.showForgotForm = false;
    this.showActivateForm = false;
  }

  public showRegister() {
    this.showRegisterForm = true;
    this.showLoginForm = false;
    this.showForgotForm = false;
    this.showActivateForm = false;
  }

  public showLogin() {
    this.showRegisterForm = false;
    this.showForgotForm = false;
    this.showLoginForm = true;
    this.showActivateForm = false;
  }

  public showForgot() {
    this.showForgotForm = true;
    this.showRegisterForm = false;
    this.showLoginForm = false;
    this.showActivateForm = false;
  }

  public showActivate() {
    this.showForgotForm = false;
    this.showRegisterForm = false;
    this.showLoginForm = false;
    this.showActivateForm = true;
  }

  public getUserData() {
    this.userDataStore$ = this.store.select(selectUserData).subscribe(({ data, isLoading }) => {
      if (!isLoading && data) {
        this.store.dispatch(getSellers());
        this.role = this.token.userInfo().role;
        this.documentType = data.documenttypecode;
        this.onboardingViewed = data.onboardingviewed;
        this.rateapp = data.rateapp;
        this.firstName = data.firstnames;
        this.lastName = data.lastnames;
        this.managedPayments = data.managedpayments;
        this.isEmployee = data.isemployeegrupoexito;
        this.acceptTyC = data.accepttermsreferrals;
        this.acceptHabeasData = data.accepthabeasdata;
        this.acceptNotifications = data.acceptnotifications;
        this.modalSequence();
      }
    });
  }

  modalSequence() {
    if (this.role === 'CLICKER') {
      if (!this.acceptTyC) {
        this.categoryTyC = 'termsAndConditions';
        this.termsAndConditionsModal();
      } else if (!this.acceptHabeasData) {
        this.categoryTyC = 'habeasData';
        this.termsAndConditionsModal();
      }
      else if (this.acceptNotifications === null) {
        this.categoryTyC = 'advertising';
        this.termsAndConditionsModal();
      }
      else {
        this.showOnBoarding();
        this.getPopUps();
      }
    }
  }

  getUserProfile() {
    this.profileStore$ = this.store.select(selectUserProfile).subscribe(({ data, isLoading }) => {
      if (data && !isLoading) {
        this.checkHomeInformationModal(data);
      }
    })
  }

  checkHomeInformationModal(data: any) {
    const { bank, bankAccountNumber, typeBankAccount, department, municipality, address } = data;
    if ((bank && bankAccountNumber && typeBankAccount) && (!department || !municipality || !address)) {
      this.dialog.open(HomeInformationModalComponent, {
        disableClose: true,
      });
    }
  }

  // @HostListener('over')
  // hideMenu() {
  //   this.utils.hideMenu();
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.windowWidth();
  }

  public windowWidth() {
    if (this.innerWidth > 800) {
      this.showAnimation1 = true;
      this.showAnimation2 = false;
    } else {
      this.showAnimation1 = false;
      this.showAnimation2 = true;
    }
  }

  initService() {
    const conn = (navigator as any).connection;
    if (conn) {
      const effectiveType = conn.effectiveType;
      const getConection = localStorage.getItem('conection');
      if (effectiveType !== '4g' && getConection !== '4g') {
        localStorage.setItem('conection', effectiveType);
        Swal.fire({
          html: "<i class='tio-wifi_off purple-text f-48'></i> <h3>Ups!</h3> <p class='purple-text f-19'>No tienes acceso a internet o tu señal es débil. Revisa tu conexión.</p>",
          confirmButtonText: 'Continuar',
          confirmButtonClass: 'button-base button-base__cancel',
          allowOutsideClick: false,
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.userDataStore$.unsubscribe();
    this.listenOnboarding$.unsubscribe();
  }
}
