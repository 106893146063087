export const getStatusClassName = (status: string) => {
    const statusList = {
        'pendiente documento': 'pendiente-documento',
        'en gestión': 'en-gestion',
        'pendiente': 'pendiente',
        'solucionado': 'solucionado',
        'solución parcial': 'solucion-parcial',
    };
    const statusLowercase = status.toLocaleLowerCase();
    return statusList[statusLowercase];
}