<mat-sidenav-container class="sidenav-container overflow-initial">
  <mat-sidenav #drawer class="sidenav-menu pt-3" fixedInViewport position="start" [opened]="false">
    <mat-icon (click)="drawer.toggle()" class="border-close">close</mat-icon>
    <mat-toolbar class="p-0 greeting-name">
      <span class="border-bottom-salmon-init color__primary-black" *ngIf="!auth.isLoggedIn()"> Menú </span>
      <span *ngIf="auth.isLoggedIn()">
        <h3 class="m-0 border-bottom-salmon-init"><span class="name">Hola, {{ firstName }}</span></h3>
      </span>
    </mat-toolbar>
    <a *ngIf="managedPayments === false && isEmployee === false && (role === 'CLICKER')"
      class="paymentInfo text-white p-2 cursor-pointer mb-2" (click)="drawer.toggle()" fxLayout="row"
      [routerLink]="['/mi-perfil/datos-financieros']">
      <img class="paymentInfo-icon" loading="lazy" src="assets/img/documents-info.svg" alt="user" />
      <div class="paymentInfo-info">
        <h4 class="paymentInfo-title">{{ 'GENERIC.COMPLETE_REGISTER' | translate }}</h4>
        <p class="paymentInfo-description">
          {{ 'GENERIC.COMPLETE_REGISTER_INFO' | translate }},
          <span class="paymentInfo-link">hazlo aquí.</span>
        </p>
      </div>
    </a>
    <mat-nav-list>
      <app-menu-options (hideSidenav)="drawer.toggle()"></app-menu-options>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="overflow-initial main-body-content">
    <!-- Add Content Here -->
    <div *ngIf="showAnimation2">
      <!-- <div class="fullheight slide-in-top" *ngIf="isOpenMenu">
        <app-menu (close)="hideMenu()"></app-menu>
      </div> -->

      <div class="fullheight slide-in-top" *ngIf="isOpen">
        <app-card *ngIf="showLoginForm" idTitle="titleLogin" title="{{ 'LOGIN.LOGIN' | translate }}"
          description="{{ 'LOGIN.DESCRIPTION' | translate }}" idAction="registerHere"
          action="{{ 'LOGIN.REGISTER' | translate }}" (actionButton)="showRegister()"
          [template]="templateCardLogin"></app-card>

        <ng-template #templateCardLogin>
          <app-loginform></app-loginform>
        </ng-template>

        <app-card *ngIf="showRegisterForm" Class="gtmRegistroClicLatrlIniciarSesion" idTitle="titleRegister"
          title="{{ 'GENERIC.REGISTER' | translate }}" description="{{ 'REGISTER.HAVE_ACCOUNT' | translate }}"
          idAction="registerForm" action="{{ 'LOGIN.LOGIN' | translate }}" (actionButton)="showLogin()"
          [template]="templateCardRegister"></app-card>

        <ng-template #templateCardRegister>
          <app-registerform></app-registerform>
        </ng-template>

        <app-card *ngIf="showForgotForm" Class="gtmIniciarSesiónClicLtrlOlvidoClave" idTitle="titleForgot"
          title="{{ 'FORGOTPASSWORD.FORGOT_PASSWORD' | translate }}"
          description="{{ 'FORGOTPASSWORD.ENTER_EMAIL' | translate }}" [template]="templateCardForgot"></app-card>

        <ng-template #templateCardForgot>
          <app-forgotpasswordform></app-forgotpasswordform>
        </ng-template>

        <app-card *ngIf="showActivateForm" idTitle="titleActivate"
          title="{{ 'FORGOTPASSWORD.FORGOT_PASSWORD' | translate }}"
          description="{{ 'FORGOTPASSWORD.ENTER_EMAIL' | translate }}" [template]="templateCardActivate"></app-card>

        <ng-template #templateCardActivate>
          <app-activate-account-form></app-activate-account-form>
        </ng-template>
      </div>
    </div>

    <div *ngIf="showAnimation1">
      <!-- <div ngClass.lt-md="fullheight slide-in-right" ngClass.gt-md="fullheight slide-in-right p-5" *ngIf="isOpenMenu"> -->
      <!-- <div class="fullheight slide-in-right" *ngIf="isOpenMenu">
        <app-menu (close)="hideMenu()"></app-menu>
      </div> -->

      <!-- <div ngClass.lt-md="fullheight slide-in-right" ngClass.gt-md="fullheight slide-in-right p-5" *ngIf="isOpen"> -->
      <div class="fullheight slide-in-right" *ngIf="isOpen">
        <app-card Class="gtmIniciarSesiónClicLtrlRegistrarte" *ngIf="showLoginForm" idTitle="titleLogin"
          title="{{ 'LOGIN.LOGIN' | translate }}" description="{{ 'LOGIN.DESCRIPTION' | translate }}"
          idAction="registerHere" action="{{ 'LOGIN.REGISTER' | translate }}" (actionButton)="showRegister()"
          [template]="templateCardLogin"></app-card>

        <ng-template #templateCardLogin>
          <app-loginform></app-loginform>
        </ng-template>

        <app-card Class="gtmRegistroClicLatrlIniciarSesion" *ngIf="showRegisterForm" idTitle="titleRegister"
          title="{{ 'GENERIC.REGISTER' | translate }}" description="{{ 'REGISTER.HAVE_ACCOUNT' | translate }}"
          idAction="registerForm" action="{{ 'LOGIN.LOGIN' | translate }}" (actionButton)="showLogin()"
          [template]="templateCardRegister"></app-card>

        <ng-template #templateCardRegister>
          <app-registerform></app-registerform>
        </ng-template>

        <app-card *ngIf="showForgotForm" idTitle="titleForgot"
          title="{{ 'FORGOTPASSWORD.FORGOT_PASSWORD' | translate }}"
          description="{{ 'FORGOTPASSWORD.ENTER_EMAIL' | translate }}" [template]="templateCardForgot"></app-card>

        <ng-template #templateCardForgot>
          <app-forgotpasswordform></app-forgotpasswordform>
        </ng-template>

        <app-card *ngIf="showActivateForm" idTitle="titleActivate"
          title="{{ 'FORGOTPASSWORD.FORGOT_PASSWORD' | translate }}"
          description="{{ 'FORGOTPASSWORD.ENTER_EMAIL' | translate }}" [template]="templateCardActivate"></app-card>

        <ng-template #templateCardActivate>
          <app-activate-account-form></app-activate-account-form>
        </ng-template>
      </div>
    </div>

    <app-superbarra></app-superbarra>
    <app-header class="app-header" *ngIf="!hideFH" (sidenav)="drawer.toggle()" [name]="firstName"></app-header>
    <!-- <app-qr *ngIf="auth.isLoggedIn() === false || (auth.getRole$ | async) === 'CLICKER'"></app-qr> -->

    <div ngClass="maincontent {{ classPage }}-page mb-4">
      <router-outlet></router-outlet>
    </div>

    <ng-template #templateTerms>
      <div class="terms text-center" *ngIf="stepTerms">
        <img loading="lazy" class="icon" src="/assets/img/icon.svg" alt="icon" />
        <div innerHtml="{{ 'GENERIC.NEW_TERMS' | translate }}"></div>
        <button class="button-base button-base__primary-black" (click)="showTerms()">Conócelos aquí</button>
      </div>

      <div class="terms" *ngIf="newTermsHTML">
        <span *ngIf="documentType !== 'NIT'">
          <h2 class="border-bottom-salmon-init">{{ textTerminos }}</h2>
          <div [innerHtml]="contentTerminos"></div>
          <h2 class="border-bottom-salmon-init">{{ textProteccion }}</h2>
          <div [innerHtml]="contentProteccion"></div>
          <h2 class="border-bottom-salmon-init">{{ textTransparencia }}</h2>
          <div [innerHtml]="contentTransparencia"></div>
          <h2 class="border-bottom-salmon-init">{{ textPrograma }}</h2>
          <div [innerHtml]="contentPrograma"></div>
        </span>
        <span *ngIf="documentType === 'NIT'">
          <h2 class="border-bottom-salmon-init">{{ textTerminosPJ }}</h2>
          <div [innerHtml]="contentTerminosPJ"></div>
        </span>
        <div class="mb-3">
          <span>
            <mat-checkbox id="chkaccept" (change)="acceptTermsCheck($event)"> </mat-checkbox>
          </span>
          <span class="accept-terms ml-2">
            {{ 'REGISTER.ACCEPT' | translate }}
          </span>
        </div>

        <span fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
          <button id="accept-modal" class="button-base button-base__primary-black" ngClass.gt-xs="pl-5 pr-5"
            ngClass.xs="pl-2 pr-2" [disabled]="!activateButton" (click)="sendReferalsTerm({})">
            {{ 'GENERIC.ACCEPT' | translate }}
          </button>

          <button id="accept-modal" (click)="logout()" class="button-base button-base__cancel" ngClass.gt-xs="pl-5 pr-5"
            ngClass.xs="pl-2 pr-2">
            {{ 'GENERIC.CANCEL' | translate }}
          </button>
        </span>
      </div>
    </ng-template>

    <app-footer *ngIf="!hideFH" [role]="auth.getRole$ | async" class="footer-container"></app-footer>

  </mat-sidenav-content>
</mat-sidenav-container>