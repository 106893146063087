import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { LinksService } from 'src/app/services/links.service';
import { getSellers, getSellersError, getSellersSuccess } from '../actions/sellers.actions';


@Injectable()
export class SellersEffects {

    constructor(
        private actions$: Actions,
        private linksService: LinksService
    ) { }

    getSellers$ = createEffect((): Observable<any> => {
        return this.actions$.pipe(
            ofType(getSellers),
            mergeMap(() =>
                this.linksService.getSellers().pipe(
                    map((data) => getSellersSuccess({ data })),
                    catchError((error) => of(getSellersError({ error }))
                    )
                )
            )
        );
    });

}