export const formatToQueryParams = (params: any) => {
    const queryParams = {...params};
    delete queryParams.currentPage;
    let queryString = '?';
    Object.keys(queryParams).forEach((x, idx) => {
        if(Object.values(queryParams)[idx]){
            queryString += `${x}=${Object.values(queryParams)[idx]}&`
        }
    })
    return queryString.substring(0, queryString.length - 1);
}