<app-top title="Recuperar contraseña" (close)="hideForgot()"></app-top>

<p class="text-center forgot-password__title">{{ 'FORGOTPASSWORD.ENTER_EMAIL' | translate }}</p>

<form class="forgotpassword-container" [formGroup]="forgotPaswordForm">
  <mat-form-field>
    <input matInput id="email" type="email" placeholder="{{ 'GENERIC.EMAIL' | translate }}" formControlName="Username" spacebar required />
    <mat-error
      id="introduceEmail"
      *ngIf="forgotPaswordForm.controls.Username.invalid && forgotPaswordForm.controls.Username.errors.required"
      >{{ 'LOGIN.INTRODUCE_EMAIL' | translate }}</mat-error
    >
    <mat-error
      id="invalidEmail"
      *ngIf="forgotPaswordForm.controls.Username.invalid && forgotPaswordForm.controls.Username.errors.pattern"
      >{{ 'LOGIN.INVALID_EMAIL' | translate }}</mat-error
    >

    <mat-error id="maxUsername" *ngIf="forgotPaswordForm.controls.Username.invalid && forgotPaswordForm.controls.Username.errors.maxlength">{{
      'GENERIC.MAX_CHARACTERS'
        | translate
          : {
              maxchar: forgotPaswordForm.controls.Username.errors.maxlength.requiredLength
            }
    }}</mat-error>
  </mat-form-field>

  <button
    class="button-base forgot-button gtmIniciarSesionClicLightboxRecuperarContrasena"
    id="recoverPassword"
    (click)="forgotPassword()"
    [disabled]="!forgotPaswordForm.valid"
  >
    <span class="gtmIniciarSesionClicLightboxRecuperarContrasena">{{ 'FORGOTPASSWORD.FORGOT_PASSWORD' | translate }}</span>
  </button>
</form>
