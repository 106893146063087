import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { selectUserProfile } from 'src/app/store/selectors/user.selectors';
import { DateFormat } from '../../helpers/date-format';
import { LinksService } from 'src/app/services/links.service';
import { ResponseService } from 'src/app/interfaces/response';
import { WindowClass } from 'src/app/helpers/window-location';
import decode from 'jwt-decode';

@Component({
  selector: 'app-superbarra',
  templateUrl: './superbarra.component.html',
  styleUrls: ['./superbarra.component.scss']
})
export class SuperbarraComponent implements OnInit, OnDestroy {

  userProfile$: Subscription = new Subscription();
  superbarra$: Subscription = new Subscription();
  saveLink$: Subscription = new Subscription();
  saveVisitOffer$: Subscription = new Subscription();
  currentSuperbarra: any;
  showByDevice: boolean = false;
  colorText: string;
  colorBackground: string;
  colorTextButton: string;
  colorButton: string;
  currentUser: any;
  url: string;

  constructor(
    private store: Store,
    private contentService: ContentService,
    private linkService: LinksService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn();
  }

  isLoggedIn() {
    this.userProfile$ = this.store.select(selectUserProfile).subscribe(({ data, isLoading }) => {
      if (!isLoading) {
        if (data) {
          this.currentUser = data;
          if (!this.currentSuperbarra) {
            const tokenDecode = decode(localStorage.getItem('ACCESS_TOKEN'));
            if (tokenDecode.role === 'CLICKER') {
              this.getSuperbarra();
            }
          }
        } else {
          this.currentSuperbarra = null;
        }
      }
    })
  }

  getSuperbarra() {
    this.superbarra$ = this.contentService.getOffersbyType({ id: 'SUPERBARRA', admin: false }).subscribe((resp) => {
      if (resp[0]) {
        this.currentSuperbarra = resp[0];
        this.setColors();
        this.detectDevice();
      }
    });
  }

  saveVisit() {
    this.saveVisitOffer$ = this.contentService.saveVisitOffer(
      { idoffer: this.currentSuperbarra.id, userId: this.currentUser.userId }
    ).subscribe();
  }

  setColors() {
    this.colorText = this.currentSuperbarra.colortextbackground;
    this.colorBackground = this.currentSuperbarra.colorbackground;
    this.colorTextButton = this.currentSuperbarra.colortextbutton;
    this.colorButton = this.currentSuperbarra.colorbutton;
  }

  detectLink() {
    if (this.currentSuperbarra.idbusiness) {
      this.saveLink();
    } else {
      WindowClass.open(this.currentSuperbarra.link);
    }
    this.saveVisit();
  }

  saveLink() {
    const data = {
      link: this.currentSuperbarra.link,
      identification: this.currentUser.identification,
      plu: 'SUPER',
      business: this.currentSuperbarra.idbusiness,
      creationDate: DateFormat.format(new Date(), 'YYYY-MM-DD HH:MM'),
    };
    this.saveLink$ = this.linkService.saveLink(data).subscribe((resp: ResponseService) => {
      if (resp.state === 'Success') {
        WindowClass.open(resp.objectResponse.link);
      }
    });
  }

  detectDevice() {
    const device = this.currentSuperbarra.device;
    const isMobile = this.detectUserAgent();
    switch (device) {
      case 'TODOS':
        this.showByDevice = true;
        break;
      case 'MOBILE':
        this.showByDevice = isMobile;
        break;
      case 'WEB':
        this.showByDevice = !isMobile;
        break;
      default:
        this.showByDevice = false;
    }
  }

  detectUserAgent() {
    return /android|iphone|kindle|ipad/i.test(navigator.userAgent);
  }

  ngOnDestroy(): void {
    this.userProfile$.unsubscribe();
    this.superbarra$.unsubscribe();
    this.saveVisitOffer$.unsubscribe();
    this.saveLink$.unsubscribe();
  }

}
