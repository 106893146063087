import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { delay } from 'rxjs';
import { ResponseService } from 'src/app/interfaces/response';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss'],
})
export class TermsAndConditionsModalComponent implements OnInit {

  mainTitle: string = '';
  termsCategory: string = '';
  typeDocument: string = '';
  mainContent: string = '';
  secondaryTitle: string = '';
  secondaryContent: string = '';
  canClose: boolean = false;
  @Output() acceptTerms: EventEmitter<any> = new EventEmitter();
  @Input() typedc: string = 'documento';
  canCancel: boolean = false;
  showGuide: boolean = false;
  isLoading: boolean = false;
  constructor(
    private personalInfo: MasterDataService,
    private matDialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) private termsData: any
  ) { }

  ngOnInit(): void {
    this.initTerms();
    !this.showGuide && this.getTerms();
  }


  getTerms() {
    this.showGuide = false;
    this.isLoading = true;
    this.personalInfo.getTerms().pipe(delay(2000)).subscribe((resp: ResponseService) => {
      const termList = resp.objectResponse;
      switch (this.termsCategory) {
        case 'termsAndConditions':
          this.termsAndConditions(termList);
          break;
        case 'advertising':
          this.advertising(termList);
          break;
        case 'habeasData':
          this.habeasData(termList);
          break;
      }
      this.isLoading = false;
    });
  }

  initTerms() {
    // Categorías: termsAndConditions, advertising, habeasData
    // personaNatural
    // personaJuridica
    // envioPublicidad
    // tratamientoDatos
    this.termsCategory = this.termsData.category || 'termsAndConditions';
    this.typeDocument = this.termsData.typeDocument;
    this.canClose = this.termsData.canClose;
    this.canCancel = this.termsCategory === 'advertising';
    this.showGuide = this.termsData.showGuide;
  }

  termsAndConditions(termList: any[]) {
    if (this.typeDocument.toLocaleLowerCase() === 'nit') {
      this.mainTitle = termList.find(term => term.sectionkey === 'TerminosCondicionesPJ').sectiontitle;
      this.mainContent = termList.find(term => term.sectionkey === 'TerminosCondicionesPJ').sectionvalue;
    } else {
      this.mainTitle = termList.find(term => term.sectionkey === 'TerminosCondiciones').sectiontitle;
      this.mainContent = termList.find(term => term.sectionkey === 'TerminosCondiciones').sectionvalue;
    }
    this.secondaryTitle = termList.find(term => term.sectionkey === 'ProgramaReferidos').sectiontitle;
    this.secondaryContent = termList.find(term => term.sectionkey === 'ProgramaReferidos').sectionvalue;
  }

  advertising(termList: any[]) {
    this.mainTitle = termList.find(term => term.sectionkey === 'EnvioPublicidad').sectiontitle;
    this.mainContent = termList.find(term => term.sectionkey === 'EnvioPublicidad').sectionvalue;
  }

  habeasData(termList: any[]) {
    this.mainTitle = termList.find(term => term.sectionkey === 'ProteccionDatos').sectiontitle;
    this.mainContent = termList.find(term => term.sectionkey === 'ProteccionDatos').sectionvalue;
    this.secondaryTitle = termList.find(term => term.sectionkey === 'Transparencia').sectiontitle;
    this.secondaryContent = termList.find(term => term.sectionkey === 'Transparencia').sectionvalue;
  }

  close(flat) {
    this.matDialogRef.close(flat);
  }

}
