import { createAction, props } from '@ngrx/store';

export const getPrincipalBlogs = createAction('[BLOG] Get principal blogs');

export const getPrincipalBlogsSuccess = createAction(
    '[BLOG] Get principal blogs success',
    props<{ principalBlogs: any[] }>()
);

export const getPrincipalBlogsError = createAction(
    '[BLOG] Get principal blogs error',
    props<{ error: any }>()
);

export const getBlogs = createAction(
    '[BLOG] Get blogs',
    props<{ params: any }>()
);

export const getBlogsSuccess = createAction(
    '[BLOG] Get blogs success',
    props<{ blogs: any[] }>()
);

export const getBlogsError = createAction(
    '[BLOG] Get blogs error',
    props<{ error: any }>()
);

export const getTags = createAction('[BLOG] Get blog tags');

export const getTagsSuccess = createAction(
    '[BLOG] Get blog tags success',
    props<{ tags: any[] }>()
);

export const getTagsError = createAction(
    '[BLOG] Get blog tags error',
    props<{ error: any }>()
);