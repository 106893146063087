<section class="superbarra" *ngIf="currentSuperbarra && showByDevice"
    [ngStyle]="{'background-color':colorBackground}">
    <p class="superbarra__container">
        <a class="superbarra__text" [ngStyle]="{'color':colorText}" (click)="detectLink()">
            {{ currentSuperbarra.description }}
        </a>
        <a class="superbarra__button" [ngStyle]="{'color':colorTextButton,'background-color':colorButton}"
            (click)="detectLink()">
            {{ currentSuperbarra.textbutton }}
        </a>
    </p>
</section>