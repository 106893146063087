import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, UrlSegment, } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthCanLoad implements CanLoad {
  constructor(private authService: AuthService, private router: Router) { }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['inicio']); // choose either default route like here
      return false;
    }
    return this.authService.isLoggedIn();
  }
}
