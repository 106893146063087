<div class="init-interactive">
    <p class="init-interactive__text">
        Hola <span class="init-interactive__text--bold">Bienvenido</span> a<br>
        <span class="init-interactive__text--bold init-interactive__text--salmon">Referidos éxito.</span>
    </p>
    <p class="init-interactive__text">
        <span class="init-interactive__text--bold">
            La plataforma que<br>
            <span class="init-interactive__text--salmon">te devuelve dinero</span></span> por todas<br>
        tus compras.
    </p>
    <img class="init-interactive__img" src="assets/img/home/init-onboarding.png" alt="">
    <div class="init-interactive__button-container">
        <button class="button-base" (click)="continueOnboarding()">
            Siguiente
        </button>
    </div>
</div>